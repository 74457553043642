import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { FetchPolicy } from 'utils/enum/Core';
import ModalUtils from 'utils/ModalUtils';
import InventoryMutation from 'services/graphQL/mutate/InventoryMutation';
import InventoryQuery from 'services/graphQL/query/InventoryQuery';

const useVehicleStyles = () => {
    const [styles, setStyles] = useState([]);

    const {
        data: stylesResponse,
        loading: loadingStyles,
        error: stylesError,
        refetch: refetchVehicleStyles,
    } = useQuery(InventoryQuery.GET_VEHICLE_STYLES, {
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
        notifyOnNetworkStatusChange: true,
    });

    const [saveVehicleStyle, { loading: savingStyle }] = useMutation(InventoryMutation.SAVE_VEHICLE_STYLE, {
        onCompleted: (response) => {
            if (response) {
                refetchVehicleStyles();
                ModalUtils.successMessage(null, 'Style saved successfully');
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage(null, error);
        },
    });

    useEffect(() => {
        if (stylesError) {
            ModalUtils.errorMessage(stylesError?.graphQLErrors);
            return;
        }

        if (!loadingStyles && stylesResponse) {
            const { getVehicleStyles } = stylesResponse;
            if (Array.isArray(getVehicleStyles)) setStyles(getVehicleStyles);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingStyles, stylesError]);

    return {
        vehicleStyles: styles,
        saveVehicleStyle,
        savingStyle,
        loadingStyles,
    };
};

export default useVehicleStyles;
