import React, { useEffect, useState } from 'react';
import {
    makeStyles, Grid, Tooltip, Switch,
} from '@material-ui/core';
import update from 'immutability-helper';
import ModalUtils from 'utils/ModalUtils';
import { Col, Form } from 'react-bootstrap';
import GraphQLClient from 'services/apollo/GraphQLClient';
import CompanyQuery from 'services/graphQL/query/setting/CompanyQuery';
import CompanyMutation from 'services/graphQL/mutate/CompanyMutation';

const useStyles = makeStyles((theme) => ({
    box: {
        marginTop: '10px',
        paddingTop: '10px',
        border: `solid 1px ${theme.palette.border.mercury}`,
    },
}));
const graphqlClient = new GraphQLClient();

const CarFaxSetting = () => {
    const classes = useStyles();

    // Initial state
    const [state, setState] = useState({
        isCarFaxEnabled: false,
        updating: false,
    });

    const onChange = (key, value) => {
        setState((prevState) => update(prevState, {
            [key]: { $set: value },
        }));
    };

    const toggleCarFax = (value) => {
        onChange('isCarFaxEnabled', value);
        graphqlClient
            .mutate(CompanyMutation.UPDATE_COMPANY_CARFAX, { input: value })
            .then((response) => {
                const { data, graphQLErrors } = response;
                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data) {
                    ModalUtils.successMessage(null, 'Company updated successfully');
                }
            });
    };

    useEffect(() => {
        graphqlClient
            .query(CompanyQuery.GET_COMPANY_CARFAX)
            .then((response) => {
                const { data, graphQLErrors } = response;
                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                const { getCompanyCarfax } = data;
                setState((prevState) => update(prevState, {
                    isCarFaxEnabled: { $set: getCompanyCarfax?.value?.toLowerCase() === 'true' },
                }));
            });
    }, []);

    return (
        <Grid container>
            <Grid item md={6} className={classes.box}>
                <Grid container>
                    <Grid item md={6}>
                        <Form.Group as={Col}>
                            <Form.Label>Enable Carfax</Form.Label>
                            <Tooltip title="Enable carfax integration for this company">
                                <Switch
                                    checked={state.isCarFaxEnabled}
                                    onChange={(event) => toggleCarFax(event.target.checked)}
                                    inputProps={{ 'aria-label': 'enable carfax' }}
                                />
                            </Tooltip>
                        </Form.Group>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default CarFaxSetting;
