import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Button, Dialog, DialogContent,
    DialogActions, makeStyles,
    Checkbox, FormControlLabel,
} from '@material-ui/core';
import { Form, Col } from 'react-bootstrap';
import {
    useQuery,
    useLazyQuery,
    useMutation,
} from '@apollo/client';
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import { FetchPolicy, AI_PLATFORM } from 'utils/enum/Core';
import ModalUtils from 'utils/ModalUtils';
import StringUtils from 'lib/StringUtils';
import ButtonStyles from 'styles/theme/Button';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import Select from 'components/widgets/Select';
import InputNumber from 'components/widgets/InputNumber';
import AssistantFiles from 'components/modules/settings/ai/AssistantFiles';
import AssistantFunctions from 'components/modules/settings/ai/AssistantFunctions';
import AIQuery from 'services/graphQL/query/ai/AIQuery';
import AIMutation from 'services/graphQL/mutate/ai/AIMutation';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const useStyles = makeStyles((theme) => ({
    AppBar: {
        color: theme.palette.text.white,
        backgroundColor: theme.palette.background.sanMarino,
        '& h4': {
            color: theme.palette.text.white,
            fontSize: '15px',
        },
    },
    content: {
        padding: '20px 10px',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            paddingRight: 0,
        },
        '& > div:nth-child(1)': {
            display: 'flex',
            fontSize: '13px',
            '& > div': {
                width: '50%',
            },
        },
        '& > div:nth-child(2)': {
            fontSize: '13px',
            marginTop: '15px',
            '& textarea': {
                resize: 'none',
                height: '250px',
            },
        },
        '& > div:nth-child(3)': {
            marginTop: '15px',
            fontSize: '13px',
            '& > div > div': {
                display: 'flex',
                marginBottom: '2px',
                justifyContent: 'space-between',
                '& > div:nth-child(1)': {
                    width: '50%',
                    display: 'flex',
                    '& > input': {
                        width: '250px',
                        marginRight: '5px',
                    },
                },
                '& > div:nth-child(2)': {
                    width: '50%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    '& > div': {
                        width: '250px',
                        marginRight: '5px',
                    },
                },
            },
        },
        '& > div:nth-child(4)': {
            marginTop: '15px',
            fontSize: '13px',
            '& > div > div:nth-child(2)': {
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: '2px',
                '& > div': {
                    width: '250px',
                    marginRight: '5px',
                    '& > div': {
                        height: '34px',
                    },
                },
                '& > input': {
                    width: '250px',
                    marginRight: '5px',
                },
            },
        },
    },
    input: {
        fontSize: '13px',
        resize: 'none',
        width: '100%',
    },
    '@global': {
        '.css-26l3qy-menu div': {
            fontSize: '13px',
            lineHeight: '1.4',
        },
    },
}));

const AssistantForm = ({ assistant, toggleDialog }) => {
    const keyStore = new KeyStore();
    const AI_GLOBAL_SETTINGS_READ = keyStore.hasPermission(Permission.AI_GLOBAL_SETTINGS_READ);

    const classes = { ...useStyles(), ...buttonStyles() };
    const filesReference = useRef();
    const functionsReference = useRef();
    const {
        aiAssistantId,
        companyCode,
        instructions,
        isLive: isAssistantLive,
        notifyAIWhilePaused: isAINotificationOn,
        maxResponseTokens,
        model,
        name: assistantName,
        temperature,
        files,
        functions: assistantFunctions,
    } = assistant ?? {};

    const [state, setState] = useState({
        companies: [],
        models: [],
        isLive: isAssistantLive ?? false,
        notifyAIWhilePaused: isAINotificationOn ?? false,
        selectedName: assistantName,
        selectedTemperature: temperature ?? 1,
        selectedMaxTokens: maxResponseTokens ?? 100,
        selectedCompany: companyCode,
        selectedModel: model ?? 'gpt-4o-mini-2024-07-18',
        selectedInstructions: instructions,
    });

    const {
        companies,
        models,
        isLive,
        notifyAIWhilePaused,
        selectedName,
        selectedTemperature,
        selectedCompany,
        selectedMaxTokens,
        selectedModel,
        selectedInstructions,
    } = state;

    const {
        data: enabledCompaniesData,
        loading: loadingEnabledCompanies,
        error: errorLoadingEnabledCompanies,
    } = useQuery(AIQuery.PULL_COMPANIES_AI_KEY_ADDED, {
        fetchPolicy: FetchPolicy.NO_CACHE,
        skip: !AI_GLOBAL_SETTINGS_READ,
    });

    const [loadModels, { loading: loadingModels }] = useLazyQuery(AIQuery.GET_MODELS, {
        onCompleted: (response) => {
            if (response) {
                const data = response.getModels;
                setState((prevState) => ({
                    ...prevState,
                    models: data,
                }));
            }
        },
        onError: (err) => {
            ModalUtils.errorMessage([err]);
        },
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    const [createAssistant, { loading: creatingAssistant }] = useMutation(AIMutation.CREATE_ASSISTANT, {
        onCompleted: () => {
            toggleDialog();
            ModalUtils.successMessage(null, 'Assistant created successfully');
        },
        onError: (error) => {
            ModalUtils.errorMessage(null, error);
        },
    });

    const [editAssistant, { loading: editingAssistant }] = useMutation(AIMutation.MODIFY_ASSISTANT, {
        onCompleted: () => {
            toggleDialog();
            ModalUtils.successMessage(null, 'Assistant updated successfully');
        },
        onError: (error) => {
            ModalUtils.errorMessage(null, error);
        },
    });

    useEffect(() => {
        if (selectedCompany != null && AI_GLOBAL_SETTINGS_READ) loadModels({ variables: { companyCode: selectedCompany } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCompany]);

    useEffect(() => {
        if (errorLoadingEnabledCompanies) {
            ModalUtils.errorMessage(errorLoadingEnabledCompanies?.graphQLErrors);
            return;
        }

        if (!loadingEnabledCompanies) {
            const data = enabledCompaniesData?.pullCompaniesAIKeyAdded;
            if (companies) {
                setState((prevState) => ({
                    ...prevState,
                    companies: (data ?? []).filter((i) => i.platform === AI_PLATFORM.OPEN_AI),
                }));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingEnabledCompanies, errorLoadingEnabledCompanies]);

    const onChange = (name, value) => {
        setState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const saveAssistant = () => {
        const selectedFiles = filesReference?.current?.getFilesSelected() ?? [];
        const selectedFunctions = functionsReference?.current?.getFunctionsSelected() ?? [];

        if (aiAssistantId) {
            const payload = {
                assistantId: aiAssistantId,
                name: selectedName,
                instructions: selectedInstructions,
                model: selectedModel,
                temperature: selectedTemperature,
                maxResponseTokens: selectedMaxTokens,
                isLive,
                notifyAIWhilePaused,
                ...(selectedFiles.length > 0 ? {
                    files: selectedFiles,
                } : {}),
                ...(selectedFunctions.length > 0 ? {
                    functions: selectedFunctions,
                } : {}),
            };

            editAssistant({
                variables: payload,
            });
            return;
        }

        const companyName = companies.find((c) => c.companyCode === selectedCompany)?.companyName;
        const payload = {
            companyCode: selectedCompany,
            companyName,
            name: selectedName,
            instructions: selectedInstructions,
            model: selectedModel,
            temperature: selectedTemperature,
            maxResponseTokens: selectedMaxTokens,
            isLive,
            notifyAIWhilePaused,
            ...(selectedFiles.length > 0 ? {
                files: selectedFiles,
            } : {}),
            ...(selectedFunctions.length > 0 ? {
                functions: selectedFunctions,
            } : {}),
        };

        createAssistant({
            variables: payload,
        });
    };

    const isSaveDisabled = creatingAssistant
    || editingAssistant
    || StringUtils.isEmpty(selectedName)
    || (AI_GLOBAL_SETTINGS_READ && StringUtils.isEmpty(selectedCompany))
    || StringUtils.isEmpty(selectedInstructions);
    return (
        <Dialog
            open
            fullWidth
            maxWidth="md"
            disableBackdropClick
            disableEscapeKeyDown
            scroll="paper"
            onMouseDown={(e) => e.stopPropagation()}
            disableEnforceFocus
        >
            <DialogAppBar
                appBarClassName={classes.AppBar}
                title="Assistant"
                onClose={() => toggleDialog()}
                toolbarSize="md"
            />
            <DialogContent>
                <div id="main-container" className={classes.content}>
                    <div>
                        <div>
                            <Form.Group as={Col}>
                                <Form.Label className={classes.labels}>Name</Form.Label>
                                <Form.Control
                                    maxLength="255"
                                    className={clsx(classes.input, StringUtils.isEmpty(selectedName) ? 'invalid-field' : '')}
                                    type="text"
                                    name="selectedName"
                                    value={selectedName ?? ''}
                                    onChange={(e) => onChange(e.target.name, e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label className={classes.labels}>Temperature</Form.Label>
                                <InputNumber
                                    value={selectedTemperature}
                                    onChange={(newValue) => onChange('selectedTemperature', newValue)}
                                    min={0}
                                    max={2}
                                    decimalScale={1}
                                    size="sm"
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label className={classes.labels}>Max Response Tokens</Form.Label>
                                <InputNumber
                                    value={selectedMaxTokens}
                                    onChange={(newValue) => onChange('selectedMaxTokens', newValue)}
                                    min={10}
                                    max={500}
                                    decimalScale={0}
                                    size="sm"
                                />
                            </Form.Group>
                        </div>
                        {AI_GLOBAL_SETTINGS_READ && (
                            <div>
                                <Form.Group as={Col}>
                                    <Form.Label className={classes.labels}>Company</Form.Label>
                                    <Select
                                        size="sm"
                                        loading={loadingEnabledCompanies}
                                        className={clsx(classes.input, StringUtils.isEmpty(selectedCompany) ? 'invalid-field' : '')}
                                        name="selectedCompany"
                                        onChange={(name, value) => onChange(name, value)}
                                        value={selectedCompany ?? ''}
                                        options={companies.map((c) => ({
                                            value: c.companyCode,
                                            label: `${c.companyCode} - ${c.companyName}`,
                                        }))}
                                    />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label className={classes.labels}>Model</Form.Label>
                                    <Select
                                        size="sm"
                                        loading={loadingModels}
                                        className={classes.input}
                                        name="selectedModel"
                                        onChange={(name, value) => onChange(name, value)}
                                        value={selectedModel ?? ''}
                                        options={models.map((m) => ({
                                            value: m,
                                            label: m,
                                        }))}
                                    />
                                </Form.Group>
                            </div>
                        )}
                    </div>
                    <div>
                        <Form.Group as={Col}>
                            <Form.Label className={classes.labels}>Instructions</Form.Label>
                            <Form.Control
                                className={StringUtils.isEmpty(selectedInstructions) ? 'invalid-field' : ''}
                                as="textarea"
                                size="sm"
                                value={selectedInstructions ?? ''}
                                onChange={(e) => onChange('selectedInstructions', e.target.value)}
                            />
                        </Form.Group>
                    </div>
                    <AssistantFiles
                        ref={filesReference}
                        files={files}
                        parentActionInProgress={creatingAssistant || editingAssistant}
                    />
                    <AssistantFunctions
                        ref={functionsReference}
                        existingFunctions={assistantFunctions}
                        parentActionInProgress={creatingAssistant || editingAssistant}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Form.Group as={Col}>
                    <FormControlLabel
                        control={(
                            <Checkbox
                                checked={isLive}
                                onClick={(e) => onChange('isLive', e.target.checked)}
                            />
                        )}
                        label="Live"
                    />
                    <FormControlLabel
                        control={(
                            <Checkbox
                                checked={notifyAIWhilePaused}
                                onClick={(e) => onChange('notifyAIWhilePaused', e.target.checked)}
                            />
                        )}
                        label="Let AI be aware of conversation while paused (collections)"
                    />
                </Form.Group>
                <Button
                    disabled={isSaveDisabled}
                    className={classes.containedSecondaryInfo}
                    size="small"
                    onClick={saveAssistant}
                >
                    {creatingAssistant || editingAssistant ? 'Saving...' : 'Save'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

AssistantForm.defaultProps = {
    assistant: null,
};

AssistantForm.propTypes = {
    toggleDialog: PropTypes.func.isRequired,
    assistant: PropTypes.object,
};

export default AssistantForm;
