import { gql } from '@apollo/client';

export default class AIMutation {
    static ADD_KEY_TO_COMPANY = gql`
        mutation addAIKeyToCompany(
            $companyCode: Int!,
            $key: String!,
            $platform: String!
        ) {
            addAIKeyToCompany(
                companyCode: $companyCode
                key: $key
                platform: $platform
            )
        }
    `;

    static REMOVE_KEY_TO_COMPANY = gql`
        mutation removeAIKeyFromCompany(
            $companyCode: Int!,
            $platform: String!
        ) {
            removeAIKeyFromCompany(
                companyCode: $companyCode
                platform: $platform
            )
        }
    `;

    static CREATE_FILE_FOR_ASSISTANT = gql`
        mutation createFileForAssistant(
            $companyCode: Int!,
            $file: Upload!
        ) {
            createFileForAssistant(
                companyCode: $companyCode
                file: $file
            )
        }
    `;

    static UPLOAD_FILE_ON_BUCKET = gql`
        mutation uploadFileOnBucket(
            $file: Upload!
        ) {
            uploadFileOnBucket(
                file: $file
            )
        }
    `;

    static EDIT_FILE_ON_BUCKET = gql`
        mutation editFileOnBucket(
            $path: String!,
            $file: Upload!
        ) {
            editFileOnBucket(
                path: $path
                file: $file
            )
        }
    `;

    static CREATE_ASSISTANT = gql`
        mutation createAsistant(
            $companyCode: Int,
            $companyName: String,
            $name: String!,
            $instructions: String!,
            $model: String!,
            $temperature: Float!,
            $maxResponseTokens: Int!,
            $isLive: Boolean!,
            $notifyAIWhilePaused: Boolean!,
            $files: [AssistantFile!],
            $functions: [AssistantFunction!]
        ) {
            createAsistant(
                companyCode: $companyCode
                companyName: $companyName
                name: $name
                instructions: $instructions
                model: $model
                temperature: $temperature
                maxResponseTokens: $maxResponseTokens
                isLive: $isLive
                notifyAIWhilePaused: $notifyAIWhilePaused
                files: $files
                functions: $functions
            )
        }
    `;

    static MODIFY_ASSISTANT = gql`
        mutation modifyAssistant(
            $assistantId: String!
            $name: String!,
            $instructions: String!,
            $model: String!,
            $temperature: Float!,
            $maxResponseTokens: Int!,
            $isLive: Boolean!,
            $notifyAIWhilePaused: Boolean!,
            $files: [AssistantFile!],
            $functions: [AssistantFunction!]
        ) {
            modifyAssistant(
                assistantId: $assistantId
                name: $name
                instructions: $instructions
                model: $model
                temperature: $temperature
                maxResponseTokens: $maxResponseTokens
                isLive: $isLive
                notifyAIWhilePaused: $notifyAIWhilePaused
                files: $files
                functions: $functions
            )
        }
    `;

    static DELETE_ASSISTANT = gql`
        mutation deleteAssistant(
            $assistantId: String!
        ) {
            deleteAssistant(
                assistantId: $assistantId
            )
        }
    `;

    static CREATE_THREAD = gql`
        mutation createThread(
            $companyCode: Int!
        ) {
            createThread(
                companyCode: $companyCode
            )
        }
    `;

    static ADD_MESSAGE_IN_THREAD = gql`
        mutation addMessageInThread(
            $assistantId: String!,
            $threadId: String!,
            $role: String!,
            $content: String!,
            $generalData: String
        ) {
            addMessageInThread(
                assistantId: $assistantId
                threadId: $threadId
                role: $role
                content: $content
                generalData: $generalData
            )
        }
    `;

    static CREATE_GLOBAL_FILE = gql`
        mutation createGlobalFile(
            $name: String!,
            $size: Float!,
            $file: Upload!
        ) {
            createGlobalFile(
                name: $name
                size: $size
                file: $file
            )
        }
    `;

    static EDIT_GLOBAL_FILE = gql`
        mutation editGlobalFile(
            $fileId: String!,
            $path: String!,
            $size: Float!,
            $file: Upload!
        ) {
            editGlobalFile(
                fileId: $fileId
                path: $path
                size: $size
                file: $file
            )
        }
    `;

    static DELETE_GLOBAL_FILE = gql`
        mutation deleteGlobalFile(
            $fileId: String!
        ) {
            deleteGlobalFile(
                fileId: $fileId
            )
        }
    `;

    static UPDATE_ASSISTANTS_GLOBAL_FILES = gql`
        mutation updateAssistantsGlobalFiles(
            $modifiedFilesURLs: [String!]!
        ) {
            updateAssistantsGlobalFiles(
                modifiedFilesURLs: $modifiedFilesURLs
            )
        }
    `;

    static MODIFY_VOICE_ASSISTANT = gql`
        mutation modifyVoiceAssistant(
            $companyCode: Int!,
            $assistantId: String!,
            $functions: [AssistantFunction!]
        ) {
            modifyVoiceAssistant(
                companyCode: $companyCode
                assistantId: $assistantId
                functions: $functions
            )
        }
    `;
}
