/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import StringUtils from 'lib/StringUtils';
import {
    makeStyles,
    FormLabel,
    FormControl,
    FormHelperText,
    Button,
    Dialog,
    DialogContent,
    DialogActions,
} from '@material-ui/core';
import { Form } from 'react-bootstrap';
import Select from 'components/widgets/Select';
import VirtualTable from 'components/widgets/VirtualTable';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import ButtonStyles from 'styles/theme/Button';
import useVehicleStyles from 'components/hook/inventory/useVehicleStyles';

// Icons
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const useStyles = makeStyles((theme) => ({
    AppBar: {
        color: theme.palette.text.white,
        backgroundColor: theme.palette.background.sanMarino,
        '& h4': {
            fontSize: '16px',
            color: theme.palette.text.white,
        },
    },
    content: {
        padding: '10px 10px',
        display: 'flex',
        flexWrap: 'wrap',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            paddingRight: 0,
            flexDirection: 'column',
        },
    },
    tableContainer: {
        width: '100%',
        height: '500px',
        overflow: 'auto',
        [theme.breakpoints.down('sm')]: {
            height: '400px',
        },
        '& > div': {
            overflowY: 'hidden',
            overflowX: 'auto',
        },
        '& .ReactVirtualized__Table > .ReactVirtualized__Table__headerRow': {
            backgroundColor: `${theme.palette.background.white} !important`,
            border: `1px solid rgba(${theme.palette.rgb.black}, 0.1)`,
            marginBottom: '2px',
            '& > div': {
                height: '30px',
                alignItems: 'center',
            },
        },
        '& .ReactVirtualized__Table__rowColumn': {
            marginLeft: '0px',
            paddingLeft: '5px',
            '& > div[class^="makeStyles-main"]': {
                [theme.breakpoints.down('sm')]: {
                    marginLeft: '10px',
                },
            },
            '& > .react-datepicker-wrapper input': {
                textAlign: 'center',
                fontSize: '12px',
            },
        },
        '& .DragHandleIcon': {
            color: theme.palette.text.waterloo,
        },
    },
    dialogActions: {
        paddingRight: '33px',
        '& > input': {
            width: '200px',
            [theme.breakpoints.down('sm')]: {
                width: '135px',
            },
        },
    },
    tableCell: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        '& > button': {
            padding: 0,
            minWidth: '32px',
            '& > span > span': {
                margin: 0,
            },
        },
        '& > button:nth-child(1) svg': {
            fill: theme.palette.info.main,
        },
        '& > button:nth-child(2) svg': {
            fill: theme.palette.error.main,
        },
    },
}));

const VehicleStyleDropdown = ({
    style,
    options,
    onChange,
}) => {
    const classes = { ...useStyles(), ...buttonStyles() };
    const invalidStyle = StringUtils.isEmpty(style);
    const { loadingStyles, savingStyle, saveVehicleStyle } = useVehicleStyles();
    const [state, setState] = useState({
        styles: [],
        newStyle: null,
        selectedStyle: null,
        isFormOpen: false,
        isAddingFieldShown: false,
        isDeletePromptShown: false,
    });

    const {
        styles,
        newStyle,
        selectedStyle,
        isFormOpen,
        isAddingFieldShown,
        isDeletePromptShown,
    } = state;

    useEffect(() => {
        setState((prevState) => ({
            ...prevState,
            styles: options ?? [],
        }));
    }, [options]);

    const toggleForm = () => {
        setState((prevState) => ({
            ...prevState,
            isFormOpen: !prevState.isFormOpen,
        }));
    };

    const cancelAdding = () => {
        setState((prevState) => ({
            ...prevState,
            newStyle: null,
            isAddingFieldShown: false,
        }));
    };

    const prepareField = () => {
        setState((prevState) => ({
            ...prevState,
            isAddingFieldShown: true,
        }));
    };

    const addNewStyle = () => {
        saveVehicleStyle({
            variables: {
                name: newStyle,
            },
        });

        setState((prevState) => ({
            ...prevState,
            newStyle: null,
            selectedStyle: null,
            isAddingFieldShown: false,
            isDeletePromptShown: false,
        }));
    };

    const onChangeStyle = (value) => {
        setState((prevState) => ({
            ...prevState,
            newStyle: value,
        }));
    };

    const cancelEdit = (record) => {
        const clone = cloneDeep(styles);
        const current = clone.find((item) => item.id === record.id);
        current.isEdited = false;

        setState((prevState) => ({
            ...prevState,
            newStyle: null,
            styles: clone,
        }));
    };

    const prepareEdit = (record) => {
        const clone = cloneDeep(styles);
        clone.forEach((item) => {
            if (item.id === record.id) {
                item.isEdited = true;
            } else {
                item.isEdited = false;
            }
        });

        setState((prevState) => ({
            ...prevState,
            newStyle: record.name,
            selectedStyle: record,
            styles: clone,
        }));
    };

    const onEditStyle = (id) => {
        saveVehicleStyle({
            variables: {
                id,
                name: newStyle,
            },
        });

        setState((prevState) => ({
            ...prevState,
            newStyle: null,
            selectedStyle: null,
            isAddingFieldShown: false,
            isDeletePromptShown: false,
        }));
    };

    const toggleDeletePrompt = (record) => {
        setState((prevState) => ({
            ...prevState,
            selectedStyle: record,
            isDeletePromptShown: !prevState.isDeletePromptShown,
        }));
    };

    const onDeleteAction = () => {
        const { id, name } = selectedStyle;

        saveVehicleStyle({
            variables: {
                id,
                name,
                active: false,
            },
        });

        setState((prevState) => ({
            ...prevState,
            newStyle: null,
            selectedStyle: null,
            isAddingFieldShown: false,
            isDeletePromptShown: false,
        }));
    };

    const columns = [
        {
            label: 'Style',
            dataKey: 'name',
            width: 150,
            disableSort: true,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                const { name, isEdited = false } = record;

                return isEdited && selectedStyle ? (
                    <Form.Control
                        autoFocus
                        type="text"
                        size="sm"
                        value={newStyle || ''}
                        maxLength={50}
                        onChange={({ target }) => onChangeStyle(target.value)}
                    />
                ) : (
                    <div className={classes.tableCell}>{name}</div>
                );
            },
        },
        {
            label: 'Actions',
            dataKey: 'actions',
            width: 200,
            disableSort: true,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                const { id, name, isEdited = false } = record;
                if (!id) return null;

                return (
                    <div className={classes.tableCell}>
                        <Button
                            disabled={loadingStyles || savingStyle || isAddingFieldShown || (isEdited && newStyle === name)}
                            size="small"
                            startIcon={isEdited ? <SaveOutlinedIcon /> : <EditOutlinedIcon />}
                            onClick={() => (isEdited ? onEditStyle(id) : prepareEdit(record))}
                        />
                        {!isEdited && (
                            <Button
                                disabled={loadingStyles || savingStyle || isAddingFieldShown}
                                size="small"
                                startIcon={<DeleteOutlineOutlinedIcon />}
                                onClick={() => toggleDeletePrompt(record)}
                            />
                        )}
                        {isEdited && (
                            <Button
                                size="small"
                                startIcon={<CloseOutlinedIcon />}
                                onClick={() => cancelEdit(record)}
                            />
                        )}
                    </div>
                );
            },
        },
    ];

    const editableStyles = styles.filter((item) => !item.isCore);
    const anyBeingEdited = editableStyles.some((m) => m.isEdited);
    return (
        <>
            <FormControl>
                <FormLabel>Style</FormLabel>
                <Select
                    name="style"
                    onChange={(_, newValue) => onChange('style', newValue)}
                    value={style}
                    options={[
                        ...styles.map((item) => ({
                            value: item.name,
                            label: item.name,
                        })),
                        {
                            label: (
                                <Button
                                    className={classes.containedInfo}
                                    size="small"
                                    fullWidth
                                    onClick={toggleForm}
                                >
                                    Add / Edit
                                </Button>
                            ),
                            value: '-1',
                            isDisabled: true,
                        },
                    ]}
                    size="sm"
                    className={clsx({ 'invalid-field': invalidStyle })}
                />
                {invalidStyle && (<FormHelperText error>The Style field is required</FormHelperText>)}
            </FormControl>
            {isFormOpen && (
                <Dialog
                    open
                    fullWidth
                    maxWidth="sm"
                    disableBackdropClick
                    disableEscapeKeyDown
                    scroll="paper"
                    onMouseDown={(e) => e.stopPropagation()}
                >
                    <DialogAppBar
                        appBarClassName={classes.AppBar}
                        title="Styles"
                        onClose={toggleForm}
                        toolbarSize="md"
                    />
                    <DialogContent>
                        <div className={classes.content}>
                            <div className={classes.tableContainer}>
                                <VirtualTable
                                    loading={false}
                                    rowHeight={35}
                                    totalRecords={editableStyles.length}
                                    data={editableStyles}
                                    columns={columns}
                                />
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions className={classes.dialogActions}>
                        {isAddingFieldShown && (
                            <Form.Control
                                autoFocus
                                type="text"
                                size="sm"
                                value={newStyle || ''}
                                maxLength={50}
                                onChange={({ target }) => onChangeStyle(target.value)}
                            />
                        )}
                        <Button
                            disabled={loadingStyles || savingStyle || (isAddingFieldShown && StringUtils.isEmpty(newStyle)) || anyBeingEdited}
                            size="small"
                            className={classes.containedSecondaryInfo}
                            onClick={() => (isAddingFieldShown ? addNewStyle() : prepareField())}
                        >
                            {isAddingFieldShown ? 'Save' : 'Add'}
                        </Button>
                        {isAddingFieldShown && (
                            <Button
                                disabled={loadingStyles || savingStyle}
                                size="small"
                                className={classes.containedError}
                                onClick={cancelAdding}
                            >
                                Cancel
                            </Button>
                        )}
                    </DialogActions>
                </Dialog>
            )}
            <ConfirmDialog
                title="Attention!"
                description="Do you want to remove this style?"
                open={isDeletePromptShown}
                variant="outlined"
                titlePrimary="Yes"
                titleSecondary="Cancel"
                onClose={() => toggleDeletePrompt()}
                onClickSecondary={() => toggleDeletePrompt()}
                onClickPrimary={onDeleteAction}
                disablePrimaryButton={loadingStyles || savingStyle}
                disableSecondaryButton={loadingStyles || savingStyle}
            />
        </>
    );
};

VehicleStyleDropdown.propTypes = {
    style: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.array,
};

VehicleStyleDropdown.defaultProps = {
    style: '',
    options: [],
};

export default VehicleStyleDropdown;
