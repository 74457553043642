/* eslint-disable consistent-return */
import React, { PureComponent } from 'react';

// Components and Others
import clsx from 'clsx';
import { map, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import Label from 'components/widgets/Label';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import { ReactComponent as RemoveOutlined } from 'assets/remove.svg';
import AddressContent from 'components/widgets/customer/AddressContent';
import AddressHistory from 'components/modules/deals/read/buyer/AddressHistory';
import AddressContainer from 'components/containers/deals/read/buyer/AddressContainer';
import AddressDialog from 'components/widgets/customer/AddressDialog';
import ManageAddress from 'components/widgets/customer/ManageAddress';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import {
    IconButton, Button, Divider, Paper, Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { BorderColorOutlined as EditIcon } from '@material-ui/icons';
import Skeleton from '@material-ui/lab/Skeleton';

// Utils
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import DealUtils from 'utils/DealUtils';
import { DealStatus } from 'utils/enum/DealEnum';

const styles = (theme) => ({
    content: {
        padding: theme.spacing(1, 0),
        display: 'flex',
    },
    current: {
        color: theme.palette.text.shipCove,
        background: theme.palette.background.alabaster,
        borderRadius: '10px',
        height: '20px',
        fontSize: '12px',
        fontFamily: 'Roboto',
        fontWeight: 400,
        width: '58px',
        textTransform: 'capitalize',
    },
    classesFooter: {
        padding: theme.spacing(0, 1),
        minWidth: '74px',
    },
    contentButtonDelete: {
        textAlign: 'end',
    },
    currentAddress: {
        alignItems: 'flex-end',
    },
    title: {
        color: theme.palette.text.stormGray,
        fontWeight: 500,
    },
    paper: {
        minHeight: 310,
        maxHeight: 310,
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '0px 1px 4px rgba(0,0,0, 0.1)',
        width: '100%',
    },
    footer: {
        padding: theme.spacing(0.5, 1.8),
    },
    primaryButton: {
        color: theme.palette.info.main,
    },
    body: {
        flex: 1,
        overflow: 'auto',
    },
    boxHeader: {
        padding: theme.spacing(1, 2),
        minHeight: 38,
    },
    generalClassBox: {
        boxShadow: '0px 1px 4px rgba(0,0,0, 0.1)',
        width: '100%',
    },
    updateButton: {
        color: theme.palette.warn.main,
        marginTop: '60px',
    },
    editButton: {
        color: theme.palette.text.gray,
        marginTop: '60px',
    },
    outdatedTag: {
        color: theme.palette.text.white,
        background: theme.palette.error.light,
        borderRadius: '10px',
        height: '20px',
        fontSize: '12px',
        fontFamily: 'Roboto',
        fontWeight: 400,
        width: '58px',
        textTransform: 'capitalize',
    },
    loading: {
        padding: '30px',
        height: '150px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    addButton: {
        padding: '0px',
    },
});

class Address extends PureComponent {
    constructor(props) {
        super(props);
        const keyStore = new KeyStore();
        this.SALES_DEAL_CUSTOMER_WRITE = keyStore.hasPermission(Permission.SALES_DEAL_CUSTOMER_WRITE);
    }

    renderButtonDelete(customerAddressId) {
        const { props: { classes, onOpenConfirm } } = this;

        return (
            <div className={classes.contentButtonDelete}>
                <IconButton
                    aria-label="delete"
                    size="small"
                    className="show-hidden-item"
                    onClick={() => onOpenConfirm(customerAddressId)}
                >
                    <RemoveOutlined />
                </IconButton>
            </div>
        );
    }

    render() {
        const {
            props: {
                classes, buyerAddress, openConfirm, onClose, record, customerCurrentAddressId,
                onDelete, onAddAddress, open, openFormDialog, accountNumber, clientId, saving, toggleManageAddress,
                onCloseConfirm, onOpen, customerId, loading, deal, toggleFormModal, setCurrentAddress, openDialogManage,
                makeReloadAddress, reloadManagerAddress, onEdit, editFromManage, editingStatus,
            },
        } = this;
        const { postedDate, stage: status } = deal;
        const { isEditing } = editingStatus;
        const allowEdit = postedDate === null && (status === DealStatus.QUOTE || status === DealStatus.FNI || status === DealStatus.DELETED);
        const dealCurrentAddress = buyerAddress?.find((x) => x.isCurrentAddress);
        const isNotPosted = deal?.postedDate === null;
        const allowAddAddress = isNotPosted && isEmpty(dealCurrentAddress) && !isEditing;
        const allowEditAddress = isNotPosted && !isEmpty(dealCurrentAddress) && !isEditing;
        const isAddressOutdated = isNotPosted && customerCurrentAddressId !== dealCurrentAddress?.customerAddressId;

        return (
            <Paper
                elevation={0}
                square
                className={clsx(classes.paper)}
            >
                <div className={clsx(classes.boxHeader, 'd-flex-center-space-between')}>
                    <Typography variant="h5" color="primary" className={classes.title}>Address</Typography>
                    <div>
                        {DealUtils.clientIdIsWeb(clientId) && isNotPosted && allowEdit && this.SALES_DEAL_CUSTOMER_WRITE && allowAddAddress && (
                            <IconButton
                                color="primary"
                                aria-label="Add Address"
                                component="span"
                                onClick={onAddAddress}
                                className={classes.addButton}
                            >
                                <AddIcon />
                            </IconButton>
                        )}
                        {DealUtils.clientIdIsWeb(clientId) && isNotPosted && allowEdit && this.SALES_DEAL_CUSTOMER_WRITE && allowEditAddress && (
                            <Button
                                color="primary"
                                size="small"
                                startIcon={<EditIcon />}
                                onClick={toggleManageAddress}
                            >
                                Manage
                            </Button>
                        )}
                    </div>
                </div>
                <Divider />
                <div className={classes.body}>
                    {(() => {
                        if (saving) {
                            return (
                                <div className={classes.loading}>
                                    <Skeleton />
                                    <Skeleton />
                                    <Skeleton />
                                </div>
                            );
                        }

                        return map(buyerAddress, (item, index) => {
                            const rightSectionTagLabel = isAddressOutdated ? 'Outdated' : 'Current';
                            const rightSectionTag = <Label className={isAddressOutdated ? classes.outdatedTag : classes.current}>{rightSectionTagLabel}</Label>;
                            let rightSectionButton = null;

                            if (DealUtils.clientIdIsWeb(clientId)
                                && isNotPosted
                                && allowEdit
                                && this.SALES_DEAL_CUSTOMER_WRITE
                                && allowEditAddress
                                && !isAddressOutdated) {
                                rightSectionButton = (
                                    <Button
                                        className={classes.editButton}
                                        variant="text"
                                        onClick={onAddAddress}
                                    >
                                        Edit
                                    </Button>
                                );
                            } else if (isAddressOutdated) {
                                rightSectionButton = (
                                    <Button
                                        className={classes.updateButton}
                                        variant="text"
                                        onClick={() => setCurrentAddress(customerCurrentAddressId)}
                                    >
                                        Update
                                    </Button>
                                );
                            }

                            let rightSection = (
                                <div>
                                    {rightSectionTag}
                                    {rightSectionButton}
                                </div>
                            );
                            let classCurrentAddress = '';

                            if (!item.isCurrentAddress) {
                                rightSection = this.SALES_DEAL_CUSTOMER_WRITE ? this.renderButtonDelete(item.dealPreviousAddressId) : null;
                                classCurrentAddress = classes.currentAddress;
                            }

                            return (
                                <AddressContent
                                    key={index}
                                    record={item}
                                    className={clsx(classes.content, classCurrentAddress, 'show-icon-when-hovering')}
                                    classNameFooter={classes.classesFooter}
                                    rightSection={rightSection}
                                />
                            );
                        });
                    }
                    )()}
                </div>
                {DealUtils.clientIdIsWeb(clientId) && isNotPosted && allowEdit && this.SALES_DEAL_CUSTOMER_WRITE && (
                    <>
                        <Divider />
                        <div className={clsx(classes.footer, 'd-flex-justify-end')}>
                            <Button
                                disabled={loading}
                                size="small"
                                className={classes.primaryButton}
                                onClick={onOpen}
                            >
                                Add previous
                            </Button>
                        </div>
                    </>
                )}
                <ConfirmDialog
                    title="Confirm remove address"
                    description="Are you sure you want to remove this address?"
                    open={openConfirm}
                    variant="outlined"
                    titlePrimary="Yes"
                    titleSecondary="No"
                    onClose={onCloseConfirm}
                    onClickSecondary={onCloseConfirm}
                    onClickPrimary={onDelete}
                />
                {open && <AddressHistory open={open} onClose={onClose} accountNumber={accountNumber} customerId={customerId} />}
                {openFormDialog
                && (
                    <AddressDialog
                        open={openFormDialog}
                        customerId={customerId}
                        record={record}
                        toggleModal={toggleFormModal}
                        isEditing={!isEmpty(record)}
                        handleCreatedAddress={editFromManage ? null : setCurrentAddress}
                    />
                )}
                {openDialogManage && (
                    <ManageAddress
                        open={openDialogManage}
                        toggleModal={toggleManageAddress}
                        makeReloadAddress={makeReloadAddress}
                        customerId={customerId}
                        onEdit={onEdit}
                        openAddress={toggleFormModal}
                        reloadManagerAddress={reloadManagerAddress}
                    />
                )}
            </Paper>
        );
    }
}

Address.propTypes = {
    buyerAddress: PropTypes.arrayOf(
        PropTypes.shape({
            address1: PropTypes.string,
            address2: PropTypes.string,
            city: PropTypes.string,
            state: PropTypes.string,
            zipCode: PropTypes.string,
            county: PropTypes.string,
            country: PropTypes.string,
            phone: PropTypes.string,
            housingStatus: PropTypes.string,
            mortgageOrRent: PropTypes.number,
            totalMonths: PropTypes.number,
        }),
    ).isRequired,
    open: PropTypes.bool.isRequired,
    openFormDialog: PropTypes.bool.isRequired,
    onOpen: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    openConfirm: PropTypes.bool.isRequired,
    onOpenConfirm: PropTypes.func.isRequired,
    onCloseConfirm: PropTypes.func.isRequired,
    onAddAddress: PropTypes.func.isRequired,
    toggleFormModal: PropTypes.func.isRequired,
    accountNumber: PropTypes.number.isRequired,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
    editingMode: PropTypes.shape({
        isEditing: PropTypes.bool,
        sectionName: PropTypes.string,
    }).isRequired,
    customerId: PropTypes.string.isRequired,
    clientId: PropTypes.string.isRequired,
    setCurrentAddress: PropTypes.func.isRequired,
    saving: PropTypes.bool.isRequired,
    openDialogManage: PropTypes.bool.isRequired,
    toggleManageAddress: PropTypes.func.isRequired,
    reloadManagerAddress: PropTypes.bool.isRequired,
    makeReloadAddress: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    editFromManage: PropTypes.bool.isRequired,
    loading: PropTypes.bool,
    deal: PropTypes.object,
    record: PropTypes.object,
    customerCurrentAddressId: PropTypes.string,
    editingStatus: PropTypes.object,
};

Address.defaultProps = {
    loading: false,
    deal: {},
    record: {},
    customerCurrentAddressId: null,
    editingStatus: {
        isEditing: false,
    },
};

export default withStyles(styles)(AddressContainer(Address));
