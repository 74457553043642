import React from 'react';
import PropTypes from 'prop-types';
import {
    makeStyles,
    FormLabel,
    Checkbox,
    Grid,
} from '@material-ui/core';
import { modules } from 'utils/enum/modules';
import { Link as RouteLink } from 'react-router-dom';
import { PurchaseAgentType } from 'utils/enum/InventoryEnum';
import InventoryDetailStyle from 'styles/modules/inventory/read/InventoryDetailStyle';
import DisplayField from 'components/modules/inventory/read/panels/DisplayField';
import StringUtils from 'lib/StringUtils';
import DateUtils from 'lib/DateUtils';

const useStyles = makeStyles((theme) => InventoryDetailStyle.detailPanels(theme));

const PurchasePanel = ({ data }) => {
    const classes = useStyles();

    const { pricing = {} } = data;
    const spareKey = !StringUtils.isEmpty(data.spareKey) ? data.spareKey : 'No';
    const tagExpires = !StringUtils.isEmpty(data.tagExpires)
        ? DateUtils.format(data.tagExpires) : '';
    const isThereAccountNumber = !StringUtils.isEmpty(pricing.tradeInAssociatedAccountNumber);

    return (
        <Grid
            container
            orientation="column"
            className={classes.labelsGrid}
        >
            <Grid
                item
                xs={12}
                sm={6}
                className={classes.purchasedFrom}
            >
                <FormLabel className={classes.labelRow}>
                    <span>Purchased From:</span>
                    {pricing.purchasedAgent !== PurchaseAgentType.TRADE_IN && pricing.purchasedFrom}
                    {pricing.purchasedAgent === PurchaseAgentType.TRADE_IN && (
                        <RouteLink
                            to={isThereAccountNumber ? {
                                pathname: `/${modules.DEALS}/${pricing.tradeInAssociatedAccountNumber}`,
                            } : {
                                pathname: `/${modules.DEALS}/`,
                                search: `?search=${pricing.purchasedFrom}`,
                            }}
                            target="_blank"
                        >
                            {pricing.purchasedFrom}
                        </RouteLink>
                    )}
                </FormLabel>
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormLabel className={classes.labelRow}>
                    <span>Title: </span>
                    <div className={classes.title}>
                        <Checkbox
                            checked={data.title || false}
                            disabled
                        />
                        <div className="title-number">{data.titleNumber}</div>
                        <div>{data.titleStatus}</div>
                    </div>
                </FormLabel>
            </Grid>
            <DisplayField
                field="Purchase Agent"
                value={pricing.purchasedAgent}
            />
            <DisplayField
                field="Purchase Date"
                value={pricing.purchasedDate}
                type="date"
            />
            <DisplayField
                field="Spare Key"
                value={spareKey}
            />
            <DisplayField
                field="Tag"
                value={data.tag}
            />
            <DisplayField
                field="Tag Expires"
                value={tagExpires}
            />
        </Grid>
    );
};

PurchasePanel.propTypes = {
    data: PropTypes.object,
};

PurchasePanel.defaultProps = {
    data: {},
};

export default PurchasePanel;
