import { useReducer } from 'react';
import { useLazyQuery } from '@apollo/client';
import { DataSort, FetchPolicy } from 'utils/enum/Core';
import ServiceQuery from 'services/graphQL/query/service/ServiceQuery';
import ModalUtils from 'utils/ModalUtils';

const ACTION_TYPES = {
    LOAD_MORE: 'setLoadMore',
    SET_PARAMS: 'setParams',
    SET_TABLE: 'setTable',
    SET_LOADING: 'setLoading',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_TABLE: {
        return {
            ...state,
            table: action.value,
        };
    }
    case ACTION_TYPES.SET_PARAMS: {
        return {
            ...state,
            table: {
                records: [],
                totalCount: 0,
            },
            params: action.value,
        };
    }
    case ACTION_TYPES.LOAD_MORE: {
        return {
            ...state,
            params: action.value,
        };
    }
    default: return action.value;
    }
};

const useTechnicianJobs = () => {
    const initState = {
        params: {
            paginate: {
                init: 0,
                limit: 10,
            },
            search: null,
        },
        table: {
            records: [],
            totalCount: 0,
        },
    };
    const [state, dispatch] = useReducer(reducer, initState);
    const {
        params,
        params: {
            paginate,
            search,
        },
        table: {
            records,
        },
    } = state;

    const [loadData, { loading }] = useLazyQuery(ServiceQuery.GET_TECHNICIAN_JOBS, {
        variables: {
            paginate,
            filter: {
                searchTerm: search,
            },
            sort: [
                { fieldName: 'invoiceNumber', dir: DataSort.DESC },
                { fieldName: 'jobNumber', dir: DataSort.ASC },
            ],
        },
        onCompleted: (res) => {
            const { getTechniciansJobs: { data: dataRecords, totalCount } } = res;

            if (dataRecords?.length > 0) {
                records.push(...dataRecords);

                dispatch({
                    type: ACTION_TYPES.SET_TABLE,
                    value: {
                        records,
                        totalCount,
                    },
                });
            }
        },
        onError: (errorMessage) => {
            const { message, graphQLErrors } = errorMessage;
            ModalUtils.errorMessage((graphQLErrors?.length > 0 ? graphQLErrors : null), message);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const onFilterChange = (param, value) => {
        if (params[param] === value) return;

        dispatch({
            type: ACTION_TYPES.SET_PARAMS,
            value: {
                ...params,
                [param]: value,
                paginate: {
                    ...paginate,
                    init: 0,
                },
            },
        });
    };

    const onSearch = (text) => {
        dispatch({
            type: ACTION_TYPES.SET_PARAMS,
            value: {
                ...params,
                search: text,
                paginate: {
                    ...paginate,
                    init: 0,
                },
            },
        });
    };

    const loadMore = () => {
        const currentOffset = records?.length || 0;

        dispatch({
            type: ACTION_TYPES.LOAD_MORE,
            value: {
                ...params,
                paginate: {
                    ...paginate,
                    init: currentOffset,
                },
            },
        });
    };

    return {
        state,
        loading,
        loadMore,
        loadData,
        onSearch,
        onFilterChange,
    };
};

export default useTechnicianJobs;
