import React, { useEffect, useState } from 'react';
import {
    makeStyles, Grid, Button, CircularProgress,
} from '@material-ui/core';
import update from 'immutability-helper';
import ModalUtils from 'utils/ModalUtils';
import { Col, Form } from 'react-bootstrap';
import GraphQLClient from 'services/apollo/GraphQLClient';
import CompanyQuery from 'services/graphQL/query/setting/CompanyQuery';
import CompanyMutation from 'services/graphQL/mutate/CompanyMutation';
import { SaveOutlinedIcon } from 'components/icons';
import StringUtils from 'lib/StringUtils';

const useStyles = makeStyles((theme) => ({
    box: {
        marginTop: '10px',
        paddingTop: '10px',
        border: `solid 1px ${theme.palette.border.mercury}`,
    },
}));
const graphqlClient = new GraphQLClient();

const PlaidSetting = () => {
    const classes = useStyles();

    // Initial state
    const [state, setState] = useState({
        plaidClientId: '',
        plaidSecretKey: '',
        updating: false,
    });

    const onChange = (key, value) => {
        setState((prevState) => update(prevState, {
            [key]: { $set: value },
        }));
    };

    const onSave = () => {
        onChange('updating', true);

        graphqlClient
            .mutate(CompanyMutation.UPDATE_PLAID_SETTING, {
                plaidClientId: state.plaidClientId,
                plaidSecretKey: state.plaidSecretKey,
            })
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data?.updatePlaidSetting) {
                    ModalUtils.successMessage(null, 'Plaid Setting updated successfully');
                }
            })
            .finally(() => {
                onChange('updating', false);
            });
    };

    useEffect(() => {
        graphqlClient
            .query(CompanyQuery.GET_COMPANY)
            .then((response) => {
                const { data, graphQLErrors } = response;
                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                const { getCompany = {} } = data;
                setState((prevState) => update(prevState, {
                    plaidClientId: { $set: getCompany.plaidClientId },
                    plaidSecretKey: { $set: getCompany.plaidSecretKey },
                }));
            });
    }, []);

    const isSaveDisabled = () => {
        const { plaidClientId, plaidSecretKey } = state;
        return (
            state.updating
            || (StringUtils.isEmpty(plaidClientId) && !StringUtils.isEmpty(plaidSecretKey))
            || (!StringUtils.isEmpty(plaidClientId) && StringUtils.isEmpty(plaidSecretKey))
        );
    };

    return (
        <Grid container>
            <Grid item md={6} className={classes.box}>
                <Grid container>
                    <Grid item md={6}>
                        <Form.Group as={Col}>
                            <Form.Label>Plaid Client Id</Form.Label>
                            <Form.Control
                                value={state.plaidClientId}
                                type="text"
                                disabled={state.updating}
                                onChange={(e) => onChange('plaidClientId', e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Button
                                size="small"
                                variant="outlined"
                                onClick={onSave}
                                disabled={isSaveDisabled()}
                                startIcon={<SaveOutlinedIcon />}
                            >
                                {state.updating ? 'Saving...' : 'Save'}
                                {state.updating && <CircularProgress size={20} />}
                            </Button>
                        </Form.Group>
                    </Grid>
                    <Grid item md={6}>
                        <Form.Group as={Col}>
                            <Form.Label>Plaid Secret Key</Form.Label>
                            <Form.Control
                                value={state.plaidSecretKey}
                                type="text"
                                disabled={state.updating}
                                onChange={(e) => onChange('plaidSecretKey', e.target.value)}
                            />
                        </Form.Group>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default PlaidSetting;
