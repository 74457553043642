import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

class InputNumber extends React.Component {
    getClassName() {
        const { props } = this;
        const { size, className, alignRight } = props;
        let classValue = 'form-control';

        if (size === 'sm') {
            classValue = `${classValue} form-control-sm`;
        } else if (size === 'lg') {
            classValue = `${classValue} form-control-lg`;
        }

        if (alignRight) classValue += ' align-right';

        if (className) {
            classValue = `${classValue} ${className}`;
        }

        return classValue;
    }

    highlightAmount = (event) => {
        const { suffix = '' } = this.props;
        const input = event.target;

        const valueLength = input.value.length;
        const suffixLength = suffix.length;
        const selectableLength = valueLength - suffixLength;

        if (suffix && input.value.endsWith(suffix)) {
            setTimeout(() => {
                input.setSelectionRange(0, selectableLength);
            }, 0);
        } else {
            input.select();
        }
    }

    validateAllowedValue(event) {
        const { props: { min, max } } = this;
        const { floatValue } = event;

        if (floatValue) {
            if (min !== null && max !== null) {
                return floatValue >= min && floatValue <= max;
            }

            if (min !== null) {
                return floatValue >= min;
            }

            if (max !== null) {
                return floatValue <= max;
            }
        }

        return true;
    }

    render() {
        const { props } = this;
        const {
            allowNegative, decimalScale, showCurrency, thousandSeparator,
            min, max, inputRef, onChange, fixedDecimalScale, onKeyDown, removeDecimalScale,
            alignRight, ...other
        } = props;

        return (
            <NumberFormat
                {...other}
                className={this.getClassName()}
                getInputRef={inputRef}
                prefix={showCurrency ? '$' : ''}
                thousandSeparator={thousandSeparator}
                fixedDecimalScale={fixedDecimalScale}
                isNumericString
                onValueChange={(values) => {
                    onChange(values.floatValue || 0);
                }}
                allowNegative={allowNegative}
                {...(removeDecimalScale ? {} : { decimalScale })}
                isAllowed={(event) => this.validateAllowedValue(event)}
                onKeyDown={onKeyDown}
                onFocus={this.highlightAmount}
            />
        );
    }
}

InputNumber.propTypes = {
    max: PropTypes.number,
    min: PropTypes.number,
    size: PropTypes.string,
    inputRef: PropTypes.func,
    onChange: PropTypes.func,
    showCurrency: PropTypes.bool,
    allowNegative: PropTypes.bool,
    decimalScale: PropTypes.number,
    thousandSeparator: PropTypes.bool,
    fixedDecimalScale: PropTypes.bool,
    className: PropTypes.string,
    onKeyDown: PropTypes.func,
    removeDecimalScale: PropTypes.bool,
    alignRight: PropTypes.bool,
    suffix: PropTypes.string,
};

InputNumber.defaultProps = {
    min: null,
    max: null,
    size: '',
    decimalScale: 2,
    inputRef: () => {},
    onChange: () => {},
    showCurrency: false,
    allowNegative: false,
    thousandSeparator: false,
    fixedDecimalScale: false,
    className: '',
    onKeyDown: () => null,
    removeDecimalScale: false,
    alignRight: false,
    suffix: '',
};

export default InputNumber;
