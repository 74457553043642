import React, { useEffect } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import InfiniteScroll from 'components/widgets/InfiniteScroll';
import If from 'components/widgets/conditional/If';
import DialogActionMessage from 'components/widgets/DialogActionMessage';
import useTechnicianJobs from 'components/modules/service/serviceInvoice/hooks/useTechnicianJobs';
import TechnicianJobForm from 'components/modules/service/serviceInvoice/create/TechnicianJobForm';
import InputSearch from 'components/widgets/InputSearch';

const ownStyle = makeStyles((theme) => (
    {
        root: {
            overflow: 'auto',
            width: '100%',
            height: 'calc(100vh - 110px)',
            backgroundColor: `rgba(${theme.palette.rgb.black}, 0.02)`,
        },
        search: {
            width: '100%',
            borderRadius: '10px',
            justifyContent: 'flex-end',
            marginTop: '3px',
            marginBottom: '3px',
            '& > div': {
                width: '230px',
                flex: 'initial',
                marginRight: '25px',
            },
            '& .form-control': {
                height: 'calc(1.5em + 0.5rem + 2px)',
                padding: '0.25rem 0.5rem',
                fontSize: '0.875rem',
                lineHeight: '1.5',
                borderRadius: '0.2rem',
            },
        },
    }
));

const TechnicianJobList = () => {
    const classes = ownStyle();
    const {
        state,
        loading,
        loadMore,
        loadData,
        onSearch,
    } = useTechnicianJobs();
    const { table, params: { search } } = state;
    const { records, totalCount } = table;
    const saving = false;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => loadData(), []);

    return (
        <>
            <InputSearch
                customClasses={classes.search}
                initialSearch={search || ''}
                onSearch={onSearch}
            />
            <InfiniteScroll
                className={classes.root}
                lengthRecord={records.length}
                totalRecord={totalCount}
                loadMore={loadMore}
                load={loading}
            >
                <Grid container>
                    {
                        records.map((item) => (
                            <Grid key={`technician-item-${item.serviceJobId}`} item md={12} lg={6}>
                                <TechnicianJobForm item={item} />
                            </Grid>
                        ))
                    }
                </Grid>
            </InfiniteScroll>
            <If condition={saving}>
                <DialogActionMessage message="saving..." />
            </If>
        </>
    );
};

export default TechnicianJobList;
