import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import {
    Checkbox,
    FormLabel,
    Grid,
    makeStyles,
} from '@material-ui/core';
import InventoryMap from 'services/mapData/InventoryMap';
import InputNumber from 'components/widgets/InputNumber';
import DisplayInput from 'components/modules/inventory/create/panels/DisplayInputNumber';
import { EmployeeType } from 'utils/enum/UserEnum';

const useStyles = makeStyles({
    root: {
        padding: '5px 10px',
        '& > div': {
            alignItems: 'center',
            marginBottom: 5,
            paddingRight: 5,
            '& > div:first-child': {
                alignItems: 'center',
                paddingRight: 5,
            },
        },
    },
    slashedPrice: {
        display: 'flex',
        alignItems: 'center',
    },
});

const PricingEditPanel = ({
    data,
    costPermission,
    onChange,
    employeeType,
    writePermission,
    defaultReconCost,
}) => {
    const classes = useStyles();

    if (!writePermission) {
        return null;
    }

    const { forUI } = InventoryMap.getTotalCost(data);
    const invalidPurchasedPrice = data.purchasedPrice <= 0;
    const employeeTypePermission = costPermission && employeeType.toUpperCase() !== EmployeeType.SALESMAN.toUpperCase();

    return (
        <Grid
            container
            orientation="column"
            className={classes.root}
        >
            <DisplayInput
                label="Purchased Price"
                value={data.purchasedPrice}
                className={clsx({ 'invalid-field': invalidPurchasedPrice })}
                onChange={(value) => onChange('purchasedPrice', value)}
                display={costPermission}
            />
            <Grid container item xs={12} sm={6}>
                <Grid
                    container
                    item
                    wrap="nowrap"
                    xs={12}
                    sm={5}
                >
                    <FormLabel>
                        Slashed Price:
                    </FormLabel>
                </Grid>
                <Grid item orientation="row" xs={12} sm={7}>
                    <div className={classes.slashedPrice}>
                        <InputNumber
                            value={data.slashedPrice}
                            onChange={(newValue) => onChange('slashedPrice', newValue)}
                            placeholder="0.00"
                            showCurrency
                            thousandSeparator
                            size="sm"
                        />
                        <Checkbox
                            checked={data.showSlashed}
                            color="primary"
                            size="small"
                            onChange={(_, value) => onChange('showSlashed', value)}
                        />
                    </div>
                </Grid>
            </Grid>
            <DisplayInput
                label="Max Recon Cost"
                value={data.maximumReconCost || defaultReconCost}
                onChange={(value) => onChange('maximumReconCost', value)}
                display={costPermission}
                isReconCost
            />
            <DisplayInput
                label="Sticker Price"
                value={data.stickerPrice}
                onChange={(value) => onChange('stickerPrice', value)}
            />
            <DisplayInput
                label={(
                    <>
                        <FormLabel>Pack:</FormLabel>
                        <Checkbox
                            checked={data.packOverride}
                            color="primary"
                            size="small"
                            onChange={(_, value) => onChange('packOverride', value)}
                        />
                    </>
                )}
                value={data.pack}
                onChange={(value) => onChange('pack', value)}
                disabled={!data.packOverride}
                display={costPermission}
            />
            <DisplayInput
                label="Internet Price"
                value={data.internetPrice}
                onChange={(value) => onChange('internetPrice', value)}
            />
            <DisplayInput
                label="Transport"
                value={data.transport}
                disabled
                display={costPermission}
            />
            <DisplayInput
                label="Minimum Price"
                value={data.minimumPrice}
                onChange={(value) => onChange('minimumPrice', value)}
                display={costPermission}
            />
            <DisplayInput
                label="Repairs"
                value={(data.repairs || 0) - (data.transport || 0)}
                disabled
                display={costPermission}
            />
            <DisplayInput
                label="Wholesale Price"
                value={data.wholeSalePrice}
                onChange={(value) => onChange('wholeSalePrice', value)}
            />
            <DisplayInput
                label="Adjustment"
                value={data.adjustment}
                disabled
                display={costPermission}
            />
            <DisplayInput
                label="Est Floor Cost"
                value={data.floorplanCost}
                disabled
                display={costPermission}
            />
            {employeeTypePermission && (
                <Grid container item xs={12} sm={6}>
                    <Grid
                        container
                        item
                        wrap="nowrap"
                        xs={12}
                        sm={5}
                    >
                        <FormLabel>
                            Markup:
                        </FormLabel>
                    </Grid>
                    <Grid item orientation="row" xs={12} sm={7}>
                        <InputNumber
                            disabled
                            allowNegative
                            value={(data.internetPrice || 0) - forUI}
                            placeholder="0.00"
                            showCurrency
                            thousandSeparator
                            size="sm"
                        />
                    </Grid>
                </Grid>
            )}
            <DisplayInput
                label="Total Cost"
                value={forUI}
                disabled
                display={employeeTypePermission}
            />
        </Grid>
    );
};

PricingEditPanel.propTypes = {
    data: PropTypes.object,
    onChange: PropTypes.func,
    costPermission: PropTypes.bool,
    employeeType: PropTypes.string,
    writePermission: PropTypes.bool,
    defaultReconCost: PropTypes.number,
};

PricingEditPanel.defaultProps = {
    data: {},
    costPermission: false,
    employeeType: '',
    writePermission: false,
    onChange: () => {},
    defaultReconCost: 0,
};

export default PricingEditPanel;
