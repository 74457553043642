/* eslint-disable import/prefer-default-export */
import * as yup from 'yup';

const WeOweRowSchema = yup.object().shape({
    nameOfItem: yup.string().required().trim().min(1),
});

const WeOweSchema = yup.array().of(
    WeOweRowSchema,
);

export {
    WeOweRowSchema,
    WeOweSchema,
};
