import React from 'react';
import PropTypes from 'prop-types';

const LinkifyText = ({ text }) => {
    const linkify = (inputText) => {
        const urlRegex = /(\bhttps?:\/\/[^\s]+|\bwww\.[^\s]+)/gi;
        const parts = inputText.split(urlRegex);

        return parts.map((part, index) => {
            if (urlRegex.test(part)) {
                const url = part.startsWith('www') ? `https://${part}` : part;
                return (
                    <a key={index} href={url} target="_blank" rel="noopener noreferrer">
                        {part}
                    </a>
                );
            }
            return part;
        });
    };

    return <div>{linkify(text)}</div>;
};

LinkifyText.propTypes = {
    text: PropTypes.string.isRequired,
};

export default LinkifyText;
