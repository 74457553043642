import React, { Component } from 'react';

// Components and others
import clsx from 'clsx';
import Split from 'react-split';
import PropTypes from 'prop-types';
import DealRecap from 'components/modules/deals/read/dealTab/DealRecap';
import DealBackend from 'components/modules/deals/read/dealTab/DealBackend';
import DealStructure from 'components/modules/deals/read/dealTab/DealStructure';
import DealTabContainer from 'components/containers/deals/read/dealTab/DealTabContainer';
import Permission from 'utils/enum/Permissions';
import KeyStore from 'utils/KeyStore';
import DealNote from 'components/modules/deals/read/dealTab/DealNote';

// Material UI
import { Grid, Paper } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { DealSection, DealStatus } from 'utils/enum/DealEnum';

const styles = (theme) => ({
    root: {
        flexGrow: 1,
        background: theme.palette.background.default,
        '& .gridItem': {
            display: 'flex',
            height: '100%',
            overflow: 'hidden',
            paddingRight: theme.spacing(1),
        },
        '& .gridItem:last-child': {
            padding: 0,
        },
    },
    rootPaper: {
        padding: theme.spacing(2),
        overflow: 'auto',
        width: '100%',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
    },
    split: {
        flexDirection: 'column',
        height: '100%',
    },
});

class DealTab extends Component {
    constructor(props) {
        super(props);

        const keyStore = new KeyStore();
        this.SALES_DEAL_PRODUCTS_READ = keyStore.hasPermission(Permission.SALES_DEAL_PRODUCTS_READ);
    }

    componentDidMount() {
        const {
            props: {
                getServicesData,
                subscribeDealChanged,
            },
        } = this;
        getServicesData();
        subscribeDealChanged();
    }

    componentWillUnmount() {
        const { props: { unsubscribeDealChanged } } = this;
        unsubscribeDealChanged();
    }

    render() {
        const {
            props: {
                classes, dealStructure, onCancelProduct, dealRecap, loading,
                getServicesData, toggleModalDealStructure, openDialogDealStructure, leadSourceList,
                showTypeModal, setDeferredDownPayment, deferredPayments, updateFees, lotData, onApplyTitleRegistration,
                onChangeSalesTax, onCancelSalesTax, onSaveSalesTax, onChangeStructureValue, accountNumber,
                isReadOnlyDealStructure, onEditDealStructure, onCancelEditDealStructure, onUpdateDealStructure,
                editingMode, onChangeEditingMode, onApplyAmountFinanced, vendorList, isCalculatingDeal, onChangeDayFirstPaymentDue,
                onChangeDateFirstPaymentDue, onChangeSoldDate, isSavingDeal, callDealPreview, clientId, resetDealInformation,
                editingStatus, calculateDeal,
            },
        } = this;
        const { status, postedDate } = dealStructure;
        const allowEditDeal = postedDate === null && (status === DealStatus.QUOTE || status === DealStatus.FNI || status === DealStatus.DELETED);

        return (
            <Grid container spacing={0} className={classes.root}>
                <Grid item xs={4} className="gridItem">
                    <DealStructure
                        loading={loading}
                        lotData={lotData}
                        clientId={clientId}
                        updateFees={updateFees}
                        vendorList={vendorList}
                        editingMode={editingMode}
                        isSavingDeal={isSavingDeal}
                        editingStatus={editingStatus}
                        calculateDeal={calculateDeal}
                        accountNumber={accountNumber}
                        dealStructure={dealStructure}
                        showTypeModal={showTypeModal}
                        onSaveSalesTax={onSaveSalesTax}
                        leadSourceList={leadSourceList}
                        getServicesData={getServicesData}
                        onChangeSalesTax={onChangeSalesTax}
                        onCancelSalesTax={onCancelSalesTax}
                        deferredPayments={deferredPayments}
                        onChangeSoldDate={onChangeSoldDate}
                        isCalculatingDeal={isCalculatingDeal}
                        onEditDealStructure={onEditDealStructure}
                        resetDealInformation={resetDealInformation}
                        onApplyAmountFinanced={onApplyAmountFinanced}
                        onUpdateDealStructure={onUpdateDealStructure}
                        onChangeStructureValue={onChangeStructureValue}
                        setDeferredDownPayment={setDeferredDownPayment}
                        isReadOnlyDealStructure={isReadOnlyDealStructure}
                        openDialogDealStructure={openDialogDealStructure}
                        onApplyTitleRegistration={onApplyTitleRegistration}
                        toggleModalDealStructure={toggleModalDealStructure}
                        onCancelEditDealStructure={onCancelEditDealStructure}
                        onChangeDayFirstPaymentDue={onChangeDayFirstPaymentDue}
                        onChangeDateFirstPaymentDue={onChangeDateFirstPaymentDue}
                    />
                </Grid>
                <Grid item xs={4} className="gridItem overflow-hidden">
                    <Paper square className={clsx(classes.rootPaper, classes.container)}>
                        <Split
                            sizes={[50, 50]}
                            className={classes.split}
                            direction="vertical"
                            minSize={0}
                        >
                            {this.SALES_DEAL_PRODUCTS_READ && (
                                <DealBackend
                                    clientId={clientId}
                                    dealId={accountNumber}
                                    editingMode={editingMode}
                                    editingStatus={editingStatus}
                                    allowEditDeal={allowEditDeal}
                                    callDealPreview={callDealPreview}
                                    onCancelProduct={onCancelProduct}
                                    postedDate={dealStructure.postedDate}
                                    onChangeEditingMode={onChangeEditingMode}
                                />
                            )}
                            {editingMode?.sectionName !== DealSection.BACK_END && (
                                <DealNote
                                    dealId={accountNumber}
                                />
                            )}
                        </Split>
                    </Paper>
                </Grid>
                <Grid item xs={4} className="gridItem">
                    <DealRecap
                        clientId={clientId}
                        editingMode={editingMode}
                        previousDealRecap={dealRecap}
                        editingStatus={editingStatus}
                        accountNumber={accountNumber}
                        allowEditDeal={allowEditDeal}
                        postedDate={dealStructure.postedDate}
                        onChangeEditingMode={onChangeEditingMode}
                    />
                </Grid>
            </Grid>
        );
    }
}

DealTab.propTypes = {
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
    dealStructure: PropTypes.shape({
        reserveOverride: PropTypes.bool,
        registerOutState: PropTypes.bool,
        discountOverride: PropTypes.bool,
        term: PropTypes.number,
        buyRate: PropTypes.number,
        discount: PropTypes.number,
        salesTax: PropTypes.number,
        totalFees: PropTypes.number,
        balanceDue: PropTypes.number,
        salesPrice: PropTypes.number,
        totalTradeAllowance: PropTypes.number,
        totalTradePayOff: PropTypes.number,
        interestRate: PropTypes.number,
        financeAmount: PropTypes.number,
        paymentAmount: PropTypes.number,
        reserveAmount: PropTypes.number,
        totalProductsPrice: PropTypes.number,
        taxableAmount: PropTypes.number,
        cashDownPayment: PropTypes.number,
        firstPaymentDueDays: PropTypes.number,
        totalDeferredDownPayment: PropTypes.number,
        soldDate: PropTypes.object,
        dealType: PropTypes.string,
        tagPlate: PropTypes.string,
        assigned: PropTypes.string,
        tagPlateType: PropTypes.string,
        firstPaymentDue: PropTypes.object,
        paymentFrequency: PropTypes.string,
        postedDate: PropTypes.string,
        netDue: PropTypes.number,
        status: PropTypes.string,
    }).isRequired,
    getServicesData: PropTypes.func.isRequired,
    accountNumber: PropTypes.number.isRequired,
    // DealBackend
    onCancelProduct: PropTypes.func.isRequired,
    onSaveSalesTax: PropTypes.func.isRequired,
    onCancelSalesTax: PropTypes.func.isRequired,
    onChangeSalesTax: PropTypes.func.isRequired,
    onChangeStructureValue: PropTypes.func.isRequired,
    // Deal Structure
    lotData: PropTypes.object.isRequired,
    updateFees: PropTypes.func.isRequired,
    showTypeModal: PropTypes.string.isRequired,
    leadSourceList: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
    })).isRequired,
    onEditDealStructure: PropTypes.func.isRequired,
    onUpdateDealStructure: PropTypes.func.isRequired,
    setDeferredDownPayment: PropTypes.func.isRequired,
    isReadOnlyDealStructure: PropTypes.bool.isRequired,
    openDialogDealStructure: PropTypes.bool.isRequired,
    onApplyTitleRegistration: PropTypes.func.isRequired,
    toggleModalDealStructure: PropTypes.func.isRequired,
    onApplyAmountFinanced: PropTypes.func.isRequired,
    onCancelEditDealStructure: PropTypes.func.isRequired,
    resetDealInformation: PropTypes.func.isRequired,
    deferredPayments: PropTypes.arrayOf(PropTypes.object).isRequired,
    // Deal Recap
    onChangeDayFirstPaymentDue: PropTypes.func.isRequired,
    onChangeDateFirstPaymentDue: PropTypes.func.isRequired,
    onChangeSoldDate: PropTypes.func.isRequired,
    // General
    editingMode: PropTypes.shape({
        isEditing: PropTypes.bool,
        sectionName: PropTypes.string,
    }).isRequired,
    onChangeEditingMode: PropTypes.func.isRequired,
    callDealPreview: PropTypes.func.isRequired,
    vendorList: PropTypes.arrayOf(PropTypes.shape({
        vendorId: PropTypes.number,
        vendorName: PropTypes.string,
    })).isRequired,
    subscribeDealChanged: PropTypes.func.isRequired,
    unsubscribeDealChanged: PropTypes.func.isRequired,
    isCalculatingDeal: PropTypes.bool.isRequired,
    isSavingDeal: PropTypes.bool.isRequired,
    dealRecap: PropTypes.object.isRequired,
    loading: PropTypes.bool,
    clientId: PropTypes.string.isRequired,
    editingStatus: PropTypes.shape({
        isEditing: PropTypes.bool,
        editingById: PropTypes.number,
        editingBy: PropTypes.string,
    }).isRequired,
    calculateDeal: PropTypes.func.isRequired,
};

DealTab.defaultProps = {
    loading: false,
};

export default withRouter(withStyles(styles)(DealTabContainer(DealTab)));
