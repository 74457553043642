import { gql } from '@apollo/client';

export default class OpportunityQuery {
    static GET_CRM_LIST = gql`
        query getCRMList($offset: Int!, $limit: Int!, $filters: CRMFilterInput, $sort: DataSort, $searchColumns: [String!]) {
            getCRMList(offset: $offset, limit: $limit, filters: $filters, sort: $sort, searchColumns: $searchColumns) {
                totalCount
                data {
                    crmId
                    leadCode
                    leadSource
                    leadSourceId
                    leadType
                    stockNumber
                    vehicleYear
                    vehicleMake
                    vehicleModel
                    vehicleTrim
                    closedDate
                    lastAction
                    notes
                    prospect {
                        customerCode
                        firstName
                        lastName
                        cellPhone
                        email
                    },
                    coProspect {
                        customerCode
                        firstName
                        lastName
                    }
                    vehicle {
                        customStockNumber
                        style
                        thumbnail
                    }
                    processStage
                    lastResults
                    noDeal
                    lotName
                    recordManager
                    appointmentSalesman
                    recordManagerId
                    salespersonId
                    modifiedOn
                    createdOn
                    status
                    tagsData
                    automatedResponseType
                    sold
                    showed
                    showedDate
                    prospectLeftDate
                    demo
                    writeUp
                    finance
                }
                floorLogSummary {
                    totalCustomers
                    showed
                    demo
                    writeUp
                    finance
                    sold
                }
            }
        }
    `;

    static GET_OPPORTUNITY_BY_CRM_ID = gql`
        query getOpportunityByCRMId($crmId: ID!){
            getOpportunityByCRMId(crmId: $crmId) {
                leadSource
                leadType
                leadCode
                processStage
                dealType
                lotId
                lotName
                prospect {
                    customerId
                    customerCode
                    email
                    cellPhone
                    firstName
                    lastName
                    language
                    allowTextCellPhone
                }
                vehicle {
                    stockNumber
                    customStockNumber
                    vin
                    year
                    make
                    model
                    trim
                    extColor
                    thumbnail
                    miles
                    stickerPrice
                    internetPrice
                }
                availableCash
                vehicleYear
                vehicleMake
                vehicleModel
                vehicleTrim
                vehiclePrice
                tradeVin
                tradeYear
                tradeMake
                tradeModel
                tradeTrim
                tradeMiles
                tradeACV
                tradePayoff
                tradePayoffDate
                tradeLeinHolder
                recordManager
                appointmentDate
                appointmentSalesman
                noDeal
                createdOn
                modifiedOn
                status
                crmId
                recordManagerId
                salespersonId
                reason
                leadSourceId
                obstacleToCloseId
                chosenVehiclePrice
                tags {
                    name
                    tagId
                    color
                }
                sold
                showed
                showedDate
                prospectLeftDate
                demo
                writeUp
                finance
                lastResults
            }
        }
    `;

    static GET_OPPORTUNITIES_BY_CRM_ID = gql`
        query getOpportunitiesByCRMId($crmId: ID!){
            getOpportunitiesByCRMId(crmId: $crmId) {
                crmId
                leadCode
                processStage
                availableCash
                vehicle {
                    stockNumber
                    customStockNumber
                    year
                    make
                    model
                    trim
                    extColor
                    thumbnail
                }
                vehicleYear
                vehicleMake
                vehicleModel
                vehicleTrim
                noDeal
                sold
                showed
                demo
                writeUp
                finance
                modifiedOn
                status
            }
        }
    `;

    static GET_LOST_REASONS = gql`
        query getLostReasons {
            getLostReasons {
                description
                lostReasonId
            }
        }
    `;

    static GET_PROSPECT_BY_CMR_ID = gql`
        query getProspectByCMRId($crmId: ID!) {
            getProspectByCMRId(crmId: $crmId) {
                customerCode
                customerId
                firstName
                middleName
                lastName
                cellPhone
                workPhone
                homePhone
                email
                dob
                ssn
                dln
                isBusiness
                allowCallCellPhone
                allowTextCellPhone
                allowCallHomePhone
                allowCallWorkPhone
                isTaxable
                language
                currentAddress {
                    customerAddressId
                    customerId
                    parentId
                    address1
                    address2
                    housingStatus
                    mortgageOrRent
                    start
                    end
                    totalMonths
                    phone
                    city
                    state
                    county
                    zipCode
                    country
                }
                currentEmployment {
                    customerId
                    customerEmploymentId
                    status
                    employer
                    title
                    monthlyIncome
                    workPhone
                    otherIncomeMonthly
                    otherIncomeSource
                    address1
                    address2
                    city
                    state
                    county
                    zipCode
                    country
                    start
                    totalMonths
                }
            }
        }
    `;

    static GET_CO_PROSPECT_BY_CMR_ID = gql`
        query getCoProspectByCMRId($crmId: ID!) {
            getCoProspectByCMRId(crmId: $crmId) {
                customerCode
                customerId
                firstName
                middleName
                lastName
                email
                cellPhone
                homePhone
                workPhone
                dob
                ssn
                dln
                isBusiness
                allowCallCellPhone
                allowTextCellPhone
                allowCallHomePhone
                allowCallWorkPhone
                currentAddress {
                    customerAddressId
                    address1
                    address2
                    state
                    city
                    county
                    country
                    zipCode
                    phone
                    housingStatus
                    mortgageOrRent
                    totalMonths
                    start
                    end
                }
                currentEmployment {
                    customerEmploymentId
                    employer
                    title
                    totalMonths
                    monthlyIncome
                    workPhone
                    otherIncomeMonthly
                    otherIncomeSource
                    address1
                    address2
                    city
                    state
                    zipCode
                    county
                    country
                    status
                }
            }
        }
    `;

    static GET_OPPORTUNITIES_BY_CUSTOMER_ID = gql`
        query getOpportunitiesByCustomerId($customerId: ID!){
            getOpportunitiesByCustomerId(customerId: $customerId) {
                crmId
                leadCode
                processStage
                availableCash
                vehicle {
                    stockNumber
                    customStockNumber
                    year
                    make
                    model
                    trim
                    extColor
                    thumbnail
                }
                vehicleYear
                vehicleMake
                vehicleModel
                vehicleTrim
                noDeal
                sold
                showed
                demo
                writeUp
                finance
                modifiedOn
                status
            }
        }
    `;

    static GET_CUSTOM_FIELD_PROPOSAL = gql`
        query getCustomFieldProposal($opportunityId: ID!, $lotId: Int!) {
            getCustomFieldProposal(opportunityId: $opportunityId, lotId: $lotId) {
                id
                name
                originalName
                commonName
                customField {
                    customFieldId
                    data
                }
            }
        }
    `;

    static GET_LEAD_SOURCES_FROM_CRM = gql`
        query getLeadSourcesFromCRM {
            getLeadSourcesFromCRM {
                leadSourceId
                leadSource
            }
        }
    `;

    static GET_CUSTOM_FIELD_PURCHASE_WORKSHEET = gql`
        query getCustomFieldPurchaseWorksheet($opportunityId: ID!, $lotId: Int!) {
            getCustomFieldPurchaseWorksheet(opportunityId: $opportunityId, lotId: $lotId) {
                id
                name
                originalName
                commonName
                customField {
                    customFieldId
                    data
                }
            }
        }
    `;

    static GET_ASSIGNEE_BY_OPPORTUNITY_ID = gql`
        query getAssigneeByOpportunityId($opportunityId: ID!) {
            getAssigneeByOpportunityId(opportunityId: $opportunityId) {
                lots
                userId
                firstName
                lastName
            }
        }
    `;

    static GET_CRM_STAGE_LIST = gql`
        query getCRMStageList{
            getCRMStageList{
                name
            }
        }
    `;

    static GET_OPPORTUNITY_BY_OPTION = gql`
        query getOpportunityByOption($recordManagerId: Int, $salespersonId: Int, $status: String) {
            getOpportunityByOption(recordManagerId: $recordManagerId, salespersonId: $salespersonId, status: $status) {
                data {
                    lotId
                    leadCode
                    lotName
                    processStage
                    opportunityId: crmId
                    recordManager
                    appointmentSalesman
                }
                totalCount
            }
        }
    `;

    static GET_AUTOMATED_RESPONSE_LIST = gql`
        query getAutomatedResponseList($target: String!) {
            getAutomatedResponseList(target: $target) {
                automatedResponseId
                name
                leadType
                trigger
                keywords
                keywordsJoint
                createdOn
                aiEnabled
                aiAssistant
                active
                steps {
                    automatedResponseStepId
                    order
                    delayAmount
                    delayMeasure
                    englishSMSTemplateId
                    englishEmailTemplateId
                    spanishSMSTemplateId
                    spanishEmailTemplateId
                    createdOn
                }
            }
        }
    `;

    static GET_OPP_AUTOMATED_RESPONSE_STATUS = gql`
        query getOpportunityAutomatedResponseStatus($crmId: ID!) {
            getOpportunityAutomatedResponseStatus(crmId: $crmId) {
                status
                automatedResponseId
                isAIEnabled
            }
        }
    `;

    static GET_AUTOMATED_RESPONSE_NOTE_EXCLUSIONS = gql`
        query getAutomatedResponseNoteExclusions {
            getAutomatedResponseNoteExclusions {
                automatedResponseNoteExclusionId
                content
                createdOn
            }
        }
    `;

    static GET_OBSTACLE_TO_CLOSE = gql`
        query getObstaclesToClose {
            getObstaclesToClose {
                obstacleToCloseId
                description
                createdOn
                modifiedOn
                active
            }
        }
    `;

    static GET_AI_FOLLOW_UP_MESSAGES = gql`
        query getAIFollowUpMessages {
            getAIFollowUpMessages {
                followUpMessageId
                message
                delayAmount
                delayMeasure
                createdOn
            }
        }
    `;

    static PULL_AVAILABLE_CRM_DOCUMENTS = gql`
        query pullAvailableCRMDocuments($leadCode: Int!) {
            pullAvailableCRMDocuments(leadCode: $leadCode) {
                documentId
                documentUrl
                documentSize
                referenceType
                createdOn
            }
        }
    `;

    static GET_CRM_DOCUMENT_UPLOAD_SIGNED_URL = gql`
        query getCRMDocumentUploadSignedURL($leadCode: Int!, $fileName: String!) {
            getCRMDocumentUploadSignedURL(leadCode: $leadCode, fileName: $fileName) {
                url
                path
            }
        }
    `;

    static GET_CRM_DOCUMENT_SECURE_URL = gql`
        query getCRMDocumentSecureURL($path: String!) {
            getCRMDocumentSecureURL(path: $path)
        }
    `;
}
