import React, { PureComponent } from 'react';

// Components and Others
import clsx from 'clsx';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import Loading from 'components/widgets/Loading';
import InfiniteScrollEnum from 'utils/enum/InfiniteScrollEnum';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import StringUtils from 'lib/StringUtils';

const styles = () => ({
    root: {
        width: '100%',
        height: '100%',
        overflow: 'auto',
    },
    emptyRecordMessage: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
});

class InfiniteScroll extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            countPage: 0,
            height: 0,
        };

        this.myRef = React.createRef();
        this.lastElement = React.createRef();
        this.handleScroll = this.handleScroll.bind(this);
        this.autoScrollBottom = debounce(this.autoScrollBottom.bind(this), 10);
    }

    UNSAFE_componentWillReceiveProps() {
        const {
            state: { countPage, height }, props: {
                scroll, totalRecord, load, autoScrollBottom = false,
            },
        } = this;
        const currentScroll = StringUtils.toUpperCase(scroll);

        if (currentScroll === InfiniteScrollEnum.TOP) {
            if (countPage === 0 && totalRecord === 0) {
                const scrollPosition = this.myRef.current.scrollHeight - this.myRef.current.clientHeight;

                this.myRef.current.scrollTo(0, scrollPosition);
            } else if (load) {
                /* TODO: The value 64 is the height of the loading, look for an option of how
                to pass this dynamic data (in case of altering the size of the loading component)
                */
                this.myRef.current.scrollTo(0, this.myRef.current.scrollHeight - (height + 64));
            }

            if (totalRecord === 0) {
                this.setState({
                    countPage: 0,
                });
            }

            if (autoScrollBottom) {
                this.autoScrollBottom();
            }
        }
    }

    handleScroll(e) {
        const {
            props: {
                totalRecord = 0, lengthRecord = 0, loadMore, scroll, loadAtScrollPercent,
            },
        } = this;
        const element = e.target;
        const currentScroll = StringUtils.toUpperCase(scroll);
        let { scrollHeight } = element;
        if (loadAtScrollPercent && typeof loadAtScrollPercent === 'number') scrollHeight = (loadAtScrollPercent * element.scrollHeight) / 100;

        if (currentScroll === InfiniteScrollEnum.BOTTOM) {
            if (Math.ceil(element.offsetHeight + element.scrollTop) >= scrollHeight) {
                if (lengthRecord < totalRecord && typeof loadMore === 'function') {
                    this.setCountPagination();
                }
            }
        } else if (currentScroll === InfiniteScrollEnum.TOP) {
            if (element.scrollTop === 0 && lengthRecord < totalRecord && typeof loadMore === 'function') {
                this.setCountPagination(element.scrollHeight);
            }
        }
    }

    setCountPagination(record) {
        const { state: { countPage }, props: { loadMore, load } } = this;
        const newCount = countPage + 1;

        if (!load) {
            this.setState({
                countPage: newCount,
                height: record,
            }, () => {
                loadMore();
            });
        }
    }

    autoScrollBottom() {
        const { props: { scrollBottonThreshold = 600 } } = this;
        const currentScrollHC = this.myRef.current?.scrollHeight - this.myRef.current?.clientHeight;
        const currentScrollTop = this.myRef.current?.scrollTop;

        if (currentScrollTop >= (currentScrollHC - scrollBottonThreshold)) {
            this.lastElement.current.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
            });
        }
    }

    renderLoadingItem() {
        const { props: { load, totalRecord } } = this;

        if (totalRecord && load) {
            return <Loading minHeight marginTop={3} />;
        }

        return null;
    }

    renderLoading() {
        const { props: { totalRecord, load } } = this;

        if (load && totalRecord === 0) {
            return <Loading />;
        }

        return null;
    }

    render() {
        const {
            props: {
                classes, className, load, emptyRecordMessage,
                scroll = InfiniteScrollEnum.BOTTOM, totalRecord,
            }, props,
        } = this;
        const currentScroll = StringUtils.toUpperCase(scroll);

        return (
            <div
                className={clsx(classes.root, className)}
                onScroll={this.handleScroll}
                ref={this.myRef}
            >
                {this.renderLoading()}
                {currentScroll === InfiniteScrollEnum.TOP && this.renderLoadingItem()}
                {props.children}
                {!load && (props.children?.length === 0 || totalRecord === 0) && <div className={classes.emptyRecordMessage}>{emptyRecordMessage}</div>}
                {currentScroll === InfiniteScrollEnum.BOTTOM && this.renderLoadingItem()}
                <div ref={this.lastElement} />
            </div>
        );
    }
}

InfiniteScroll.propTypes = {
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
    className: PropTypes.string,
    totalRecord: PropTypes.number.isRequired,
    lengthRecord: PropTypes.number.isRequired,
    loadMore: PropTypes.func.isRequired,
    load: PropTypes.bool.isRequired,
    scroll: PropTypes.string,
    autoScrollBottom: PropTypes.bool,
    scrollBottonThreshold: PropTypes.number,
    loadAtScrollPercent: PropTypes.number,
    emptyRecordMessage: PropTypes.string,
};

InfiniteScroll.defaultProps = {
    className: '',
    autoScrollBottom: false,
    scrollBottonThreshold: 600,
    scroll: InfiniteScrollEnum.BOTTOM,
    loadAtScrollPercent: null,
    emptyRecordMessage: 'No records to display',
};

export default withStyles(styles)(InfiniteScroll);
