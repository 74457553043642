import React from 'react';
import PropTypes from 'prop-types';
import { upperCase } from 'lodash';

import {
    Grid,
    makeStyles,
    FormLabel,
    Checkbox,
} from '@material-ui/core';
import DisplayField from 'components/modules/inventory/read/panels/DisplayField';
import InventoryMap from 'services/mapData/InventoryMap';
import InventoryDetailStyle from 'styles/modules/inventory/read/InventoryDetailStyle';
import { EmployeeType } from 'utils/enum/UserEnum';
import NumberUtils from 'lib/NumberUtils';

const useStyles = makeStyles((theme) => InventoryDetailStyle.detailPanels(theme));
const PricingPanel = ({
    data,
    employeeType,
    costPermission,
    defaultReconCost,
}) => {
    const classes = useStyles();

    const { forUI } = InventoryMap.getTotalCost(data);
    const hasPermission = costPermission && upperCase(employeeType) !== upperCase(EmployeeType.SALESMAN);

    return (
        <Grid
            container
            orientation="column"
            className={classes.labelsGrid}
        >
            <DisplayField
                field="Purchased Price"
                value={data.purchasedPrice}
                type="money"
                display={costPermission}
            />
            <Grid item xs={12} sm={6}>
                <FormLabel className={classes.slashedPrice}>
                    <span>Slashed Price: </span>
                    <div>
                        {NumberUtils.applyCurrencyFormat(data.slashedPrice)}
                        <Checkbox
                            checked={data.showSlashed}
                            disabled
                        />
                    </div>
                </FormLabel>
            </Grid>
            <DisplayField
                field="Max Recon Cost"
                value={data.maximumReconCost || defaultReconCost}
                type="money"
                display={costPermission}
            />
            <DisplayField
                field="Sticker Price"
                value={data.stickerPrice}
                type="money"
            />
            <DisplayField
                field="Pack"
                value={data.pack}
                type="money"
                display={costPermission}
            />
            <DisplayField
                field="Internet Price"
                value={data.internetPrice}
                type="money"
            />
            <DisplayField
                field="Transport"
                value={data.transport}
                type="money"
                display={costPermission}
            />
            <DisplayField
                field="Minimum Price"
                value={data.minimumPrice}
                type="money"
                display={costPermission}
            />
            <DisplayField
                field="Repairs"
                value={(data.repairs || 0) - (data.transport || 0)}
                type="money"
                display={costPermission}
            />
            <DisplayField
                field="Wholesale Price"
                value={data.wholeSalePrice}
                type="money"
            />
            <DisplayField
                field="Adjustment"
                value={data.adjustment}
                type="money"
                display={costPermission}
            />
            <DisplayField
                field="Est Floor Cost"
                value={data.floorplanCost}
                type="money"
                display={costPermission}
            />
            <DisplayField
                field="Markup"
                value={(data.internetPrice || 0) - forUI}
                type="money"
                display={hasPermission}
            />
            <DisplayField
                field="Total Cost"
                value={forUI}
                type="money"
                display={hasPermission}
            />
        </Grid>
    );
};

PricingPanel.propTypes = {
    data: PropTypes.object,
    costPermission: PropTypes.bool,
    employeeType: PropTypes.string,
    defaultReconCost: PropTypes.number,
};

PricingPanel.defaultProps = {
    employeeType: '',
    data: {},
    costPermission: false,
    defaultReconCost: 0,
};

export default PricingPanel;
