import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import {
    makeStyles, Grid, useTheme,
    useMediaQuery, Button, Tooltip,
    Typography,
} from '@material-ui/core';
import KeyStore from 'utils/KeyStore';
import Split from 'react-split';
import Permission from 'utils/enum/Permissions';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import { FetchPolicy, DataSort } from 'utils/enum/Core';
import ROLE from 'utils/enum/OpenAIEnum';
import StringUtils from 'lib/StringUtils';
import ModalUtils from 'utils/ModalUtils';
import AIQuery from 'services/graphQL/query/ai/AIQuery';
import AIMutation from 'services/graphQL/mutate/ai/AIMutation';
import ButtonStyles from 'styles/theme/Button';
import AIStyle from 'styles/modules/ai/AIStyle';
import Search from 'components/widgets/Search';
import GlobalFilesForm from 'components/modules/settings/ai/GlobalFilesForm';
import AssistantForm from 'components/modules/settings/ai/AssistantForm';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import MessageAdd from 'components/widgets/sms/MessageAdd';

// icons
import { ReactComponent as AddCircleOutlineIcon } from 'assets/addproduct.svg';
import RefreshOutlinedIcon from '@material-ui/icons/RefreshOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import CommentOutlinedIcon from '@material-ui/icons/CommentOutlined';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const useStyles = makeStyles((theme) => AIStyle.content(theme));

const AssistantsSettings = ({ canWrite }) => {
    const keyStore = new KeyStore();
    const AI_GLOBAL_SETTINGS_READ = keyStore.hasPermission(Permission.AI_GLOBAL_SETTINGS_READ);

    const client = useApolloClient();
    const classes = { ...useStyles(), ...buttonStyles() };
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [state, setState] = useState({
        assistants: [],
        conversations: [],
        selectedAssistant: null,
        search: null,
        isGlobalFilesOpen: false,
        isAssistantFormOpen: false,
        isDeletePromptVisible: false,
        processing: false,
        conversationUpdated: false,
        sendingMessageInConversation: false,
    });

    const {
        assistants,
        conversations,
        selectedAssistant,
        search,
        isGlobalFilesOpen,
        isAssistantFormOpen,
        isDeletePromptVisible,
        processing,
        conversationUpdated,
        sendingMessageInConversation,
    } = state;

    const {
        data: assistantsData,
        loading: loadingAssistants,
        error: errorLoadingAssistants,
        refetch: refetchAssistants,
    } = useQuery(AIQuery.PULL_ACTIVE_ASSISTANTS, {
        fetchPolicy: FetchPolicy.NO_CACHE,
        notifyOnNetworkStatusChange: true,
    });

    const [deleteAssistant, { loading: removingAssistant }] = useMutation(AIMutation.DELETE_ASSISTANT, {
        onCompleted: () => {
            setState((prevState) => ({
                ...prevState,
                selectedAssistant: null,
                assistants: assistants.filter((a) => a.aiAssistantId !== selectedAssistant),
                isDeletePromptVisible: false,
            }));

            ModalUtils.successMessage(null, 'Assistant removed successfully');
        },
        onError: (error) => {
            setState((prevState) => ({
                ...prevState,
                isDeletePromptVisible: false,
            }));

            ModalUtils.errorMessage(null, error);
        },
    });

    useEffect(() => {
        if (errorLoadingAssistants) {
            ModalUtils.errorMessage(errorLoadingAssistants?.graphQLErrors);
            return;
        }

        if (!loadingAssistants) {
            const assist = assistantsData?.pullActiveAssistants;
            if (assist) {
                setState((prevState) => ({
                    ...prevState,
                    assistants: assist,
                }));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingAssistants, errorLoadingAssistants]);

    useEffect(() => {
        const conversationParent = document.getElementById('conversation-parent');
        if (conversationParent) {
            const { children } = conversationParent;
            const total = children.length;

            if (total > 0) {
                children.item(total - 1).scrollIntoView({
                    behavior: 'smooth',
                    block: 'end',
                });
            }

            setState((prevState) => ({
                ...prevState,
                conversationUpdated: false,
            }));
        }
    }, [conversationUpdated]);

    const onChange = (name, value) => {
        setState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const toggleAssistantForm = (removeSelectedAssistant = false) => {
        if (isAssistantFormOpen) refetchAssistants();

        setState((prevState) => ({
            ...prevState,
            ...(removeSelectedAssistant ? {
                selectedAssistant: null,
            } : {}),
            isAssistantFormOpen: !isAssistantFormOpen,
        }));
    };

    const toggleDeletePrompt = () => {
        setState((prevState) => ({
            ...prevState,
            isDeletePromptVisible: !isDeletePromptVisible,
        }));
    };

    const removeAssistant = () => {
        deleteAssistant({
            variables: {
                assistantId: selectedAssistant,
            },
        });
    };

    const onSendRequest = async (
        assistantId,
        companyCode,
        role,
        message,
        threadId,
    ) => {
        let incomingThreadId = threadId;

        try {
            if (!incomingThreadId) {
                // Create new thread
                const { data } = await client.mutate({
                    mutation: AIMutation.CREATE_THREAD,
                    variables: {
                        companyCode,
                    },
                    fetchPolicy: FetchPolicy.NO_CACHE,
                });
                incomingThreadId = data?.createThread;
            }

            const { data: addMessageData } = await client.mutate({
                mutation: AIMutation.ADD_MESSAGE_IN_THREAD,
                variables: {
                    assistantId,
                    threadId: incomingThreadId,
                    role,
                    content: message,
                },
                fetchPolicy: FetchPolicy.NO_CACHE,
            });

            return {
                threadId: incomingThreadId,
                runId: addMessageData?.addMessageInThread,
            };
        } catch (error) {
            return error;
        }
    };

    const openConversation = async (reset = false, assistantId) => {
        setState((prevState) => ({
            ...prevState,
            sendingMessageInConversation: false,
            processing: true,
        }));

        let clone = cloneDeep(conversations);
        // eslint-disable-next-line no-param-reassign
        clone.forEach((c) => { c.active = false; });
        if (reset) clone = clone.filter((c) => c.assistantId !== assistantId);

        const existing = clone.find((c) => c.assistantId === (assistantId ?? selectedAssistant));
        if (!existing) {
            const assistant = assistants.find((a) => a.aiAssistantId === (assistantId ?? selectedAssistant));
            const {
                companyCode,
            } = assistant;

            // Add first message
            const role = ROLE.ASSISTANT;
            const message = 'Welcome, how may I help you?';
            const requestResult = await onSendRequest(assistantId ?? selectedAssistant, companyCode, role, message);
            if (requestResult instanceof Error) {
                setState((prevState) => ({
                    ...prevState,
                    processing: false,
                }));

                ModalUtils.errorMessage(null, 'Error initiating conversation');
                return;
            }

            const { threadId } = requestResult;
            clone.push({
                assistantId: assistantId ?? selectedAssistant,
                companyCode,
                threadId,
                messages: [{
                    role,
                    message,
                }],
                active: true,
            });
        } else {
            existing.active = true;
        }

        setState((prevState) => ({
            ...prevState,
            processing: false,
            conversations: clone,
        }));
    };

    const sendMessage = async (assistantId, companyCode, threadId, text) => {
        const clone = cloneDeep(conversations);
        const current = clone.find((c) => c.assistantId === assistantId);
        if (current) current.messages.push({ role: ROLE.USER, message: text?.trim() });

        setState((prevState) => ({
            ...prevState,
            sendingMessageInConversation: true,
            conversationUpdated: true,
            conversations: clone,
        }));

        const requestResult = await onSendRequest(assistantId, null, ROLE.USER, text?.trim(), threadId);
        if (requestResult instanceof Error) {
            ModalUtils.errorMessage(null, 'Error sending message');
            return;
        }

        const { runId } = requestResult;
        if (!runId) {
            ModalUtils.errorMessage(null, 'Error sending message');
            return;
        }

        try {
            // Pull latest messages
            const { data } = await client.query({
                query: AIQuery.LIST_MESSAGES_IN_THREAD,
                variables: {
                    companyCode,
                    threadId,
                    runId,
                    order: DataSort.ASC,
                },
                fetchPolicy: FetchPolicy.NO_CACHE,
            });

            const { messages } = data?.listMessagesInThread;
            messages.forEach(({ role, content: { value } }) => { current.messages.push({ role, message: value }); });
            setState((prevState) => ({
                ...prevState,
                sendingMessageInConversation: false,
                conversationUpdated: true,
                conversations: clone,
            }));
        } catch (error) {
            setState((prevState) => ({
                ...prevState,
                sendingMessageInConversation: false,
            }));

            ModalUtils.errorMessage(null, 'Error pulling messages');
        }
    };

    const resetConvesation = (assistantId) => {
        if (assistantId) openConversation(true, assistantId);
    };

    const filteredAssistants = !StringUtils.isEmpty(search)
        ? assistants.filter((a) => a.companyName?.toLowerCase().includes(search.toLowerCase())
        || a.name?.toLowerCase().includes(search.toLowerCase())
        || String(a.companyCode).includes(search.toLowerCase()))
        : assistants;
    const renderAssistants = () => (
        <div className={classes.container}>
            <div className={classes.label}>
                Assistants
                {canWrite && (
                    <Button
                        disabled={processing}
                        size="small"
                        startIcon={<AddCircleOutlineIcon />}
                        onClick={() => toggleAssistantForm(true)}
                    />
                )}
            </div>
            <div className={classes.boxSearch}>
                <Search fullWidth onKeyDown={(input) => onChange('search', input)} />
            </div>
            <div className={classes.list}>
                {filteredAssistants.map(({
                    aiAssistantId,
                    companyName,
                    name,
                }, index) => {
                    const isSelected = selectedAssistant === aiAssistantId;

                    return (
                        <div
                            className={isSelected ? classes.listItemSelected : classes.listItem}
                            onClick={() => onChange('selectedAssistant', aiAssistantId)}
                            key={index}
                        >
                            <span>{`${companyName} | ${name}`}</span>
                            {isSelected && canWrite && (
                                <div>
                                    <Tooltip title="Test in Conversation">
                                        <span>
                                            <Button
                                                disabled={processing}
                                                size="small"
                                                startIcon={<CommentOutlinedIcon />}
                                                onClick={() => openConversation()}
                                            />
                                        </span>
                                    </Tooltip>
                                    <Tooltip title="Edit">
                                        <span>
                                            <Button
                                                disabled={processing}
                                                size="small"
                                                startIcon={<EditOutlinedIcon />}
                                                onClick={() => toggleAssistantForm()}
                                            />
                                        </span>
                                    </Tooltip>
                                    <Tooltip title="Delete">
                                        <span>
                                            <Button
                                                disabled={processing}
                                                size="small"
                                                startIcon={<DeleteOutlineOutlinedIcon />}
                                                onClick={toggleDeletePrompt}
                                            />
                                        </span>
                                    </Tooltip>
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );

    const activeConversation = conversations.find((c) => c.active === true);
    const renderConversationSpot = () => (
        <div className={classes.container}>
            <div className={classes.label}>
                Conversation
                <Tooltip title="Reset conversation" placement="top">
                    <Button
                        disabled={processing}
                        className={classes.refreshButton}
                        size="small"
                        startIcon={<RefreshOutlinedIcon />}
                        onClick={() => resetConvesation(activeConversation?.assistantId)}
                    />
                </Tooltip>
            </div>
            <div className={clsx(classes.list, classes.conversation)}>
                {activeConversation && (
                    <>
                        <div id="conversation-parent">
                            {activeConversation.messages.map(({
                                role,
                                message,
                            }, index) => {
                                const isUser = role === ROLE.USER;
                                return (
                                    <div key={index} className={clsx(classes.main, { [classes.assistantMessageBox]: !isUser })}>
                                        <div className={clsx(classes.message, isUser ? classes.userMessage : classes.assistantMessage)}>
                                            <Typography className={classes.text}>
                                                {message}
                                            </Typography>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div>
                            <MessageAdd
                                disabled={sendingMessageInConversation}
                                onSubmit={
                                    ({ text }) => sendMessage(activeConversation.assistantId, activeConversation.companyCode, activeConversation.threadId, text)
                                }
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    );

    return (
        <>
            {AI_GLOBAL_SETTINGS_READ && (
                <div className={classes.globalFiles}>
                    <Button
                        disabled={processing}
                        className={classes.containedSecondaryInfo}
                        size="small"
                        onClick={() => onChange('isGlobalFilesOpen', true)}
                    >
                        Global Files
                    </Button>
                </div>
            )}
            {isMobile && (
                <Grid container className={classes.box}>
                    {renderAssistants()}
                    {renderConversationSpot()}
                </Grid>
            )}
            {!isMobile && (
                <Grid container className={classes.box}>
                    <Split
                        sizes={[60, 40]}
                        minSize={0}
                        gutterSize={10}
                        gutterAlign="center"
                        direction="horizontal"
                        cursor="col-resize"
                        className={classes.splitter}
                    >
                        {renderAssistants()}
                        {renderConversationSpot()}
                    </Split>
                </Grid>
            )}
            {isGlobalFilesOpen && (
                <GlobalFilesForm canWrite={canWrite} toggleDialog={() => onChange('isGlobalFilesOpen', !isGlobalFilesOpen)} />
            )}
            {isAssistantFormOpen && (
                <AssistantForm
                    assistant={selectedAssistant ? assistants.find((a) => a.aiAssistantId === selectedAssistant) : null}
                    toggleDialog={toggleAssistantForm}
                />
            )}
            <ConfirmDialog
                title="Attention!"
                description="Do you want to remove this assistant? All associated files will be removed as well"
                open={isDeletePromptVisible}
                variant="outlined"
                titlePrimary="Yes"
                titleSecondary="Cancel"
                onClose={toggleDeletePrompt}
                onClickSecondary={toggleDeletePrompt}
                onClickPrimary={removeAssistant}
                disablePrimaryButton={removingAssistant}
                disableSecondaryButton={removingAssistant}
            />
        </>
    );
};

AssistantsSettings.propTypes = {
    canWrite: PropTypes.bool.isRequired,
};

export default AssistantsSettings;
