import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    makeStyles, useTheme,
    Button, TextField,
    Tooltip,
} from '@material-ui/core';
import { useApolloClient, gql } from '@apollo/client';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ModalUtils from 'utils/ModalUtils';
import StringUtils from 'lib/StringUtils';
import DateUtils from 'lib/DateUtils';
import PDFUtils from 'utils/PDFUtils';
import GeneralUtils from 'utils/GeneralUtils';
import ImagesUtils from 'utils/ImagesUtils';
import useFilesManagerFunctions from 'components/hook/core/useFilesManagerFunctions';
import { FetchPolicy, DOCUMENT_REFERENCE_TYPE } from 'utils/enum/Core';
import Split from 'react-split';
import Dropzone from 'react-dropzone';
import ButtonStyles from 'styles/theme/Button';
import Loading from 'components/widgets/Loading';
import InputSearch from 'components/widgets/InputSearch';
import VirtualTable from 'components/widgets/VirtualTable';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';

// Icons
import InboxOutlinedIcon from '@material-ui/icons/InboxOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const useStyles = makeStyles((theme) => ({
    container: {
        height: 'calc(100vh - 150px)',
        padding: '10px',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            height: 'initial',
            flexDirection: 'column',
            marginBottom: '20px',
        },
    },
    splitter: {
        display: 'flex',
        overflow: 'hidden',
        height: '100%',
        width: '100%',
        '& > div.gutter': {
            marginLeft: '5px',
            marginRight: '5px',
        },
    },
    associatedSplitter: {
        height: '100%',
        flexDirection: 'column',
        fontSize: 'initial !important',
        flexShrink: 'initial !important',
        marginBottom: 'initial !important',
        '& > div.gutter': {
            marginLeft: 0,
            marginRight: 0,
        },
        '& > div:nth-child(1)': {
            display: 'flex',
            flexDirection: 'column',
            '& > div:nth-child(1)': {
                marginBottom: '2px',
                fontSize: '13px',
                flexShrink: 0,
            },
            '& > div:nth-child(2)': {
                display: 'flex',
                justifyContent: 'flex-start',
                flexShrink: 0,
                '& > div': {
                    width: '250px',
                    '& input': {
                        borderRadius: 'initial',
                    },
                },
            },
            '& > div:nth-child(3)': {
                flexGrow: 1,
                overflowY: 'hidden',
            },
        },
        '& > div:nth-child(3) > div': {
            height: '100%',
            overflowY: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            '& > div:nth-child(1)': {
                display: 'flex',
                flexShrink: 0,
                alignItems: 'center',
                color: theme.palette.text.trout,
                fontSize: '14px',
                padding: '10px',
            },
            '& > div:nth-child(2)': {
                marginBottom: '2px',
                fontSize: '13px',
                flexShrink: 0,
            },
            '& > div:nth-child(3)': {
                flexGrow: 1,
                height: 'initial',
            },
        },
    },
    files: {
        border: `1px solid ${theme.palette.border.ghost}`,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            height: '600px',
        },
        '& > div:nth-child(1)': {
            marginBottom: '2px',
            fontSize: '13px',
            flexShrink: 0,
        },
        '& > div:nth-child(2)': {
            display: 'flex',
            justifyContent: 'flex-start',
            flexShrink: 0,
            '& > div': {
                width: '250px',
                '& input': {
                    borderRadius: 'initial',
                },
            },
        },
        '& > div:nth-child(3)': {
            flexGrow: 1,
            overflowY: 'hidden',
        },
    },
    preview: {
        border: `1px solid ${theme.palette.border.ghost}`,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        [theme.breakpoints.down('sm')]: {
            height: '600px',
            marginTop: '20px',
        },
        '& > div:nth-child(1)': {
            display: 'flex',
            justifyContent: 'flex-start',
            alignContent: 'center',
            padding: '10px',
            borderBottom: `1px solid ${theme.palette.border.ghost}`,
            color: theme.palette.text.trout,
            fontSize: '14px',
            flexShrink: 0,
        },
        '& > div:nth-child(2)': {
            padding: '5px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexGrow: 1,
            overflowY: 'auto',
            position: 'relative',
            '& > div:nth-child(1)': {
                fontSize: '13px',
            },
        },
    },
    dragzone: {
        cursor: 'pointer',
        border: `1px solid ${theme.palette.border.alto}`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '5px 10px',
        transition: 'border-color .3s',
        height: '35px',
        color: theme.palette.text.white,
        backgroundColor: theme.palette.success.main,
        '& > svg': {
            fill: theme.palette.text.white,
            width: '20px',
            height: '20px',
            marginLeft: '5px',
        },
    },
    tableContainer: {
        marginTop: '3px',
        height: '100%',
        '& .ReactVirtualized__Table > .ReactVirtualized__Table__headerRow': {
            backgroundColor: `${theme.palette.background.white} !important`,
            border: `1px solid rgba(${theme.palette.rgb.black}, 0.1)`,
            marginBottom: '2px',
            '& > div': {
                height: '30px',
                borderRight: `1px solid rgba(${theme.palette.rgb.black}, 0.05)`,
                alignItems: 'center',
            },
        },
        '& .ReactVirtualized__Table__rowColumn': {
            justifyContent: 'left',
            padding: '7px 5px',
            fontSize: '12px',
            color: theme.palette.text.outerSpace,
            display: 'flex',
            '& > .MuiTextField-root': {
                width: '90%',
                [theme.breakpoints.down('md')]: {
                    width: '100%',
                },
            },
        },
        '& .DragHandleIcon': {
            color: theme.palette.text.waterloo,
        },
    },
    tableHeader: {
        textAlign: 'left',
        color: theme.palette.text.waterloo,
        borderRight: `1px solid ${theme.palette.border.ghost}`,
        height: '100%',
        alignItems: 'center',
    },
    text: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    deleteButton: {
        minWidth: '32px',
        '& span.MuiButton-startIcon': {
            margin: 0,
        },
    },
    loader: {
        position: 'absolute',
        left: '0',
        top: '0',
        zIndex: '999',
        backgroundColor: theme.palette.background.white,
        width: '100%',
        height: '98%',
        opacity: '0.5',
    },
    download: {
        padding: 0,
        minWidth: 'initial',
        marginRight: '10px',
        '& > span > span': {
            margin: 0,
            '& > svg': {
                fontSize: '25px !important',
            },
        },
    },
    nameWrapper: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        '& > div:nth-child(1)': {
            width: '90%',
            cursor: 'pointer',
            color: theme.palette.secondary.main,
            '& > div.MuiOutlinedInput-root': {
                marginRight: '10px',
                '& > input': {
                    height: '13px !important',
                },
            },
        },
        '& > div:nth-child(2)': {
            width: '10%',
            '& > button': {
                padding: '5px',
                minWidth: 'initial',
                '& > span > span': {
                    margin: 0,
                    '& > svg': {
                        fontSize: '16px !important',
                    },
                },
            },
        },
    },
    textFieldSmall: {
        '& input': {
            padding: 7,
        },
    },
    iframe: {
        height: '100%',
        width: '100%',
    },
    innerImage: {
        width: '100%',
    },
}));

const FilesManager = ({
    referenceId,
    referenceType,
    maxFileSize,
    allowedFiles,
    pullDocumentsQuery,
    getDocumentUploadSignedURL,
    getDocumentSecureURL,
    createDocumentsMutation,
    editDocumentNameMutation,
    deleteMutation,
    associatedReferenceId,
    getAssociatedDocumentUploadSignedURL,
    createAssociatedDocumentsMutation,
    canWrite,
    canDelete,
}) => {
    const classes = { ...useStyles(), ...buttonStyles() };
    const client = useApolloClient();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isDeals = referenceType === DOCUMENT_REFERENCE_TYPE.DEALS;
    const [state, setState] = useState({
        documents: [],
        associatedDocuments: [],
        search: '',
        previewTitle: '',
        previewExt: null,
        previewURL: null,
        modifiedName: null,
        selectedDocumentId: null,
        isUploadingDocuments: false,
        isDeletePromptVisible: false,
        isProcessingFile: false,
    });

    const {
        documentsData,
        documentRename,
        deleteDocument,
        uploadDocuments,
        loadingDocuments,
        errorLoadingDocuments,
        renamingDocument,
        deletingDocument,
    } = useFilesManagerFunctions(state, setState, {
        referenceId,
        referenceType,
        maxFileSize,
        allowedFiles,
        pullDocumentsQuery,
        getDocumentUploadSignedURL,
        createDocumentsMutation,
        editDocumentNameMutation,
        deleteMutation,
        associatedReferenceId,
        getAssociatedDocumentUploadSignedURL,
        createAssociatedDocumentsMutation,
    });

    const sortDocumentsByName = (documents) => documents.map(((doc) => ({
        ...doc,
        documentName: StringUtils.getFileNameFromPath(doc.documentUrl),
    }))).sort((a, b) => a.documentName.localeCompare(b.documentName));

    useEffect(() => {
        if (errorLoadingDocuments) {
            ModalUtils.errorMessage(errorLoadingDocuments?.graphQLErrors);
            return;
        }

        if (!loadingDocuments) {
            const [name] = Object.keys(documentsData || {});
            if (!name) return;

            let documents = null;
            let associatedDocuments = null;

            if (isDeals) {
                const { dealDocuments, inventoryDocuments } = documentsData[name] ?? {};
                documents = dealDocuments ?? [];
                associatedDocuments = inventoryDocuments ?? [];
            } else {
                documents = documentsData[name];
            }

            setState((prevState) => ({
                ...prevState,
                documents: sortDocumentsByName(documents || []),
                associatedDocuments: sortDocumentsByName(associatedDocuments || []),
            }));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingDocuments, errorLoadingDocuments]);

    const onSearch = (text) => {
        setState((prevState) => ({
            ...prevState,
            search: text,
        }));
    };

    const toggleDeletePrompt = (record) => {
        if (!canDelete) return;

        setState((prevState) => ({
            ...prevState,
            selectedDocumentId: record?.documentId,
            isDeletePromptVisible: !state.isDeletePromptVisible,
        }));
    };

    const onDeleteAction = () => {
        if (!canDelete) return;

        deleteDocument({
            variables: {
                documentId: state.selectedDocumentId,
            },
        });
    };

    const onChangeName = (value) => {
        if (['.', '/', '\\'].some((char) => value.includes(char))) return;

        setState((prevState) => ({
            ...prevState,
            modifiedName: value,
        }));
    };

    const editDocumentName = (record, isAssociated) => {
        const doc = (isAssociated ? state.associatedDocuments : state.documents).find((el) => el.documentId === record?.documentId);

        setState((prevState) => ({
            ...prevState,
            selectedDocumentId: record?.documentId,
            modifiedName: doc?.documentName.split('.')[0],
        }));
    };

    const saveDocumentName = (isAssociated) => {
        if (
            (isAssociated ? state.associatedDocuments : state.documents)
                .some((el) => el?.documentName.split('.')[0]?.toLowerCase() === state.modifiedName?.trim().toLowerCase())
        ) {
            setState((prevState) => ({
                ...prevState,
                selectedDocumentId: null,
                modifiedName: null,
            }));

            return;
        }

        documentRename({
            variables: {
                documentId: state.selectedDocumentId,
                name: state.modifiedName,
            },
        });
    };

    const previewDocument = async (record, isDownload = false) => {
        const {
            documentName,
            documentUrl,
        } = record;

        setState((prevState) => ({
            ...prevState,
            isProcessingFile: true,
        }));

        const path = documentUrl;
        const ext = path.substring(path.lastIndexOf('.') + 1)?.toLowerCase();

        let localUrl = null;
        if (record.referenceType === DOCUMENT_REFERENCE_TYPE.DEAL) {
            const isSecured = StringUtils.isSecureURL(documentUrl);
            localUrl = isSecured ? documentUrl : documentUrl.replace('http://', 'https://');
        } else {
            const { data } = await client.query({
                query: getDocumentSecureURL,
                variables: {
                    path,
                },
                fetchPolicy: FetchPolicy.NO_CACHE,
            });

            const [name] = Object.keys(data || {});
            if (!name) return;

            const secureURL = data[name];
            if (!secureURL) {
                setState((prevState) => ({
                    ...prevState,
                    isProcessingFile: false,
                }));

                return;
            }

            if (ext === 'pdf') {
                localUrl = await PDFUtils.convertPDFToBlobURL(secureURL);
            } else {
                localUrl = await ImagesUtils.convertImageToBlobURL(secureURL, 'image/jpeg');
            }
        }

        if (isDownload) {
            GeneralUtils.downloadFile(localUrl, documentName);
            setState((prevState) => ({
                ...prevState,
                isProcessingFile: false,
            }));

            return;
        }

        setState((prevState) => ({
            ...prevState,
            previewTitle: `| ${documentName}`,
            previewExt: ext,
            previewURL: localUrl,
            isProcessingFile: false,
        }));
    };

    const getColumns = (isAssociated = false) => [
        {
            headerClassName: classes.tableHeader,
            label: 'Name',
            dataKey: 'name',
            width: 400,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                const isEditMode = state.selectedDocumentId && !state.isDeletePromptVisible;
                const isSelected = record.documentId === state.selectedDocumentId;
                const name = record.documentName?.split('.')[0];
                return (
                    <div className={classes.nameWrapper}>
                        {(!isEditMode || (isEditMode && !isSelected)) && (
                            <div
                                className={classes.text}
                                onClick={() => previewDocument(record)}
                            >
                                {name}
                            </div>
                        )}
                        {isEditMode && isSelected && (
                            <TextField
                                className={classes.textFieldSmall}
                                defaultValue={state.modifiedName}
                                onChange={({ target: { value } }) => onChangeName(value)}
                                inputProps={{ maxLength: 100 }}
                                variant="outlined"
                                size="small"
                            />
                        )}
                        <Tooltip title="Rename File">
                            <div>
                                <Button
                                    disabled={renamingDocument || state.isProcessingFile}
                                    className={classes.containedInfo}
                                    size="small"
                                    startIcon={isEditMode && isSelected ? <SaveOutlinedIcon /> : <EditOutlinedIcon />}
                                    onClick={() => (isEditMode && isSelected ? saveDocumentName(isAssociated) : editDocumentName(record, isAssociated))}
                                />
                            </div>
                        </Tooltip>
                    </div>
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Size',
            dataKey: 'size',
            width: 120,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <span className={classes.text}>{record.documentSize}</span>
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Type',
            dataKey: 'type',
            width: 120,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                const ext = record.documentName?.split('.')[1];
                return (
                    <span className={classes.text}>{ext?.toUpperCase()}</span>
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Created On',
            dataKey: 'createdOn',
            width: 180,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <span className={classes.text}>{DateUtils.getFormattedDateInUserTimezone(record.createdOn)}</span>
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Actions',
            dataKey: 'actions',
            width: 150,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                if (!canWrite) return null;

                return (
                    <>
                        <Button
                            disabled={state.isProcessingFile}
                            className={classes.download}
                            size="small"
                            startIcon={<CloudDownloadOutlinedIcon />}
                            onClick={() => previewDocument(record, true)}
                        />
                        {canDelete && (
                            <Button
                                disabled={state.isProcessingFile}
                                className={clsx(classes.containedError, classes.deleteButton)}
                                size="small"
                                startIcon={<DeleteOutlineOutlinedIcon />}
                                onClick={() => toggleDeletePrompt(record)}
                            />
                        )}
                    </>
                );
            },
        },
    ];

    const docs = state.documents
        .filter((el) => (
            StringUtils.isEmpty(state.search)
            || (
                !StringUtils.isEmpty(state.search)
                && el.documentName?.toLowerCase().includes(state.search?.toLowerCase())
            )
        ));
    const renderLeftSectionContent = () => (
        <>
            <div>
                <Dropzone
                    disabled={state.isUploadingDocuments || renamingDocument || state.isProcessingFile}
                    multiple
                    accept={allowedFiles}
                    onDrop={(acceptedFiles) => uploadDocuments(acceptedFiles)}
                    className={classes.dragzone}
                >
                    <p className={classes.uploadText}>
                        Click or drag document to this area to upload (PDF and Images)
                    </p>
                    <InboxOutlinedIcon />
                </Dropzone>
            </div>
            <div>
                <InputSearch
                    executeWhenClearButton={() => onSearch('')}
                    onSearch={onSearch}
                />
            </div>
            <div>
                <div className={classes.tableContainer}>
                    <VirtualTable
                        loading={loadingDocuments}
                        rowHeight={45}
                        totalRecords={docs.length}
                        data={docs}
                        columns={getColumns()}
                        width={getColumns().reduce((a, b) => a + b.width, 0)}
                    />
                </div>
            </div>
            {state.isUploadingDocuments && (
                <Loading className={classes.loader} />
            )}
        </>
    );

    const renderAssociatedDocuments = () => (
        <>
            <div>
                <div>
                    Sold Vehicle
                </div>
                <div>
                    <Dropzone
                        disabled={state.isUploadingDocuments || renamingDocument || state.isProcessingFile}
                        multiple
                        accept={allowedFiles}
                        onDrop={(acceptedFiles) => uploadDocuments(acceptedFiles, true)}
                        className={classes.dragzone}
                    >
                        <p className={classes.uploadText}>
                            Click or drag document to this area to upload (PDF and Images)
                        </p>
                        <InboxOutlinedIcon />
                    </Dropzone>
                </div>
                <div className={classes.tableContainer}>
                    <VirtualTable
                        loading={loadingDocuments}
                        rowHeight={45}
                        totalRecords={state.associatedDocuments.length}
                        data={state.associatedDocuments}
                        columns={getColumns(true)}
                        width={getColumns(true).reduce((a, b) => a + b.width, 0)}
                    />
                </div>
            </div>
        </>
    );

    const renderDealsLeftSection = () => (
        <Split
            sizes={[65, 35]}
            minSize={150}
            gutterSize={10}
            gutterAlign="center"
            direction="vertical"
            cursor="row-resize"
            className={classes.associatedSplitter}
        >
            <div>
                {renderLeftSectionContent()}
            </div>
            <div>
                {renderAssociatedDocuments()}
            </div>
        </Split>
    );

    const renderRightSectionContent = () => (
        <>
            <div>
                {`Document Preview ${state.previewTitle}`}
            </div>
            <div>
                {!state.previewURL && !state.isProcessingFile && (
                    <div>
                        Click on a document&apos;s name to preview
                    </div>
                )}
                {state.previewURL && state.previewExt === 'pdf' && (
                    <iframe
                        className={classes.iframe}
                        title="Preview"
                        src={`${process.env.PUBLIC_URL}/pdfjs/web/viewer.html?file=${state.previewURL}`}
                        frameBorder="0"
                        allowFullScreen
                    />
                )}
                {state.previewURL && state.previewExt !== 'pdf' && (
                    <img
                        className={classes.innerImage}
                        alt="Preview"
                        src={state.previewURL}
                    />
                )}
                {state.isProcessingFile && (
                    <Loading className={classes.loader} />
                )}
            </div>
        </>
    );

    return (
        <div className={classes.container}>
            {!isMobile && (
                <Split
                    sizes={[50, 50]}
                    minSize={300}
                    gutterSize={10}
                    gutterAlign="center"
                    direction="horizontal"
                    cursor="col-resize"
                    className={classes.splitter}
                >
                    <div
                        className={classes.files}
                    >
                        {!isDeals && renderLeftSectionContent()}
                        {isDeals && renderDealsLeftSection()}
                    </div>
                    <div
                        className={classes.preview}
                    >
                        {renderRightSectionContent()}
                    </div>
                </Split>
            )}
            {isMobile && (
                <>
                    <div
                        className={classes.files}
                    >
                        {!isDeals && renderLeftSectionContent()}
                        {isDeals && renderDealsLeftSection()}
                    </div>
                    <div
                        className={classes.preview}
                    >
                        {renderRightSectionContent()}
                    </div>
                </>
            )}
            <ConfirmDialog
                title="Attention!"
                description="Do you want to remove this document?"
                open={state.isDeletePromptVisible}
                variant="outlined"
                titlePrimary="Yes"
                titleSecondary="Cancel"
                onClose={() => toggleDeletePrompt()}
                onClickSecondary={() => toggleDeletePrompt()}
                onClickPrimary={onDeleteAction}
                disablePrimaryButton={deletingDocument}
                disableSecondaryButton={deletingDocument}
            />
        </div>
    );
};

FilesManager.propTypes = {
    referenceId: PropTypes.number,
    referenceType: PropTypes.string,
    maxFileSize: PropTypes.number,
    allowedFiles: PropTypes.string,
    pullDocumentsQuery: PropTypes.object.isRequired,
    getDocumentUploadSignedURL: PropTypes.object.isRequired,
    getDocumentSecureURL: PropTypes.object.isRequired,
    createDocumentsMutation: PropTypes.object.isRequired,
    editDocumentNameMutation: PropTypes.object.isRequired,
    deleteMutation: PropTypes.object.isRequired,
    associatedReferenceId: PropTypes.number,
    getAssociatedDocumentUploadSignedURL: PropTypes.object,
    createAssociatedDocumentsMutation: PropTypes.object,
    canWrite: PropTypes.bool,
    canDelete: PropTypes.bool,
};

FilesManager.defaultProps = {
    referenceId: 0,
    referenceType: '',
    maxFileSize: 1048576,
    allowedFiles: 'image/*',
    canWrite: false,
    canDelete: false,
    associatedReferenceId: -1,
    getAssociatedDocumentUploadSignedURL: gql`
        query querySample {
            querySample
        }
    `,
    createAssociatedDocumentsMutation: gql`
        mutation mutationSample {
            mutationSample
        }
    `,
};

export default FilesManager;
