import React, { useEffect, useState } from 'react';
import {
    makeStyles, Grid, Button, CircularProgress,
} from '@material-ui/core';
import update from 'immutability-helper';
import ModalUtils from 'utils/ModalUtils';
import { Col, Form } from 'react-bootstrap';
import GraphQLClient from 'services/apollo/GraphQLClient';
import CompanyQuery from 'services/graphQL/query/setting/CompanyQuery';
import CompanyMutation from 'services/graphQL/mutate/CompanyMutation';
import { SaveOutlinedIcon } from 'components/icons';
import StringUtils from 'lib/StringUtils';

const useStyles = makeStyles((theme) => ({
    box: {
        marginTop: '10px',
        paddingTop: '10px',
        border: `solid 1px ${theme.palette.border.mercury}`,
    },
}));
const graphqlClient = new GraphQLClient();

const SecureCloseSetting = () => {
    const classes = useStyles();

    // Initial state
    const [state, setState] = useState({
        secureCloseEnabled: false,
        secureCloseDmsguid: '',
        updating: false,
    });

    const onChange = (key, value) => {
        setState((prevState) => update(prevState, {
            [key]: { $set: value },
        }));
    };

    const onSave = () => {
        onChange('updating', true);

        graphqlClient
            .mutate(CompanyMutation.UPDATE_SECURE_CLOSE_SETTING, {
                secureCloseEnabled: state.secureCloseEnabled,
                secureCloseDmsguid: state.secureCloseDmsguid,
            })
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data?.updateSecureCloseSetting) {
                    ModalUtils.successMessage(null, 'Secure Close Setting updated successfully');
                }
            })
            .finally(() => {
                onChange('updating', false);
            });
    };

    useEffect(() => {
        graphqlClient
            .query(CompanyQuery.GET_COMPANY)
            .then((response) => {
                const { data, graphQLErrors } = response;
                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                const { getCompany = {} } = data;
                setState((prevState) => update(prevState, {
                    secureCloseEnabled: { $set: getCompany.secureCloseEnabled },
                    secureCloseDmsguid: { $set: getCompany.secureCloseDmsguid },
                }));
            });
    }, []);

    return (
        <Grid container>
            <Grid item md={6} className={classes.box}>
                <Grid container>
                    <Grid item md={6}>
                        <Form.Group as={Col}>
                            <Form.Check
                                onChange={(e) => onChange('secureCloseEnabled', e.target.checked)}
                                checked={state.secureCloseEnabled}
                                name="secureCloseEnabled"
                                className={classes.alignCheckBox}
                                inline
                                label="Use Secure Close ID"
                            />
                            <Form.Control
                                value={state.secureCloseEnabled ? state.secureCloseDmsguid : ''}
                                type="text"
                                disabled={!state.secureCloseEnabled}
                                className={state.secureCloseEnabled && !state.secureCloseDmsguid ? 'invalid-field' : ''}
                                onChange={(e) => onChange('secureCloseDmsguid', e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Button
                                size="small"
                                variant="outlined"
                                onClick={onSave}
                                disabled={state.updating || (state.secureCloseEnabled && StringUtils.isEmpty(state.secureCloseDmsguid))}
                                startIcon={<SaveOutlinedIcon />}
                            >
                                {state.updating ? 'Saving...' : 'Save'}
                                {state.updating && <CircularProgress size={20} />}
                            </Button>
                        </Form.Group>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default SecureCloseSetting;
