/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-globals */
import React, {
    useReducer,
    useEffect,
    useRef,
    useContext,
} from 'react';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { cloneDeep } from 'lodash';
import {
    makeStyles, Button,
    Select,
    ListItemText, MenuItem,
    Checkbox,
} from '@material-ui/core';
import { modules } from 'utils/enum/modules';
import Chart from 'react-apexcharts';
import { useTheme } from '@material-ui/core/styles';
import { Col, Form } from 'react-bootstrap';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import { FetchPolicy, ALL_LOTS, DataSort } from 'utils/enum/Core';
import LotsCategory from 'utils/enum/LotsCategory';
import HttpClient from 'services/api/HttpClient';
import useFilterActions from 'components/modules/inventory/hooks/useFilterActions';
import useSortingActions from 'components/modules/inventory/hooks/useSortingActions';
import { MarketCompsDistanceOptions, InventoryCategory } from 'utils/enum/InventoryEnum';
import LotQuery from 'services/graphQL/query/LotQuery';
import SpreadsheetUtils from 'utils/SpreadsheetUtils';
import ModalUtils from 'utils/ModalUtils';
import StringUtils from 'lib/StringUtils';
import DateUtils, { DateFormat } from 'lib/DateUtils';
import NumberUtils from 'lib/NumberUtils';
import ArrayUtils from 'lib/ArrayUtils';
import InventoryHelper from 'utils/InventoryHelper';
import InventoryQuery from 'services/graphQL/query/InventoryQuery';
import InventoryMutation from 'services/graphQL/mutate/InventoryMutation';
import ButtonStyles from 'styles/theme/Button';
import UserContext from 'components/context/UserContext';
import InputNumber from 'components/widgets/InputNumber';
import CarGurusBox from 'components/modules/inventory/read/CarGurusBox';
import CircularProgress from '@material-ui/core/CircularProgress';
import MarketCompsTabStyle from 'styles/modules/inventory/read/MarketCompsTabStyle';
import VirtualTable from 'components/widgets/VirtualTable';

// icons
import { CarfaxIcon, ExcelIcon } from 'components/icons/index';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';

const INIT_STATE = {
    settings: {
        marketPercentage: 90,
        marketDistance: 70,
    },
    sort: {
        dir: DataSort.DESC,
        field: 'vin',
    },
    filters: {
        distance: null,
        year: null,
        trim: [],
        engine: [],
        transmission: [],
        bodyStyle: [],
        drivetrain: [],
        sellers: [],
        months: 3,
        notPullFromMarket: false,
    },
    filterOptions: {
        trim: [],
        engine: [],
        transmission: [],
        bodyStyle: [],
        drivetrain: [],
        sellers: [],
    },
    series: [
        {
            name: 'Suggested Price',
            type: 'scatter',
            data: [],
        },
        {
            name: 'Current Price',
            type: 'scatter',
            data: [],
        },
        {
            name: 'Market Vehicles',
            type: 'scatter',
            data: [],
        },
        {
            name: 'Other Active Vehicles',
            type: 'scatter',
            data: [],
        },
        {
            name: 'Trend Line',
            type: 'line',
            data: [],
        },
        {
            name: 'Sold',
            type: 'scatter',
            data: [],
        },
        {
            name: 'Market Vehicles - Older Year',
            type: 'scatter',
            data: [],
        },
        {
            name: 'Market Vehicles - Newer Year',
            type: 'scatter',
            data: [],
        },
    ],
    isDistanceDisabled: false,
    vehiclesInMarket: [],
    activeInventory: [],
    soldInventory: [],
    suggestedPrice: 0,
    repairs: 0,
    markUp: 0,
    slopeDepreciation: null,
    intercept: null,
    isChartLoadingFirstTime: true,
    isCriteriaDataLoaded: false,
    carGurusInfo: null,
    filterOptionCount: [],
    filteredVehiclesInMarket: [],
    filteredVehiclesInMarketBackUp: [],
    columnsData: [],
    tableFilters: [],
    selectedYears: [],
    filtersBackup: null,
    areThereFiltersSaved: false,
    doneLoadingSavedFilters: false,
};

const ACTION_TYPES = {
    SET_SETTINGS: 'setSettings',
    SET_VEHICLES_DATA: 'setVehiclesData',
    SET_SERIES: 'setSeries',
    UPDATE_SUGGESTED_PRICE: 'updateSuggestedPrice',
    UPDATE_MARKPUP_OR_REPAIRS: 'updateMarkUpOrRepairs',
    SET_FILTERS: 'setFilters',
    SET_CARGURUS_INFO: 'setCargurusInfo',
    UPDATE_FILTERED_VEHICLES_MARKET: 'updateFilteredVehiclesMarket',
    UPDATE_TABLE_FILTERS: 'updateTableFilters',
    UPDATE_SELECTED_YEARS: 'updateSelectedYears',
    UPDATE_FILTERS_BACKUP: 'updateFiltersBackup',
};

const getListOptionsInFilter = (data, field, optional = 'none') => {
    const options = data
        .map((element) => (element[field] || element[optional] || '').toUpperCase())
        .filter((option) => !StringUtils.isEmpty(option))
        .sort((a, b) => a.localeCompare(b));

    if (options.length === 0) return [];
    return [
        'ALL',
        ...new Set(
            options,
        ),
    ];
};

const reducer = (state, action = {}) => {
    switch (action.type) {
    case ACTION_TYPES.UPDATE_FILTERS_BACKUP:
        return {
            ...state,
            filtersBackup: action.value,
        };
    case ACTION_TYPES.UPDATE_SELECTED_YEARS:

        return {
            ...state,
            selectedYears: state.filters.year,
            filters: {
                ...state.filters,
                notPullFromMarket: false,
            },
        };
    case ACTION_TYPES.SET_SETTINGS:
        const { year, trim, distance } = state.filters;
        const currentYears = year === null ? [action.value.year] : year;

        return {
            ...state,
            settings: action.value,
            selectedYears: currentYears,
            filters: {
                ...state.filters,
                distance: distance === null ? action.value.marketDistance : distance,
                year: currentYears,
                trim: trim.length === 0 ? [action.value.trim] : trim,
            },
        };
    case ACTION_TYPES.SET_VEHICLES_DATA:
        const { notPullFromMarket, trim: trimFilter } = state.filters;
        const {
            isDistanceDisabled, market, inventory, decodedTrim,
        } = action.value;

        const payload = {
            ...state,
            isDistanceDisabled,
            vehiclesInMarket: !notPullFromMarket
                ? [...new Map(
                    market
                        .filter((e) => Number(e.miles || 0) > 0 && Number(e.sellingPrice || 0) > 0 && e.trim !== null)
                        .map((el) => [el.vin, el]),
                ).values()]
                : state.vehiclesInMarket,
            activeInventory: [...new Map(
                inventory
                    .filter((element) => element.active && element.vin !== action.currentVIN && element.trim !== null)
                    .map((element) => [element.vin, element]),
            ).values()],
            soldInventory: [...new Map(
                inventory
                    .filter((element) => !element.active && element.soldDate && element.vin !== action.currentVIN && element.trim !== null)
                    .map((element) => [element.vin, element]),
            ).values()],
            isChartLoadingFirstTime: false,
        };

        const trimOptions = getListOptionsInFilter([...payload.vehiclesInMarket, ...payload.activeInventory, ...payload.soldInventory], 'trim');
        let selectedTrims = trimFilter.includes('ALL') ? trimFilter : trimFilter.filter((x) => trimOptions.includes(x));

        if (
            action.currentTrim
            && trimOptions.includes(action.currentTrim)
            && !selectedTrims.includes(action.currentTrim)
            && !state.areThereFiltersSaved
        ) {
            selectedTrims.push(action.currentTrim);
        }

        if (
            decodedTrim
            && decodedTrim !== action.currentTrim
            && trimOptions.includes(decodedTrim)
            && !selectedTrims.includes(decodedTrim)
            && !state.areThereFiltersSaved
        ) {
            selectedTrims.push(decodedTrim);
        }

        if (selectedTrims.length === 0) selectedTrims = ['ALL'];
        return {
            ...payload,
            filters: {
                ...state.filters,
                trim: selectedTrims,
            },
        };
    case ACTION_TYPES.SET_SERIES:
        const { slope, intercept } = action.equation;
        const options = action.filterOptions;
        const filters = (action.filtersData || state.filters);
        const {
            trim: trimData,
            engine,
            transmission,
            bodyStyle,
            drivetrain,
            sellers,
        } = filters;

        const filterOptionCount = [];
        Object.keys(options).forEach((filter, index) => {
            const data = filter === 'trim'
                ? [...state.vehiclesInMarket, ...state.activeInventory, ...state.soldInventory]
                : index >= 2 ? filterOptionCount[index - 1].data : action.fullFilteredData;
            const values = options[filter] || [];

            let property = filter;
            let dataUsedInFilter = [];
            if (property === 'sellers') property = 'sellerName';
            filterOptionCount.push({
                filter,
                options: values.map((v) => {
                    const allData = data.filter((el) => !StringUtils.isEmpty(el[property]));
                    const optionData = data.filter((el) => !StringUtils.isEmpty(el[property]) && el[property].toLowerCase() === v.toLowerCase());
                    const inputtedData = v === 'ALL' ? allData : optionData;

                    const isAllSelected = filters[filter].includes('ALL');
                    dataUsedInFilter = isAllSelected ? allData : allData.filter((el) => filters[filter].includes(el[property]));
                    return {
                        name: v,
                        count: inputtedData.length,
                    };
                }),
                data: dataUsedInFilter,
            });
        });

        return {
            ...state,
            sort: {
                dir: DataSort.DESC,
                field: 'vin',
            },
            filteredVehiclesInMarket: action.filteredVehiclesInMarket,
            filteredVehiclesInMarketBackUp: action.filteredVehiclesInMarket,
            series: action.value,
            slopeDepreciation: slope,
            intercept,
            filterOptionCount,
            filterOptions: options,
            filters: {
                ...state.filters,
                trim: (trimData || []).includes('ALL') && options.trim.length > 0
                    ? options.trim
                    : trimData,
                engine: engine.includes('ALL') && options.engine.length > 0
                    ? options.engine
                    : engine,
                transmission: transmission.includes('ALL') && options.transmission.length > 0
                    ? options.transmission
                    : transmission,
                bodyStyle: bodyStyle.includes('ALL') && options.bodyStyle.length > 0
                    ? options.bodyStyle
                    : bodyStyle,
                drivetrain: drivetrain.includes('ALL') && options.drivetrain.length > 0
                    ? options.drivetrain
                    : drivetrain,
                sellers: sellers.includes('ALL') && options.sellers.length > 0
                    ? options.sellers
                    : sellers,
            },
        };
    case ACTION_TYPES.UPDATE_SUGGESTED_PRICE:
        const { suggestedPrice, markUp, settings } = action.value;

        return {
            ...state,
            suggestedPrice,
            markUp,
            settings,
        };
    case ACTION_TYPES.UPDATE_MARKPUP_OR_REPAIRS:

        return {
            ...state,
            repairs: action.value.repairs,
            markUp: action.value.markUp,
        };
    case ACTION_TYPES.SET_FILTERS:

        return {
            ...state,
            filters: action.value,
            filtersBackup: action.filtersBackup || state.filtersBackup,
            isCriteriaDataLoaded: true,
            areThereFiltersSaved: action.areThereFiltersSaved || state.areThereFiltersSaved,
            doneLoadingSavedFilters: action.doneLoadingSavedFilters || state.doneLoadingSavedFilters,
        };
    case ACTION_TYPES.SET_CARGURUS_INFO:
        return {
            ...state,
            carGurusInfo: action.value,
        };
    case ACTION_TYPES.UPDATE_FILTERED_VEHICLES_MARKET:
        return {
            ...state,
            filteredVehiclesInMarket: action.value,
            sort: {
                dir: action.sortDirection,
                field: action.sortBy,
            },
        };
    case ACTION_TYPES.UPDATE_TABLE_FILTERS:
        return {
            ...state,
            ...action.value,
        };
    default:
        return state;
    }
};

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const MarketCompsTab = ({
    record,
    setVehicleDetail,
    canWrite,
    isAppraisals,
    setHeaderValues,
    headerValues,
}) => {
    const useStyles = makeStyles((theme) => MarketCompsTabStyle.getStyle(theme, isAppraisals));

    const userData = useContext(UserContext);
    const { userInformation } = userData;
    const { company } = userInformation || {};

    const trimRef = useRef();
    const tableReference = useRef();
    const classes = { ...useStyles(), ...buttonStyles() };
    const client = useApolloClient();
    const themeHook = useTheme();
    const isMobile = useMediaQuery(themeHook.breakpoints.down('sm'));
    const { sortDataByColumn } = useSortingActions();
    const {
        loadLocalFilterValues,
        resetFilters,
        applyFilters: applyLocalFilters,
        filterLocalData,
    } = useFilterActions();

    const repairsInitValue = (headerValues.repairs || 0) > 0 ? headerValues.repairs : 300;
    const markUpInitValue = (headerValues.markUp || 0) > 0 ? headerValues.markUp : 3000;
    const [state, dispatch] = useReducer(reducer, {
        ...INIT_STATE,
        isChartLoadingFirstTime: !isAppraisals,
        isCriteriaDataLoaded: isAppraisals === true,
        suggestedPrice: headerValues.suggestedPrice || INIT_STATE.suggestedPrice,
        repairs: isAppraisals
            ? repairsInitValue
            : INIT_STATE.repairs,
        markUp: isAppraisals
            ? markUpInitValue
            : INIT_STATE.markUp,
    });

    const {
        settings: {
            marketPercentage,
            marketDistance,
        },
        filters,
        filterOptions,
        vehiclesInMarket,
        activeInventory,
        soldInventory,
        series,
        suggestedPrice,
        repairs,
        markUp,
        slopeDepreciation,
        intercept,
        isChartLoadingFirstTime,
        isDistanceDisabled,
        isCriteriaDataLoaded,
        carGurusInfo,
        filteredVehiclesInMarket,
        selectedYears,
        doneLoadingSavedFilters,
    } = state;

    const calculatePercentage = (price) => {
        if (!slopeDepreciation || !intercept) return;

        const { miles, pricing } = record.detail;
        const payload = {};
        if (price <= 0) {
            payload.suggestedPrice = 0;
            payload.markUp = pricing ? Math.round(price - pricing.cost) : markUp;
            payload.settings = {
                marketPercentage: 0,
                marketDistance,
            };
        } else {
            const averagePrice = miles * slopeDepreciation + intercept;
            const percentage = Math.round((100 * price) / averagePrice);

            payload.suggestedPrice = price;
            payload.markUp = pricing ? Math.round(price - pricing.cost) : markUp;
            payload.settings = {
                marketPercentage: percentage,
                marketDistance,
            };
        }

        dispatch({
            type: ACTION_TYPES.UPDATE_SUGGESTED_PRICE,
            value: payload,
        });
    };

    const calculatePrice = (percentage = 0) => {
        if (!slopeDepreciation || !intercept) return;

        const { miles, pricing } = record.detail;
        const averagePrice = miles * slopeDepreciation + intercept;
        const price = Math.round(averagePrice * (percentage / 100));

        const isPriceCorrect = !Number.isNaN(price);
        dispatch({
            type: ACTION_TYPES.UPDATE_SUGGESTED_PRICE,
            value: {
                suggestedPrice: isPriceCorrect ? price : suggestedPrice,
                markUp: pricing && isPriceCorrect ? Math.round(price - pricing.cost) : markUp,
                settings: {
                    marketPercentage: percentage,
                    marketDistance,
                },
            },
        });
    };

    const linearRegression = (records) => {
        const { length } = records;
        let sumX = 0;
        let sumY = 0;
        let sumXY = 0;
        let sumXX = 0;

        records.forEach((item) => {
            sumX += item.x;
            sumY += item.y;
            sumXY += item.x * item.y;
            sumXX += item.x * item.x;
        });

        const slope = (length * sumXY - sumX * sumY) / (length * sumXX - sumX * sumX);
        return {
            intercept: (sumY / length) - (slope * sumX) / length,
            slope,
        };
    };

    const evaluateLinearEquation = (equation, x) => equation.slope * x + equation.intercept;
    const getTrendLine = (records) => {
        const equation = linearRegression(records);

        const minX = Math.min(...records.map((e) => e.x));
        const maxX = Math.max(...records.map((e) => e.x));

        return {
            points: [
                {
                    x: minX,
                    y: evaluateLinearEquation(equation, minX),
                },
                {
                    x: maxX,
                    y: evaluateLinearEquation(equation, maxX),
                },
            ],
            equation,
        };
    };

    const getTickAmountInAxis = () => {
        const incrementsInX = 10000;
        const incrementsInY = 2000;

        const valuesX = series
            .map((s) => s.data.map((el) => el.x))
            .flat()
            .filter((el) => !isNaN(el) && Math.abs(el) !== Infinity);
        const valuesY = series
            .map((s) => s.data.map((el) => el.y))
            .flat()
            .filter((el) => !isNaN(el) && Math.abs(el) !== Infinity);

        const maxInX = Math.max(...valuesX);
        const maxInY = Math.max(...valuesY);
        const minInX = Math.min(...valuesX);
        const minInY = Math.min(...valuesY);

        const maxValueX = Math.ceil(maxInX / incrementsInX) * incrementsInX;
        const maxValueY = Math.ceil(maxInY / incrementsInY) * incrementsInY;
        const minValueX = Math.floor(minInX / incrementsInX) * incrementsInX;
        const minValueY = Math.floor(minInY / incrementsInY) * incrementsInY;

        return {
            tickAmountX: (maxValueX - minValueX) / incrementsInX,
            tickAmountY: (maxValueY - minValueY) / incrementsInY,
            maxValueX,
            maxValueY,
            minValueX,
            minValueY,
        };
    };

    const chartOptions = InventoryHelper.getMarketCompsChartOptions(themeHook, (data) => {
        const { seriesIndex, dataPointIndex } = data;
        if (seriesIndex === 4) return ''; // Trend Line

        let info = null;
        switch (seriesIndex) {
        case 0:
        case 1:
            info = InventoryHelper.formatInfoForMarketCompsTooltip({
                ...record.detail,
                thumbnail: record.thumbnail,
                alternativeThumbnail: record.alternativeThumbnail,
                ...(seriesIndex === 0
                    ? { pricing: { ...record.detail.pricing, internetPrice: NumberUtils.applyCurrencyFormat(suggestedPrice) } }
                    : {}),
            });
            break;
        default:
            info = InventoryHelper.formatInfoForMarketCompsTooltip(series[seriesIndex]?.data[dataPointIndex]?.vehicle);
            break;
        }

        const component = (
            <div className="scatter-tooltip">
                <div className="vehicle-scatter-info">
                    <a
                        href={info.url || '#'}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="vehicle-scatter-title"
                    >
                        {`${info.year} ${info.make} ${info.model} ${info.trim}`}
                    </a>
                    {!info.seller && info.stockNumber > 0 && (
                        <span>
                            <span className="vehicle-scatter-label">
                                Stock Number:
                            </span>
                            <span className="vehicle-scatter-value">
                                {info.stockNumber}
                            </span>
                        </span>
                    )}
                    {info.seller && (
                        <span className={classes.vinStyle}>
                            <span className="vehicle-scatter-label">
                                VIN:
                            </span>
                            <span className="vehicle-scatter-value">
                                {info.vin}
                            </span>
                            <div className="vinCopy">
                                <FileCopyOutlinedIcon />
                            </div>
                        </span>
                    )}
                    {(info.seller || info.soldDate) && (
                        <span>
                            <span className="vehicle-scatter-label">
                                Engine:
                            </span>
                            <span className="vehicle-scatter-value">
                                {info.engine}
                            </span>
                        </span>
                    )}
                    <span>
                        <span className="vehicle-scatter-label">Price:</span>
                        <span className="vehicle-scatter-value">
                            {NumberUtils.applyCurrencyFormat(info.price)}
                        </span>
                    </span>
                    <span>
                        <span className="vehicle-scatter-label">Miles:</span>
                        <span className="vehicle-scatter-value">
                            {info.miles}
                        </span>
                    </span>
                    {Number(info.age) >= 0 && (
                        <span>
                            <span className="vehicle-scatter-label">Age:</span>
                            <span className="vehicle-scatter-value">
                                {info.age}
                            </span>
                            {info.dealType && (
                                <>
                                    <span className="vehicle-scatter-label">Deal Type:</span>
                                    <span className="vehicle-scatter-value">
                                        {info.dealType.toUpperCase()}
                                    </span>
                                </>
                            )}
                        </span>
                    )}
                    {info.distance && (
                        <span>
                            <span className="vehicle-scatter-label">Distance:</span>
                            <span className="vehicle-scatter-value">
                                {NumberUtils.conditionalRoundUp(info.distance)}
                            </span>
                        </span>
                    )}
                    {info.soldDate && (
                        <span>
                            <span className="vehicle-scatter-label">Sold Date:</span>
                            <span className="vehicle-scatter-value">
                                {DateUtils.getUSFormattedDateFromUTC(info.soldDate, DateFormat.DEFAULT_DATE)}
                            </span>
                        </span>
                    )}
                    {info.lotName && (
                        <span>
                            <span className="vehicle-scatter-label">Lot Name:</span>
                            <span className="vehicle-scatter-value">
                                {info.lotName}
                            </span>
                        </span>
                    )}
                    {info.seller && (
                        <span>
                            <span className="vehicle-scatter-label">Seller:</span>
                            <span className="vehicle-scatter-value">
                                {info.seller}
                            </span>
                        </span>
                    )}
                </div>
                {(info.thumbnail && !isMobile) && (
                    <div className="thumbnail-container">
                        <img
                            src={info.thumbnail}
                            width="50"
                            height="50"
                            alt="Vehicle thumbnail"
                            onError={(event) => {
                                // eslint-disable-next-line no-param-reassign
                                event.target.src = InventoryHelper.getThumbnail(info.bodyStyle);
                            }}
                        />
                    </div>
                )}
            </div>
        );

        return ReactDOMServer.renderToString(component);
    }, getTickAmountInAxis());

    const {
        data: criteriaData,
        loading: criteriaLoading,
        error: criteriaError,
    } = useQuery(InventoryQuery.GET_FILTER_CRITERIA, {
        variables: {
            stockNumber: record.detail.stockNumber,
        },
        fetchPolicy: FetchPolicy.NO_CACHE,
    });

    const { INV_MARKET_PERCENTAGE, INV_MARKET_DISTANCE } = InventoryCategory;
    const {
        data: settingsData,
        loading: settingsLoading,
        error: settingsError,
    } = useQuery(LotQuery.GET_SETTINGS, {
        variables: {
            category: LotsCategory.INVENTORY,
            lotName: ALL_LOTS,
            key: [INV_MARKET_PERCENTAGE, INV_MARKET_DISTANCE],
        },
        skip: !record.detail.year || !doneLoadingSavedFilters,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const {
        data: vehiclesData,
        loading: vehiclesLoading,
        error: vehiclesError,
    } = useQuery(InventoryQuery.PULL_MARKET_COMPS_VEHICLES, {
        variables: {
            distance: filters.distance === -1 ? null : filters.distance,
            stockNumber: record.detail.stockNumber,
            year: selectedYears,
            make: record.detail.make,
            model: record.detail.model,
            trim: record.detail.trim || '',
            months: filters.months === -1 ? null : filters.months,
            lotName: record.detail.lotName,
            notPullFromMarket: filters.notPullFromMarket,
            isAppraisals,
        },
        skip: !filters.distance
            || !isCriteriaDataLoaded
            || selectedYears.length === 0,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const {
        data: carGurusData,
        loading: carGurusLoading,
        error: carGurusError,
    } = useQuery(InventoryQuery.PULL_CARGURUS_DATA, {
        variables: isAppraisals ? {
            manualInput: {
                vin: record.detail.vin,
                price: suggestedPrice,
                miles: record.detail.miles,
            },
        } : {
            stockNumber: record.detail.stockNumber,
            internetPrice: record.detail.pricing?.internetPrice,
        },
        skip: isAppraisals && (!suggestedPrice || suggestedPrice <= 0 || record.detail?.miles <= 0),
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [saveVehicle] = useMutation(InventoryMutation.UPDATE_VEHICLE, {
        onCompleted: (response) => {
            if (response) {
                setVehicleDetail('pricing', {
                    internetPrice: suggestedPrice,
                });
                ModalUtils.successMessage(null, 'Internet Price updated successfully');
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage(null, error);
        },
    });

    useEffect(() => {
        if (carGurusError) {
            const error = carGurusError?.graphQLErrors[0]?.message;
            if (error === 'No CarGurus credentials found.') return;

            dispatch({
                type: ACTION_TYPES.SET_CARGURUS_INFO,
                value: {},
            });

            return;
        }

        if (!carGurusLoading) {
            const data = carGurusData?.pullCarGurusData;
            if (data) {
                dispatch({
                    type: ACTION_TYPES.SET_CARGURUS_INFO,
                    value: data,
                });
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [carGurusLoading, carGurusError]);

    useEffect(() => {
        if (settingsError) {
            ModalUtils.errorMessage(settingsError?.graphQLErrors);
            return;
        }

        if (!settingsLoading) {
            const settings = settingsData?.getSettings;
            if (settings) {
                dispatch({
                    type: ACTION_TYPES.SET_SETTINGS,
                    value: {
                        marketPercentage: Number(settings.find((setting) => setting.key === INV_MARKET_PERCENTAGE)?.value || marketPercentage),
                        marketDistance: Number(settings.find((setting) => setting.key === INV_MARKET_DISTANCE)?.value || marketDistance),
                        year: record.detail.year,
                        trim: (record.detail?.trim || '').toUpperCase(),
                    },
                });
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settingsLoading, settingsError]);

    const generateFiltersBackup = (data) => {
        if (Object.keys(data).length === 0) return null;

        return Object.fromEntries(
            Object
                .entries(data)
                .filter(([key]) => !Array.isArray(data[key]) || (Array.isArray(data[key]) && !data[key].includes('ALL'))),
        );
    };

    useEffect(() => {
        if (criteriaError) {
            ModalUtils.errorMessage(criteriaError?.graphQLErrors);
            return;
        }

        if (!criteriaLoading) {
            const clone = cloneDeep(filters);
            const response = criteriaData?.getFilterCriteria;

            let data = {};
            let areThereFiltersSaved = false;
            let filtersBackup = null;
            if (response) {
                areThereFiltersSaved = true;
                data = JSON.parse(response.criteria);
                delete data.marketPercentage;

                Object.keys(data).forEach((key) => {
                    if (data[key] === null) delete data[key];
                });

                filtersBackup = generateFiltersBackup(data);
            }

            const updated = {
                ...clone,
                ...data,
            };

            Object.keys(updated).forEach((key) => {
                const val = updated[key];

                if (
                    ['engine', 'transmission', 'bodyStyle', 'drivetrain', 'sellers'].includes(key)
                ) {
                    if (val.length === 0) updated[key] = ['ALL'];
                    if (val.length > 0) updated[key] = [...new Set(val.join(',').toUpperCase().split(','))];
                }
            });

            dispatch({
                type: ACTION_TYPES.SET_FILTERS,
                value: updated,
                filtersBackup,
                areThereFiltersSaved,
                doneLoadingSavedFilters: true,
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [criteriaLoading, criteriaError]);

    const splitInMarketByYear = (data, pattern) => {
        const { year } = record.detail;

        switch (pattern) {
        case 'current':
            return data.filter((element) => Number(element.year) === Number(year));
        case 'older':
            return data.filter((element) => Number(element.year) < Number(year));
        case 'newer':
            return data.filter((element) => Number(element.year) > Number(year));
        default:
            return [];
        }
    };

    const getFilteredData = (data, fields, item) => data
        .filter((element) => {
            const value = fields.reduce((a, b) => {
                const current = element[b];
                return current || a;
            }, null);

            return item.includes('ALL')
                || !value
                || item.includes(value.toUpperCase());
        });

    const applyFilters = (data, filtersData, filterByTrim = false) => {
        let filteredData = data;
        const {
            trim = [],
            engine = [],
            transmission = [],
            bodyStyle = [],
            drivetrain = [],
            sellers = [],
        } = (filtersData || filters);

        if (trim.length > 0) {
            filteredData = getFilteredData(filteredData, ['trim'], trim);
        }

        if (filterByTrim) return filteredData;
        if (engine.length > 0) {
            filteredData = getFilteredData(filteredData, ['engine'], engine);
        }

        if (transmission.length > 0) {
            filteredData = getFilteredData(filteredData, ['transmission'], transmission);
        }

        if (bodyStyle.length > 0) {
            filteredData = getFilteredData(filteredData, ['bodyStyle', 'style'], bodyStyle);
        }

        if (drivetrain.length > 0) {
            filteredData = getFilteredData(filteredData, ['drivetrain'], drivetrain);
        }

        if (sellers.length > 0) {
            filteredData = getFilteredData(filteredData, ['sellerName'], sellers);
        }

        return filteredData;
    };

    const filterRecords = (filtersData) => {
        const currentMarketData = splitInMarketByYear(vehiclesInMarket, 'current');
        const olderMarketData = splitInMarketByYear(vehiclesInMarket, 'older');
        const newerMarketData = splitInMarketByYear(vehiclesInMarket, 'newer');

        const currentMarketSerieFiltered = applyFilters(currentMarketData, filtersData);
        const activeSerieFiltered = applyFilters(activeInventory, filtersData);
        const soldSerieFiltered = applyFilters(soldInventory, filtersData);
        const olderMarketSerieFiltered = applyFilters(olderMarketData, filtersData);
        const newerMarketSerieFiltered = applyFilters(newerMarketData, filtersData);

        const clone = cloneDeep(series);
        clone[0].data = suggestedPrice > 0 ? [{ x: record.detail.miles, y: suggestedPrice }] : [];
        clone[1].data = [{ x: record.detail.miles, y: record.detail?.pricing?.internetPrice }];
        clone[2].data = currentMarketSerieFiltered
            .map((vehicle) => ({ x: Number(vehicle.miles || 0), y: Number(vehicle.sellingPrice || 0), vehicle }));
        clone[3].data = activeSerieFiltered.map((vehicle) => ({ x: Number(vehicle.miles), y: Number(vehicle.internetPrice), vehicle }));
        clone[5].data = soldSerieFiltered.map((vehicle) => ({ x: Number(vehicle.miles), y: Number(vehicle.internetPrice), vehicle }));
        clone[6].data = olderMarketSerieFiltered
            .map((vehicle) => ({ x: Number(vehicle.miles || 0), y: Number(vehicle.sellingPrice || 0), vehicle }));
        clone[7].data = newerMarketSerieFiltered
            .map((vehicle) => ({ x: Number(vehicle.miles || 0), y: Number(vehicle.sellingPrice || 0), vehicle }));

        const trendLine = getTrendLine([...clone[2].data, ...clone[6].data, ...clone[7].data]);
        clone[4].data = trendLine.points;

        const fullData = [...vehiclesInMarket, ...activeInventory, ...soldInventory];
        const fullFilteredData = applyFilters(fullData, filtersData, true);
        const filteredTransmissionData = applyFilters(fullFilteredData, { engine: filtersData?.engine }, false);
        const filteredDrivetrainData = applyFilters(filteredTransmissionData, { transmission: filtersData?.transmission }, false);
        const filteredBodyStyleData = applyFilters(filteredDrivetrainData, { drivetrain: filtersData?.drivetrain }, false);
        const filteredSellersData = applyFilters(filteredBodyStyleData, { bodyStyle: filtersData?.bodyStyle }, false);

        const options = {
            ...filterOptions,
            trim: getListOptionsInFilter(fullData, 'trim'),
            engine: getListOptionsInFilter(fullFilteredData, 'engine'),
            transmission: getListOptionsInFilter(filteredTransmissionData, 'transmission'),
            drivetrain: getListOptionsInFilter(filteredDrivetrainData, 'drivetrain'),
            bodyStyle: getListOptionsInFilter(filteredBodyStyleData, 'bodyStyle', 'style'),
            sellers: getListOptionsInFilter(filteredSellersData, 'sellerName'),
        };

        const {
            detail,
            thumbnail,
            alternativeThumbnail,
        } = record;

        const filtersOptions = options || filterOptions;
        dispatch({
            type: ACTION_TYPES.SET_SERIES,
            value: clone,
            equation: trendLine.equation,
            filterOptions: {
                trim: filtersOptions.trim,
                engine: filtersOptions.engine,
                transmission: filtersOptions.transmission,
                drivetrain: filtersOptions.drivetrain,
                bodyStyle: filtersOptions.bodyStyle,
                sellers: filtersOptions.sellers,
            },
            filtersData,
            fullFilteredData,
            filteredVehiclesInMarket: sortDataByColumn('vin', DataSort.DESC, [
                ...currentMarketSerieFiltered,
                ...olderMarketSerieFiltered,
                ...newerMarketSerieFiltered,
                ...activeSerieFiltered.map((x) => ({
                    ...x,
                    sellerName: x.lotName,
                    url: `/${modules.INVENTORY}/${x.stockNumber}`,
                    sellingPrice: x.internetPrice,
                    distance: 0,
                    isActiveVehicle: true,
                })),
                {
                    ...detail,
                    thumbnail,
                    alternativeThumbnail,
                    sellerName: detail.lotName,
                    sellingPrice: detail.pricing?.internetPrice ?? suggestedPrice ?? 0,
                    age: detail.pricing?.age ?? 0,
                    distance: 0,
                    isCurrentVehicle: true,
                },
            ]),
        });
    };

    const applyFilter = (name, value) => {
        if (vehiclesLoading) return;

        const clone = cloneDeep(filters);
        const options = filterOptions[name];
        trimRef.current = {
            selected: filters.trim,
            choices: filterOptions.trim,
        };

        if (['distance', 'months', 'year'].includes(name)) {
            const isYear = name === 'year';
            if (!isYear) clone.notPullFromMarket = name === 'months';
            clone[name] = (isYear ? value.sort((a, b) => a.localeCompare(b)) : value);

            dispatch({
                type: ACTION_TYPES.SET_FILTERS,
                value: clone,
            });
            return;
        }

        if (!ArrayUtils.isNotEmpty(value)) {
            clone[name] = [...options];
        } else if (!value.includes('ALL') && value.length === options.length - 1) {
            const current = clone[name];

            if (current.includes('ALL')) {
                clone[name] = [];
            } else {
                clone[name] = ['ALL', ...value];
            }
        } else if (value.includes('ALL')) {
            const current = clone[name];
            if (current.length > value.length && value.length > 1) {
                clone[name] = value.filter((e) => e !== 'ALL');
            } else {
                clone[name] = [...options];
            }
        } else {
            clone[name] = value;
        }

        filterRecords(clone);
    };

    useEffect(() => {
        if (vehiclesError) {
            ModalUtils.errorMessage(vehiclesError?.graphQLErrors);
            return;
        }

        if (!vehiclesLoading) {
            const data = vehiclesData?.pullMarketCompsVehicles;
            if (data) {
                dispatch({
                    type: ACTION_TYPES.SET_VEHICLES_DATA,
                    value: data,
                    currentVIN: record.detail.vin,
                    currentTrim: record.detail.trim?.toUpperCase(),
                });
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vehiclesLoading, vehiclesError]);

    const appraisedValue = suggestedPrice - repairs - markUp;
    useEffect(() => {
        if (
            vehiclesInMarket.length > 0
            || activeInventory.length > 0
            || soldInventory.length > 0
        ) filterRecords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vehiclesInMarket, activeInventory, soldInventory, suggestedPrice]);

    useEffect(() => {
        if (!slopeDepreciation || !intercept) return;
        if (suggestedPrice > 0) {
            calculatePercentage(suggestedPrice);
        } else {
            calculatePrice(marketPercentage);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [slopeDepreciation, intercept]);

    useEffect(() => {
        calculatePrice(marketPercentage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [record.detail?.miles]);

    useEffect(() => {
        setHeaderValues(suggestedPrice, marketPercentage, repairs, markUp, appraisedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appraisedValue]);

    useEffect(() => {
        const { sort: { dir, field }, tableFilters, filteredVehiclesInMarketBackUp } = state;
        dispatch({
            type: ACTION_TYPES.UPDATE_FILTERED_VEHICLES_MARKET,
            value: sortDataByColumn(
                field,
                dir,
                filterLocalData(tableFilters, filteredVehiclesInMarketBackUp),
            ),
            sortBy: field,
            sortDirection: dir,
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.tableFilters]);

    const saveCriteria = async () => {
        try {
            const {
                distance,
                months,
                year,
                trim,
                engine,
                transmission,
                drivetrain,
                bodyStyle,
                sellers,
            } = filters;

            const filtersToSave = {
                distance,
                months,
                year,
                ...(trim.length === 0 ? { trim: null } : { trim }),
                ...(engine.length === 0 ? { engine: null } : { engine }),
                ...(transmission.length === 0 ? { transmission: null } : { transmission }),
                ...(drivetrain.length === 0 ? { drivetrain: null } : { drivetrain }),
                ...(bodyStyle.length === 0 ? { bodyStyle: null } : { bodyStyle }),
                ...(sellers.length === 0 ? { sellers: null } : { sellers }),
            };

            await client.mutate({
                mutation: InventoryMutation.SAVE_VEHICLE_MARKET_CRITERIA,
                variables: {
                    input: {
                        stockNumber: record.detail.stockNumber,
                        criteria: filtersToSave,
                    },
                },
                fetchPolicy: FetchPolicy.NO_CACHE,
            });

            dispatch({
                type: ACTION_TYPES.UPDATE_FILTERS_BACKUP,
                value: generateFiltersBackup(filtersToSave),
            });

            ModalUtils.successMessage(null, 'Filters saved successfully');
        } catch (error) {
            ModalUtils.errorMessage(null, 'Error saving the filters');
        }
    };

    const onChange = (name, value) => {
        const payload = {
            repairs,
            markUp,
        };

        dispatch({
            type: ACTION_TYPES.UPDATE_MARKPUP_OR_REPAIRS,
            value: {
                ...payload,
                [name]: value,
            },
        });
    };

    const rowStyleFormat = (row) => {
        const { index } = row;
        if (index < 0) return;

        const rowData = filteredVehiclesInMarket[index];
        const isCurrent = 'isCurrentVehicle' in rowData;
        const isActive = 'isActiveVehicle' in rowData;
        if (!isCurrent && !isActive) return;

        // eslint-disable-next-line consistent-return
        return {
            fontWeight: 'bold',
            ...(isCurrent ? { backgroundColor: themeHook.palette.background.mintGreen } : {}),
        };
    };

    const exportRecordsToExcel = async () => {
        const {
            year,
            make,
            model,
            trim,
        } = record.detail;

        const date = DateUtils.getFormattedDateInUserTimezone(new Date().toISOString(), DateFormat.SHORT_DATE_WITH_DASHES);
        const name = `${year}-${make}-${model}-${trim}-${date}`.toLowerCase();
        const spreadSheet = new SpreadsheetUtils(name);

        const rows = filteredVehiclesInMarket.map((row) => ({
            VIN: row.vin,
            VEHICLE: `${row.year} ${row.make} ${row.model} ${row.trim}`.toUpperCase(),
            ENGINE: row.engine,
            MILES: NumberUtils.applyThousandsFormat(row.miles),
            PRICE: NumberUtils.applyCurrencyFormat(row.sellingPrice),
            AGE: NumberUtils.applyThousandsFormat(row.age),
            DISTANCE: NumberUtils.conditionalRoundUp(row.distance),
            SELLER: row.sellerName,
            URL: row.url,
        }));

        const widthSizePixel = 150;
        const firstRow = rows[0];
        const columnsProperties = { sizes: Object.keys(firstRow).map(() => widthSizePixel) };
        await spreadSheet.addWorkSheet(rows, columnsProperties, 'Report');
        await spreadSheet.download();
    };

    const onSort = ({ sortBy, sortDirection }) => {
        const sortedData = sortDataByColumn(sortBy, sortDirection, filteredVehiclesInMarket);
        dispatch({
            type: ACTION_TYPES.UPDATE_FILTERED_VEHICLES_MARKET,
            value: sortedData,
            sortBy,
            sortDirection,
        });
    };

    const onLoadFilterValues = async (column, searchInput = null) => {
        const { columnsData, tableFilters } = state;
        const data = loadLocalFilterValues(filteredVehiclesInMarket, columnsData, column, searchInput);
        dispatch({
            type: ACTION_TYPES.UPDATE_TABLE_FILTERS,
            value: {
                columnsData: data,
                tableFilters,
            },
        });
    };

    const onResetFilters = (column) => {
        const { columnsData, tableFilters } = state;
        const data = resetFilters(columnsData, column, tableFilters);

        dispatch({
            type: ACTION_TYPES.UPDATE_TABLE_FILTERS,
            value: {
                columnsData: data.columnsData,
                tableFilters: data.filters,
            },
        });

        if (column && tableReference.current) tableReference.current.closeFilterBox();
    };

    const onApplyFilters = (column, selectedValues) => {
        const { columnsData, tableFilters } = state;
        const data = applyLocalFilters(tableFilters, column, selectedValues, false, columnsData);

        dispatch({
            type: ACTION_TYPES.UPDATE_TABLE_FILTERS,
            value: {
                columnsData: data.columnsData,
                tableFilters: data.filters,
            },
        });

        if (tableReference.current) tableReference.current.closeFilterBox();
    };

    const updateYearsFilter = () => {
        dispatch({
            type: ACTION_TYPES.UPDATE_SELECTED_YEARS,
        });
    };

    const renderFilterValues = (selected, options) => {
        if (options && options.length === 0) return 'No options';
        if (selected.length === 0 && options && options.length > 0) return 'Select...';
        return selected.includes('ALL') ? 'ALL' : selected.join(', ');
    };

    const checkBoldFilterOption = (filter, option) => {
        const { filtersBackup } = state;

        return filtersBackup
        && filter in filtersBackup
        && (
            (!Array.isArray(filtersBackup[filter]) && String(filtersBackup[filter]).toLowerCase() === String(option)?.toLowerCase())
            || (Array.isArray(filtersBackup[filter]) && filtersBackup[filter].includes(option))
        );
    };

    const buildFilterOptions = (filter, current, values) => values.map((option, index) => {
        const options = state.filterOptionCount.find((c) => c.filter === filter)?.options || [];
        const isBold = checkBoldFilterOption(filter, option);

        return (
            <MenuItem
                key={index}
                value={option}
            >
                <Checkbox
                    color="primary"
                    disabled={filter === 'year' ? (option === record.detail.year) : false}
                    checked={(current || []).includes('ALL') || (current || []).includes(option)}
                />
                <ListItemText
                    className={isBold ? classes.boldFilter : ''}
                    primary={`${option} ${options.length > 0 ? `(${options.find((op) => op.name.toLowerCase() === option.toLowerCase())?.count || 0})` : ''}`}
                />
            </MenuItem>
        );
    });

    const getColumns = () => [
        {
            headerClassName: classes.tableHeader,
            label: 'No',
            dataKey: 'number',
            width: 40,
            filterEnabled: false,
            disableSort: true,
            cellRenderer: (cell) => (
                <span>{cell.rowIndex + 1}</span>
            ),
        },
        {
            headerClassName: classes.tableHeader,
            label: 'VIN',
            dataKey: 'vin',
            width: 200,
            filterEnabled: true,
            cellRenderer: (cell) => (
                <a
                    href={cell.rowData.url || '#'}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {cell.rowData.vin?.toUpperCase()}
                </a>
            ),
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Vehicle',
            dataKey: 'trim',
            width: 180,
            filterEnabled: true,
            cellRenderer: (cell) => {
                const {
                    rowData: {
                        year,
                        make,
                        model,
                        trim,
                    },
                } = cell;

                return (
                    <span className={classes.ellipsis}>{`${year} ${make} ${model} ${trim}`.toUpperCase()}</span>
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Engine',
            dataKey: 'engine',
            width: 120,
            filterEnabled: true,
            cellRenderer: (cell) => (
                <span className={classes.ellipsis}>{cell.rowData.engine}</span>
            ),
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Miles',
            dataKey: 'miles',
            width: 90,
            filterEnabled: false,
            cellRenderer: (cell) => (
                <span>{NumberUtils.applyThousandsFormat(cell.rowData.miles)}</span>
            ),
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Price',
            dataKey: 'sellingPrice',
            width: 100,
            filterEnabled: false,
            cellRenderer: (cell) => (
                <span>{NumberUtils.applyCurrencyFormat(cell.rowData.sellingPrice)}</span>
            ),
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Age',
            dataKey: 'age',
            width: 80,
            filterEnabled: false,
            cellRenderer: (cell) => (
                <span>{NumberUtils.applyThousandsFormat(cell.rowData.age)}</span>
            ),
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Distance',
            dataKey: 'distance',
            width: 120,
            filterEnabled: false,
            cellRenderer: (cell) => (
                <span>{NumberUtils.conditionalRoundUp(cell.rowData.distance)}</span>
            ),
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Seller',
            dataKey: 'sellerName',
            width: 200,
            filterEnabled: true,
            cellRenderer: (cell) => (
                <span className={classes.ellipsis}>{cell.rowData.sellerName?.toUpperCase()}</span>
            ),
        },
    ];

    let subtractor = 3; // Year Range +/- 2
    const yearOptions = buildFilterOptions('year', filters.year, [...Array(5).keys()].map(() => {
        subtractor -= 1;
        const year = Number(record.detail.year);
        const calculatedValue = String(year + subtractor);

        return calculatedValue;
    }));

    const currentMarkUp = (record.detail?.pricing?.internetPrice || 0) - (record.detail?.pricing?.cost || 0);
    const noTrendLine = Number.isNaN(slopeDepreciation || NaN) || Number.isNaN(intercept || NaN);
    return (
        <div className={classes.main}>
            <div className={classes.topRow}>
                <>
                    <div className={classes.leftSection}>
                        {canWrite && (
                            <Button
                                disabled={vehiclesLoading || noTrendLine}
                                className={classes.containedSecondaryInfo}
                                size="medium"
                                onClick={() => saveVehicle({
                                    variables: {
                                        stockNumber: record.detail.stockNumber,
                                        input: {
                                            internetPrice: suggestedPrice,
                                        },
                                    },
                                })}
                            >
                                Save
                            </Button>
                        )}
                        <Form.Group as={Col}>
                            <Form.Label className={classes.subLabel}>Suggested Price</Form.Label>
                            <InputNumber
                                disabled={vehiclesLoading || noTrendLine}
                                value={suggestedPrice}
                                onChange={(value) => calculatePercentage(value)}
                                placeholder="$0"
                                showCurrency
                                min={0}
                                decimalScale={0}
                                thousandSeparator
                                size="sm"
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label className={classes.subLabel}>% of Market</Form.Label>
                            <input
                                disabled={vehiclesLoading || noTrendLine}
                                className={classes.market}
                                name="marketPercentage"
                                type="number"
                                min="0"
                                value={marketPercentage}
                                onChange={({ target: { value } }) => calculatePrice(value)}
                            />
                        </Form.Group>
                        {isAppraisals && (
                            <Form.Group as={Col}>
                                <Form.Label className={classes.subLabel}>Repairs & Transport</Form.Label>
                                <InputNumber
                                    disabled={vehiclesLoading || noTrendLine}
                                    allowNegative={false}
                                    value={repairs}
                                    onChange={(value) => onChange('repairs', value)}
                                    placeholder="$0"
                                    showCurrency
                                    min={0}
                                    decimalScale={0}
                                    thousandSeparator
                                    size="sm"
                                />
                            </Form.Group>
                        )}
                        <Form.Group as={Col}>
                            <Form.Label className={classes.subLabel}>Mark Up</Form.Label>
                            {!isAppraisals && (
                                <Form.Control
                                    readOnly
                                    className={clsx(classes.input, markUp >= 0 ? classes.markupPositive : classes.markupNegative)}
                                    type="text"
                                    value={NumberUtils.applyCurrencyFormat(markUp, '$0,0')}
                                />
                            )}
                            {isAppraisals && (
                                <InputNumber
                                    disabled={vehiclesLoading || noTrendLine}
                                    allowNegative={false}
                                    value={markUp}
                                    onChange={(value) => onChange('markUp', value)}
                                    placeholder="$0"
                                    showCurrency
                                    min={0}
                                    decimalScale={0}
                                    thousandSeparator
                                    size="sm"
                                />
                            )}
                        </Form.Group>
                        {isAppraisals && (
                            <Form.Group as={Col}>
                                <Form.Label className={classes.subLabel}>Appraised Value</Form.Label>
                                <InputNumber
                                    readOnly
                                    allowNegative
                                    value={appraisedValue}
                                    className={appraisedValue >= 0 ? classes.markupPositive : classes.markupNegative}
                                    showCurrency
                                    thousandSeparator
                                    size="sm"
                                />
                            </Form.Group>
                        )}
                    </div>
                    <div className={classes.rightSection}>
                        {!isAppraisals && (
                            <Form.Group as={Col}>
                                <Form.Label className={classes.subLabel}>Total Cost</Form.Label>
                                <Form.Control
                                    readOnly
                                    className={classes.input}
                                    type="text"
                                    value={NumberUtils.applyCurrencyFormat(record.detail?.pricing?.cost || 0, '$0,0')}
                                />
                            </Form.Group>
                        )}
                        {!isAppraisals && (
                            <Form.Group as={Col}>
                                <Form.Label className={classes.subLabel}>Mark Up</Form.Label>
                                <Form.Control
                                    readOnly
                                    className={clsx(classes.input, currentMarkUp >= 0 ? classes.markupPositive : classes.markupNegative)}
                                    type="text"
                                    value={NumberUtils.applyCurrencyFormat(currentMarkUp, '$0,0')}
                                />
                            </Form.Group>
                        )}
                        {isAppraisals && (
                            <Button
                                disabled={!record.detail?.vin || !company?.carfaxId}
                                className={classes.carfax}
                                size="small"
                                onClick={() => window.open(HttpClient.getCarFaxURL(company.carfaxId, record.detail.vin))}
                            >
                                <img
                                    alt="Carfax"
                                    src={CarfaxIcon}
                                />
                            </Button>
                        )}
                    </div>
                </>
            </div>
            {carGurusInfo && (
                <CarGurusBox info={carGurusInfo} />
            )}
            <div className={classes.counter}>
                <div>
                    <span>Market:</span>
                    {filteredVehiclesInMarket.length > 0 ? filteredVehiclesInMarket.length - series[3].data.length - 1 : 0}
                </div>
                <div>
                    /
                </div>
                <div>
                    <span>Active Inventory:</span>
                    {series[3].data.length}
                </div>
                <div>
                    /
                </div>
                <div>
                    <span>Sold Inventory:</span>
                    {series[5].data.length}
                </div>
            </div>
            <div className={classes.bottomRow}>
                <div id="chartContainer" className={classes.chartContainer}>
                    {(isChartLoadingFirstTime || vehiclesLoading) && (
                        <div className={classes.loader}>
                            <CircularProgress
                                size={24}
                                disableShrink
                            />
                        </div>
                    )}
                    <Chart
                        options={chartOptions}
                        series={series}
                        height="90%"
                        width="100%"
                    />
                </div>
                <div className={classes.filtersContainer}>
                    <div
                        className={classes.filtersTitle}
                    >
                        Filters
                    </div>
                    <div>
                        {filters.distance && (
                            <Form.Group as={Col}>
                                <Form.Label className={classes.subLabel}>Distance</Form.Label>
                                <Select
                                    disabled={isDistanceDisabled || vehiclesLoading}
                                    value={filters.distance}
                                    variant="outlined"
                                    margin="dense"
                                    name="distance"
                                    onChange={({ target: { name, value } }) => applyFilter(name, value)}
                                >
                                    {MarketCompsDistanceOptions.map((option, index) => {
                                        const isBold = checkBoldFilterOption('distance', option);

                                        return (
                                            <MenuItem
                                                key={index}
                                                className={isBold ? classes.boldFilter : ''}
                                                value={option === 'Any' ? -1 : Number(option)}
                                            >
                                                {option}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </Form.Group>
                        )}
                        <Form.Group as={Col}>
                            <Form.Label className={classes.subLabel}>Months</Form.Label>
                            <Select
                                disabled={vehiclesLoading || !record.detail.year}
                                value={filters.months}
                                variant="outlined"
                                margin="dense"
                                name="months"
                                onChange={({ target: { name, value } }) => applyFilter(name, value)}
                            >
                                {[-1, 3, 4].map((option, index) => {
                                    const isBold = checkBoldFilterOption('months', option);

                                    return (
                                        <MenuItem
                                            key={index}
                                            className={isBold ? classes.boldFilter : ''}
                                            value={option}
                                        >
                                            {option === -1 ? 'None' : `${option} Months`}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </Form.Group>
                    </div>
                    <Form.Group as={Col}>
                        <Form.Label className={classes.subLabel}>Year</Form.Label>
                        <Select
                            disabled={vehiclesLoading || !record.detail.year}
                            multiple
                            value={filters.year || [0]}
                            renderValue={renderFilterValues}
                            variant="outlined"
                            margin="dense"
                            name="year"
                            onChange={({ target: { name, value } }) => (!vehiclesLoading ? applyFilter(name, value) : null)}
                        >
                            {yearOptions}
                            <MenuItem>
                                <Button
                                    disabled={vehiclesLoading}
                                    className={classes.containedSecondaryInfo}
                                    fullWidth
                                    size="small"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        updateYearsFilter();
                                    }}
                                >
                                    Apply
                                </Button>
                            </MenuItem>
                        </Select>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label className={classes.subLabel}>Trim</Form.Label>
                        <Select
                            displayEmpty
                            disabled={vehiclesLoading || filterOptions.trim.length === 0}
                            multiple
                            value={filters.trim || []}
                            renderValue={(selected) => renderFilterValues(selected, filterOptions.trim)}
                            variant="outlined"
                            margin="dense"
                            name="trim"
                            onChange={({ target: { name, value } }) => applyFilter(name, value)}
                        >
                            {buildFilterOptions('trim', filters.trim, filterOptions.trim)}
                        </Select>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label className={classes.subLabel}>Engine</Form.Label>
                        <Select
                            displayEmpty
                            disabled={vehiclesLoading || filterOptions.engine.length === 0}
                            multiple
                            value={filters.engine || []}
                            renderValue={(selected) => renderFilterValues(selected, filterOptions.engine)}
                            variant="outlined"
                            margin="dense"
                            name="engine"
                            onChange={({ target: { name, value } }) => applyFilter(name, value)}
                        >
                            {buildFilterOptions('engine', filters.engine, filterOptions.engine)}
                        </Select>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label className={classes.subLabel}>Transmission</Form.Label>
                        <Select
                            displayEmpty
                            disabled={vehiclesLoading || filterOptions.transmission.length === 0}
                            multiple
                            value={filters.transmission || []}
                            renderValue={(selected) => renderFilterValues(selected, filterOptions.transmission)}
                            variant="outlined"
                            margin="dense"
                            name="transmission"
                            onChange={({ target: { name, value } }) => applyFilter(name, value)}
                        >
                            {buildFilterOptions('transmission', filters.transmission, filterOptions.transmission)}
                        </Select>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label className={classes.subLabel}>Drivetrain</Form.Label>
                        <Select
                            displayEmpty
                            disabled={vehiclesLoading || filterOptions.drivetrain.length === 0}
                            multiple
                            value={filters.drivetrain || []}
                            renderValue={(selected) => renderFilterValues(selected, filterOptions.drivetrain)}
                            variant="outlined"
                            margin="dense"
                            name="drivetrain"
                            onChange={({ target: { name, value } }) => applyFilter(name, value)}
                        >
                            {buildFilterOptions('drivetrain', filters.drivetrain, filterOptions.drivetrain)}
                        </Select>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label className={classes.subLabel}>Body Style</Form.Label>
                        <Select
                            displayEmpty
                            disabled={vehiclesLoading || filterOptions.bodyStyle.length === 0}
                            multiple
                            value={filters.bodyStyle || []}
                            renderValue={(selected) => renderFilterValues(selected, filterOptions.bodyStyle)}
                            variant="outlined"
                            margin="dense"
                            name="bodyStyle"
                            onChange={({ target: { name, value } }) => applyFilter(name, value)}
                        >
                            {buildFilterOptions('bodyStyle', filters.bodyStyle, filterOptions.bodyStyle)}
                        </Select>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label className={classes.subLabel}>Sellers</Form.Label>
                        <Select
                            displayEmpty
                            disabled={vehiclesLoading || filterOptions.sellers.length === 0}
                            multiple
                            value={filters.sellers || []}
                            renderValue={(selected) => renderFilterValues(selected, filterOptions.sellers)}
                            variant="outlined"
                            margin="dense"
                            name="sellers"
                            onChange={({ target: { name, value } }) => applyFilter(name, value)}
                        >
                            {buildFilterOptions('sellers', filters.sellers, filterOptions.sellers)}
                        </Select>
                    </Form.Group>
                    {canWrite && (
                        <Button
                            disabled={vehiclesLoading}
                            className={classes.containedSecondaryInfo}
                            fullWidth
                            size="medium"
                            onClick={saveCriteria}
                        >
                            Save
                        </Button>
                    )}
                </div>
            </div>
            <div className={classes.results}>
                <div className={classes.resultsTitle}>
                    Vehicles in Market
                    <div className={classes.exporter}>
                        <Button
                            className={clsx(classes.containedSecondaryInfo, 'button-contained-info-with-icon-excel')}
                            disabled={vehiclesInMarket.length === 0}
                            size="small"
                            onClick={exportRecordsToExcel}
                            startIcon={<ExcelIcon />}
                        >
                            Export
                        </Button>
                    </div>
                </div>
                <div className={classes.tableContainer}>
                    <VirtualTable
                        ref={tableReference}
                        loading={false}
                        rowHeight={45}
                        totalRecords={filteredVehiclesInMarket.length}
                        data={filteredVehiclesInMarket}
                        columns={getColumns()}
                        sort={onSort}
                        sortBy={state.sort.field}
                        sortDirection={state.sort.dir}
                        filters={state.tableFilters}
                        loadFilterValues={onLoadFilterValues}
                        resetFilters={onResetFilters}
                        applyFilters={onApplyFilters}
                        columnsData={state.columnsData}
                        rowStyleFormat={rowStyleFormat}
                    />
                </div>
            </div>
        </div>
    );
};

MarketCompsTab.propTypes = {
    record: PropTypes.object.isRequired,
    canWrite: PropTypes.bool.isRequired,
    setVehicleDetail: PropTypes.func,
    isAppraisals: PropTypes.bool,
    setHeaderValues: PropTypes.func,
    headerValues: PropTypes.object,
};

MarketCompsTab.defaultProps = {
    isAppraisals: false,
    setVehicleDetail: () => null,
    setHeaderValues: () => null,
    headerValues: {},
};

export default MarketCompsTab;
