import { gql } from '@apollo/client';

export default class ServiceMutation {
static CREATE_SERVICE_INVOICE = gql`
mutation createServiceInvoice($record: ServiceInvoiceInput!) {
  createServiceInvoice(record: $record){
    success
    errorCode
    errorMessage
    record {
      invoiceNumber
      customerCode
      vehicleStock
      vehicleCustomStock
      milesIn
      milesOut
      hatNumber
      vehicleVin
      vehicleYear
      vehicleMake
      vehicleModel
      vehicleTrim
      lotName
      advisor
      saveParts
      deposit
      warranty
      warrantyDeductable
      fees
      shopSupplies
      cancellationFee
      storageFee
      laborDiscount
      discountPercentage
      discount
      quickNote
      status
      totalParts
      totalLabor
      total
      lotId
      totalSubletPrice
      advisorId
      lotId
      licensePlate
      vehicleMiles
      vehicleStyle
      vehicleColor
      roCompleted
      tag
      flag
      inHouse
      isTaxable
      roClosed
      roOpen
      vehicleEngine
      tax
      inspectionDone
      rentalCar
      estimateDelivery
      hashRecord
      userField1
      customerWaiting
      customerPay
      surplusSalesTax
      overrideShopSuppliesFee
      customer {
        customerCode
        firstName
        lastName
        address
      }
      jobs {
        invoiceNumber
        jobNumber
        technician
        payType
        hours
        laborCost
        laborTotal
        subletCost
        partsTotal
        subletPrice
        subletInvoice
        subletVendorId
        isHrsChecked
        approved
        status
        discount
        customerStates
        cause
        correction
        notes
        estimatedHours
        actualHours
        recommend1
        laborOperationCode
        technicianImage
        isSublet
        serviceJobId
        technicianId
        flaggedHours
        overrideSubletPrice
        vehicleInspectionItemIds
        payTypeId
        jobTypeId
        jobTypeDescription
        jobDescription
        warrantyCovered
        internalCovered
        customerPay
        customerTax
        partsTax
        laborTax
        parts {
          serviceJobPartsId
          invoiceNumber
          jobNumber
          partStockNumber
          description
          quantity
          listPrice
          netPrice
          total
          partCost
          partInventoryId
          isTaxable
          partTaxAmount
        }
      }
      }
    }
}`

static UPDATE_SERVICE_INVOICE = gql`
mutation updateServiceInvoice($record: ServiceInvoiceInput!) {
  updateServiceInvoice(record: $record){
    success
    errorCode
    errorMessage
    record {
      invoiceNumber
      customerCode
      vehicleStock
      vehicleCustomStock
      milesIn
      milesOut
      hatNumber
      vehicleVin
      vehicleYear
      vehicleMake
      vehicleModel
      vehicleTrim
      lotName
      advisor
      saveParts
      deposit
      warranty
      warrantyDeductable
      fees
      shopSupplies
      cancellationFee
      storageFee
      laborDiscount
      discountPercentage
      discount
      quickNote
      status
      totalParts
      totalLabor
      total
      lotId
      totalSubletPrice
      advisorId
      lotId
      licensePlate
      vehicleMiles
      vehicleStyle
      vehicleColor
      roCompleted
      tag
      flag
      inHouse
      isTaxable
      roClosed
      roOpen
      vehicleEngine
      tax
      inspectionDone
      rentalCar
      estimateDelivery
      hashRecord
      userField1
      customerWaiting
      customerPay
      surplusSalesTax
      overrideShopSuppliesFee
      customer {
        customerCode
        firstName
        lastName
        address
      }
      jobs {
        invoiceNumber
        jobNumber
        technician
        payType
        hours
        laborCost
        laborTotal
        subletCost
        partsTotal
        subletPrice
        subletInvoice
        subletVendorId
        isHrsChecked
        approved
        status
        discount
        customerStates
        cause
        correction
        notes
        estimatedHours
        actualHours
        recommend1
        laborOperationCode
        technicianImage
        isSublet
        serviceJobId
        technicianId
        flaggedHours
        overrideSubletPrice
        vehicleInspectionItemIds
        payTypeId
        jobTypeId
        jobTypeDescription
        jobDescription
        flaggedHoursPaid
        flaggedHoursApprovedOn
        technicianFunded
        technicianFundedApprovedOn
        warrantyCovered
        internalCovered
        customerPay
        customerTax
        partsTax
        laborTax
        parts {
          serviceJobPartsId
          invoiceNumber
          jobNumber
          partStockNumber
          description
          quantity
          listPrice
          netPrice
          total
          partCost
          partInventoryId
          isTaxable
          partTaxAmount
        }
      }
      }
    }
}`

static CHANGE_STATUS_SERVICE_INVOICES = gql`
mutation changeServiceInvoiceStatus($id: Int!, $status: String!, $milesOut: Int) {
  changeServiceInvoiceStatus(id: $id, status: $status, milesOut: $milesOut)
}`;

static SAVE_DEFAULT_PARTS_SETTINGS = gql`
  mutation saveDefaultPartsSettings($record: PartsSettingsInput!) {
    saveDefaultPartsSettings(record: $record)
  }
`;

static UPLOAD_RECON_ITEM_IMAGE = gql`
  mutation uploadReconItemImage($stockNumber: Int!, $file: Upload!) {
    uploadReconItemImage(stockNumber: $stockNumber, file: $file) {
          url
          key
          bucket
      }
  }
`;

static PRINT_SERVICE_INVOICES = gql`
mutation printServiceInvoices($ids: [Int!]!, $documentType: String) {
  printServiceInvoices(ids: $ids, documentType: $documentType)
}`;

static CHANGE_JOB_SERVICE_STATUS = gql`
mutation chagenJobServiceStatus($jobNumber: Int!, $invoiceNumber: Int!, $status: String!) {
  chagenJobServiceStatus(jobNumber: $jobNumber, invoiceNumber: $invoiceNumber, status: $status)
}`;

static UPDATE_SERVICE_TECHNICIAN_JOB = gql`
  mutation updateTechnicianJob(
    $serviceJobId: ID!,
    $status: String!,
    $cause: String,
    $correction: String,
    $recomendations: String,
    $requestedParts: String
  ) {
    updateTechnicianJob(
      serviceJobId: $serviceJobId,
      status: $status,
      cause: $cause,
      correction: $correction,
      recomendations: $recomendations,
      requestedParts: $requestedParts
    )
  }`;

static DELETE_OP_CODE = gql`
mutation deleteOperationCode($id: Int!) {
  deleteOperationCode(id: $id)
}`;

static PRINT_INSPECTION_LIST = gql`
mutation printInspectionList($invoiceNumber: Int, $stockNumber: Int) {
  printInspectionList(invoiceNumber: $invoiceNumber, stockNumber: $stockNumber)
}`;

static CREATE_OPERATION_CODE = gql`
  mutation createOperationCode($record: OpCodesInput!) {
    createOperationCode(record: $record)
  }
`

static UPDATE_OPERATION_CODE = gql`
  mutation updateOperationCode($record: OpCodesInput!) {
    updateOperationCode(record: $record)
  }
`
}
