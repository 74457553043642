import React, { Component } from 'react';

// Components and Others
import { isFinite } from 'lodash';
import PropTypes from 'prop-types';
import Employment from 'components/modules/deals/read/buyer/Employment';
import PaperAddInformation from 'components/widgets/form/PaperAddInformation';
import CustomerDialog from 'components/modules/customer/read/CustomerDialog';
import EditCustomerDialog from 'components/widgets/customer/EditCustomerDialog';
import BasicBuyerInformation from 'components/widgets/customer/BasicBuyerInformation';
import BasicCoBuyerInformation from 'components/widgets/customer/BasicCoBuyerInformation';
import InternalBuyerContainer from 'components/containers/deals/read/buyer/InternalBuyerContainer';
import If from 'components/widgets/conditional/If';

import { CustomerType, DealStatus } from 'utils/enum/DealEnum';

// Material UI
import { Grid, Button } from '@material-ui/core';
import CompareArrows from '@material-ui/icons/CompareArrows';
import { withStyles } from '@material-ui/core/styles';
import Address from 'components/modules/deals/read/buyer/Address';

// Utils
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import DealUtils from 'utils/DealUtils';
import DateUtils from 'lib/DateUtils';

const styles = (theme) => ({
    root: {
        margin: 0,
        width: '100%',
        overflow: 'auto',
        background: theme.palette.background.default,
    },
    centerPanel: {
        textAlign: 'center',
    },
});

class InternalBuyerTab extends Component {
    constructor(props) {
        super(props);
        const keyStore = new KeyStore();
        this.SALES_DEAL_CUSTOMER_WRITE = keyStore.hasPermission(Permission.SALES_DEAL_CUSTOMER_WRITE);
    }

    componentDidMount() {
        const { getServicesData, subscribeEditBuyer } = this.props;
        subscribeEditBuyer();
        getServicesData();
    }

    componentWillUnmount() {
        const { unsubscribeEditBuyer } = this.props;

        unsubscribeEditBuyer();
    }

    renderCoBuyerInformation() {
        const {
            props: {
                editingMode, onChangeEditingMode, accountNumber, coBuyer, coBuyerAddress, coBuyerEmployments,
                clientId, deal, toggleEditCustomer, removeCoBuyer, loadingRequest,
            },
        } = this;

        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <BasicCoBuyerInformation
                        title="Co Buyer"
                        loading={loadingRequest}
                        clientId={clientId}
                        dealId={accountNumber}
                        editingMode={editingMode}
                        postedDate={deal.postedDate}
                        onChangeEditingMode={onChangeEditingMode}
                        onEdit={toggleEditCustomer}
                        onDelete={removeCoBuyer}
                        {...coBuyer}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Address
                        clientId={clientId}
                        editingMode={editingMode}
                        deal={deal}
                        buyerAddress={coBuyerAddress}
                        customerCurrentAddressId={coBuyer.currentAddressId}
                        accountNumber={accountNumber}
                        customerId={coBuyer.customerId}
                        onChangeEditingMode={onChangeEditingMode}
                        type={CustomerType.CO_BUYER}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Employment
                        clientId={clientId}
                        editingMode={editingMode}
                        postedDate={deal.postedDate}
                        type={CustomerType.CO_BUYER}
                        accountNumber={accountNumber}
                        customerId={coBuyer.customerId}
                        employments={coBuyerEmployments}
                        onChangeEditingMode={onChangeEditingMode}
                        dealCurrentEmploymentId={coBuyer.currentEmploymentId}
                    />
                </Grid>
            </Grid>
        );
    }

    renderCoBuyerSection() {
        const {
            loading, coBuyer, onOpenCustomer, clientId,
        } = this.props;

        if (loading) return null;

        if (isFinite(coBuyer.customerCode)) return this.renderCoBuyerInformation();

        return DealUtils.clientIdIsWeb(clientId) ? <PaperAddInformation label="Add Co Buyer" onClickLeftLabel={onOpenCustomer} /> : null;
    }

    render() {
        const {
            props: {
                classes, buyerAddress, buyer, coBuyer, onCloseCustomer, clientId, loadingRequest,
                accountNumber, buyerEmployments, editingMode, onChangeEditingMode, recordToEdit, editingStatus,
                openCustomer, onDoubleClick, deal, loading, toggleEditCustomer, openEditCustomer, swapBuyerCoBuyer,
            },
        } = this;
        const { status, postedDate } = deal;
        const allowEdit = postedDate === null && (status === DealStatus.QUOTE || status === DealStatus.FNI || status === DealStatus.DELETED);
        const { isEditing } = editingStatus;
        const isNotPosted = !DateUtils.isValid(deal.postedDate);

        return (
            <Grid container spacing={3} className={classes.root}>
                <Grid item xs={5}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <BasicBuyerInformation
                                title="Buyer"
                                clientId={clientId}
                                dealId={accountNumber}
                                editingMode={editingMode}
                                editingStatus={editingStatus}
                                onChangeEditingMode={onChangeEditingMode}
                                deal={deal}
                                onEdit={toggleEditCustomer}
                                {...buyer}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Address
                                loading={loading}
                                clientId={clientId}
                                deal={deal}
                                editingMode={editingMode}
                                buyerAddress={buyerAddress}
                                editingStatus={editingStatus}
                                customerCurrentAddressId={buyer.currentAddressId}
                                accountNumber={accountNumber}
                                customerId={buyer.customerId}
                                onChangeEditingMode={onChangeEditingMode}
                                type={CustomerType.BUYER}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Employment
                                loading={loading}
                                clientId={clientId}
                                editingMode={editingMode}
                                type={CustomerType.BUYER}
                                postedDate={deal.postedDate}
                                accountNumber={accountNumber}
                                customerId={buyer.customerId}
                                editingStatus={editingStatus}
                                employments={buyerEmployments}
                                onChangeEditingMode={onChangeEditingMode}
                                dealCurrentEmploymentId={buyer.currentEmploymentId}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={2} className={classes.centerPanel}>
                    <If condition={
                        isFinite(coBuyer.customerCode)
                            && DealUtils.clientIdIsWeb(clientId)
                            && isNotPosted
                            && allowEdit
                            && this.SALES_DEAL_CUSTOMER_WRITE
                    }
                    >
                        <Button
                            startIcon={<CompareArrows />}
                            onClick={swapBuyerCoBuyer}
                            disabled={loadingRequest || isEditing}
                        >
                            Swap
                        </Button>
                    </If>
                </Grid>
                <Grid item xs={5}>
                    {this.renderCoBuyerSection()}
                </Grid>
                {openCustomer && !isEditing && <CustomerDialog open={openCustomer} toggleModal={onCloseCustomer} onSelectRow={onDoubleClick} />}
                {openEditCustomer && !isEditing && <EditCustomerDialog open={openEditCustomer} record={recordToEdit} toggleModal={toggleEditCustomer} />}
            </Grid>
        );
    }
}

InternalBuyerTab.propTypes = {
    buyer: PropTypes.shape({
        customerId: PropTypes.string,
        dob: PropTypes.string,
        ssn: PropTypes.string,
        dln: PropTypes.string,
        email: PropTypes.string,
        title: PropTypes.string,
        lastName: PropTypes.string,
        cellPhone: PropTypes.string,
        homePhone: PropTypes.string,
        workPhone: PropTypes.string,
        firstName: PropTypes.string,
        currentAddressId: PropTypes.string,
        currentEmploymentId: PropTypes.string,
        isBusiness: PropTypes.bool,
    }).isRequired,
    buyerAddress: PropTypes.arrayOf(
        PropTypes.shape({
            address1: PropTypes.string,
            address2: PropTypes.string,
            city: PropTypes.string,
            state: PropTypes.string,
            zipCode: PropTypes.string,
            county: PropTypes.string,
            country: PropTypes.string,
            phone: PropTypes.string,
            housingStatus: PropTypes.string,
            mortgageOrRent: PropTypes.number,
            totalMonths: PropTypes.number,
        }),
    ).isRequired,
    buyerEmployments: PropTypes.arrayOf(
        PropTypes.shape({
            dealPreviousEmploymentId: PropTypes.string,
            customerEmploymentId: PropTypes.string,
            status: PropTypes.string,
            employer: PropTypes.string,
            title: PropTypes.string,
            totalMonths: PropTypes.number,
            monthlyIncome: PropTypes.number,
            workPhone: PropTypes.string,
            otherIncomeMonthly: PropTypes.number,
            otherIncomeSource: PropTypes.string,
            address1: PropTypes.string,
            address2: PropTypes.string,
            city: PropTypes.string,
            state: PropTypes.string,
            zipCode: PropTypes.string,
            county: PropTypes.string,
            country: PropTypes.string,
            isCurrentEmployment: PropTypes.bool.isRequired,
        }),
    ).isRequired,
    coBuyer: PropTypes.shape({
        customerCode: PropTypes.number,
        customerId: PropTypes.string,
        dob: PropTypes.string,
        ssn: PropTypes.string,
        dln: PropTypes.string,
        email: PropTypes.string,
        title: PropTypes.string,
        lastName: PropTypes.string,
        cellPhone: PropTypes.string,
        homePhone: PropTypes.string,
        workPhone: PropTypes.string,
        firstName: PropTypes.string,
        currentAddressId: PropTypes.string,
        currentEmploymentId: PropTypes.string,
        isBusiness: PropTypes.bool,
    }).isRequired,
    coBuyerAddress: PropTypes.arrayOf(
        PropTypes.shape({
            address1: PropTypes.string,
            address2: PropTypes.string,
            city: PropTypes.string,
            state: PropTypes.string,
            zipCode: PropTypes.string,
            county: PropTypes.string,
            country: PropTypes.string,
            phone: PropTypes.string,
            housingStatus: PropTypes.string,
            mortgageOrRent: PropTypes.number,
            totalMonths: PropTypes.number,
        }),
    ).isRequired,
    coBuyerEmployments: PropTypes.arrayOf(
        PropTypes.shape({
            dealPreviousEmploymentId: PropTypes.string,
            customerEmploymentId: PropTypes.string,
            status: PropTypes.string,
            employer: PropTypes.string,
            title: PropTypes.string,
            totalMonths: PropTypes.number,
            monthlyIncome: PropTypes.number,
            workPhone: PropTypes.string,
            otherIncomeMonthly: PropTypes.number,
            otherIncomeSource: PropTypes.string,
            address1: PropTypes.string,
            address2: PropTypes.string,
            city: PropTypes.string,
            state: PropTypes.string,
            zipCode: PropTypes.string,
            county: PropTypes.string,
            country: PropTypes.string,
            isCurrentEmployment: PropTypes.bool.isRequired,
        }),
    ).isRequired,
    accountNumber: PropTypes.number.isRequired,
    getServicesData: PropTypes.func.isRequired,
    openAddressHistory: PropTypes.bool.isRequired,
    onOpenAddressHistory: PropTypes.func.isRequired,
    onCloseAddressHistory: PropTypes.func.isRequired,
    subscribeEditBuyer: PropTypes.func.isRequired,
    unsubscribeEditBuyer: PropTypes.func.isRequired,
    classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
    editingMode: PropTypes.shape({
        isEditing: PropTypes.bool,
        sectionName: PropTypes.string,
    }).isRequired,
    onChangeEditingMode: PropTypes.func.isRequired,
    onOpenCustomer: PropTypes.func.isRequired,
    onCloseCustomer: PropTypes.func.isRequired,
    openCustomer: PropTypes.bool.isRequired,
    onDoubleClick: PropTypes.func.isRequired,
    toggleEditCustomer: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    deal: PropTypes.object,
    clientId: PropTypes.string.isRequired,
    recordToEdit: PropTypes.object,
    openEditCustomer: PropTypes.bool.isRequired,
    removeCoBuyer: PropTypes.func.isRequired,
    swapBuyerCoBuyer: PropTypes.func.isRequired,
    loadingRequest: PropTypes.bool.isRequired,
    editingStatus: PropTypes.object,
};

InternalBuyerTab.defaultProps = {
    deal: {},
    recordToEdit: null,
    editingStatus: {
        isEditing: false,
        editingById: null,
        editingBy: null,
    },
};

export default withStyles(styles)(InternalBuyerContainer(InternalBuyerTab));
