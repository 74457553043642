import { gql } from '@apollo/client';

export default class InventoryMutation {
    static UPDATE_VEHICLE = gql`
        mutation updateVehicle($stockNumber: Int!, $input: InventoryInput!) {
            updateVehicle(stockNumber: $stockNumber, input: $input)
        }
    `;

    static UPDATE_VEHICLE_QUICKNOTE = gql`
        mutation updateVehicleQuickNote(
            $stockNumber: Int!
            $quickNote: String
        ) {
            updateVehicleQuickNote(
                stockNumber: $stockNumber
                quickNote: $quickNote
            )
        }
    `;

    static UPDATE_VEHICLE_LOCATION = gql`
        mutation updateVehicleLocation($stockNumber: Int!, $location: String!) {
            updateVehicleLocation(
                stockNumber: $stockNumber
                location: $location
            )
        }
    `;

    static CREATE_EQUIPMENT = gql`
        mutation createEquipment(
            $stockNumber: Int!
            $trim: String
            $decodedStyle: String!
            $standardEquipment: [EquipmentInput!]!
            $optionalEquipment: [EquipmentInput!]!
            $additionalSpec: [AdditionalSpecInput]!
            $isEditing: Boolean
        ) {
            createEquipment(
                stockNumber: $stockNumber
                trim: $trim
                decodedStyle: $decodedStyle
                standardEquipment: $standardEquipment
                optionalEquipment: $optionalEquipment
                additionalSpec: $additionalSpec
                isEditing: $isEditing
            )
        }
    `;

    static SET_VEHICLE_FEATURES = gql`
        mutation setVehicleFeatures($stockNumber: Int!, $features: [Int]!) {
            setVehicleFeatures(stockNumber: $stockNumber, features: $features)
        }
    `;

    static CREATE_FEATURE = gql`
        mutation createFeature($input: NewKeyFeatureInput!) {
            createFeature(input: $input)
        }
    `;

    static EDIT_FEATURE = gql`
        mutation editFeature($optionId: Int!, $input: EditKeyFeatureInput!) {
            editFeature(optionId: $optionId, input: $input)
        }
    `;

    static DELETE_FEATURE = gql`
        mutation deleteFeature($optionId: Int!) {
            deleteFeature(optionId: $optionId)
        }
    `;

    static UPLOAD_ICON = gql`
        mutation uploadIcon($file: Upload!) {
            uploadIcon(file: $file) {
                url
                key
                bucket
            }
        }
    `;

    static DELETE_ICON = gql`
        mutation deleteIcon($key: String!) {
            deleteIcon(key: $key)
        }
    `;

    static SAVE_VEHICLE_DESCRIPTION = gql`
        mutation saveVehicleDescription($stockNumber: Int!, $description: String!) {
            saveVehicleDescription(stockNumber: $stockNumber, description: $description)
        }
    `;

    static UPLOAD_VEHICLE_PHOTO = gql`
        mutation uploadVehiclePhoto($stockNumber: Int!, $file: Upload!) {
            uploadVehiclePhoto(stockNumber: $stockNumber, file: $file) {
                url
                key
                bucket
            }
        }
    `;

    static REMOVE_VEHICLE_PHOTOS = gql`
        mutation removeVehiclePhotos($stockNumber: Int!, $paths: [String!]!) {
            removeVehiclePhotos(stockNumber: $stockNumber, paths: $paths)
        }
    `;

    static SET_VEHICLE_THUMBNAIL = gql`
        mutation setVehicleThumbnail($stockNumber: Int!, $path: String!) {
            setVehicleThumbnail(stockNumber: $stockNumber, path: $path) {
                success
                thumbnail
            }
        }
    `;

    static REARRANGE_VEHICLE_IMAGES = gql`
        mutation rearrangeVehicleImages($stockNumber: Int!, $images: [String], $thumbnail: String) {
            rearrangeVehicleImages(stockNumber: $stockNumber, images: $images, thumbnail: $thumbnail)
        }
    `;

    static SAVE_VEHICLE_CUSTOM_FIELDS = gql`
        mutation saveCustomFieldInventory(
            $stockNumber: String!
            $input: [CustomFieldInput]!
        ) {
            saveCustomFieldInventory(stockNumber: $stockNumber, input: $input)
        }
    `;

    static CREATE_METADATA_OPTION = gql`
        mutation createMetadataOption(
            $entityMetadataId: String!
            $option: String!
        ) {
            createMetadataOption(
                entityMetadataId: $entityMetadataId
                option: $option
            ) {
                entityMetadataOptionId
                option
                created
            }
        }
    `;

    static UPDATE_METADATA_OPTION = gql`
        mutation updateMetadataOption(
            $entityMetadataOptionId: String!
            $option: String!
        ) {
            updateMetadataOption(
                entityMetadataOptionId: $entityMetadataOptionId
                option: $option
            )
        }
    `;

    static DELETE_METADATA_OPTION = gql`
        mutation deleteMetadataOption($entityMetadataOptionId: String!) {
            deleteMetadataOption(
                entityMetadataOptionId: $entityMetadataOptionId
            )
        }
    `;

    static SAVE_INVENTORY_METADATA = gql`
        mutation saveInventoryMetadata($input: [EntityMetadataInput]!) {
            saveInventoryMetadata(input: $input)
        }
    `;

    static SAVE_INVENTORY_SETTINGS = gql`
        mutation saveInventorySettings($input: [settingInput]!) {
            saveInventorySettings(input: $input)
        }
    `;

    static PRINT_SERVICE_INVOICE = gql`
        mutation printServiceInvoice($inventoryRepairId: Int!) {
            printServiceInvoice(inventoryRepairId: $inventoryRepairId)
        }
    `;

    static ADD_INSPECTION_CATEGORY = gql`
        mutation addInspectionCategory($input: InspectionCategoryInput!) {
            addInspectionCategory(input: $input)
        }
    `;

    static SAVE_INSPECTION_CATEGORY = gql`
        mutation saveInspectionCategory($input: InspectionCategoryUpdateInput!) {
            saveInspectionCategory(input: $input)
        }
    `;

    static ADD_INSPECTION_ITEM = gql`
        mutation addInspectionItem($input: InspectionItemInput!) {
            addInspectionItem(input: $input)
        }
    `;

    static SAVE_INSPECTION_ITEM = gql`
        mutation saveInspectionItem($input: InspectionItemUpdateInput!) {
            saveInspectionItem(input: $input)
        }
    `;

    static SAVE_RECON_DEFAULT_INSPECTOR = gql`
        mutation saveReconDefaultInspector($userId: Int!) {
            saveReconDefaultInspector(userId: $userId)
        }
    `;

    static SAVE_VEHICLE_RECON_ITEMS = gql`
        mutation saveVehicleReconItems($stockNumber: Int!, $items: [reconInspectionItem]!) {
            saveVehicleReconItems(stockNumber: $stockNumber, items: $items) {
                vehicleInspectionItemId
                reconInspectionItemId
            }
        }
    `;

    static SET_RECON_STATUS = gql`
        mutation setReconStatus(
            $stockNumber: Int!,
            $status: String!,
            $inspector: Int,
            $note: String,
            $maximumReconCost: Float,
            $reapproval: Boolean
        ) {
            setReconStatus(
                stockNumber: $stockNumber,
                status: $status,
                inspector: $inspector,
                note: $note,
                maximumReconCost: $maximumReconCost,
                reapproval: $reapproval
            )
        }
    `;

    static UPLOAD_VEHICLE_RECON_ITEM_IMAGE = gql`
        mutation uploadVehicleReconItemImage($stockNumber: Int!, $vehicleInspectionItemId: Int!, $file: Upload!) {
            uploadVehicleReconItemImage(stockNumber: $stockNumber, vehicleInspectionItemId: $vehicleInspectionItemId, file: $file) {
                url
                key
                bucket
            }
        }
    `;

    static SAVE_VEHICLE_RECON_ITEM_IMAGES = gql`
        mutation saveVehicleReconItemImages($vehicleInspectionItemId: Int!, $images: [String]!) {
            saveVehicleReconItemImages(vehicleInspectionItemId: $vehicleInspectionItemId, images: $images)
        }
    `;

    static REMOVE_VEHICLE_RECON_ITEM_IMAGES = gql`
        mutation removeVehicleReconItemImages($ids: [String!]!) {
            removeVehicleReconItemImages(ids: $ids)
        }
    `;

    static SAVE_VEHICLE_REPAIR_ITEM = gql`
        mutation saveVehicleRepairItem($input: repairItemInput!) {
            saveVehicleRepairItem(input: $input)
        }
    `;

    static SAVE_VEHICLE_REPAIR_ITEMS_ORDER = gql`
        mutation saveVehicleRepairItemsOrder($input: [repairItemOrderInput]!) {
            saveVehicleRepairItemsOrder(input: $input)
        }
    `;

    static UPDATE_PARTS_LIST_ITEM = gql`
        mutation updatePartsListItem($input: PartsListItemInput!) {
            updatePartsListItem(input: $input)
        }
    `;

    static SAVE_VEHICLE_MARKET_CRITERIA = gql`
        mutation createOrUpdateVehicleMarketCriteria(
            $input: MarketCompsFilterInput!
        ) {
            createOrUpdateVehicleMarketCriteria(input: $input)
        }
    `;

    static SAVE_APPRAISAL_DATA = gql`
        mutation saveAppraisalData(
            $input: AppraisalInput!
        ) {
            saveAppraisalData(input: $input) {
                appraisalListId
                customer {
                    customerId
                    cellPhone
                    email
                }
            }
        }
    `;

    static UPLOAD_APPRAISAL_IMAGE = gql`
        mutation uploadAppraisalImage(
            $appraisalListId: Int!,
            $file: Upload!
        ) {
            uploadAppraisalImage(appraisalListId: $appraisalListId, file: $file) {
                url
                key
                bucket
            }
        }
    `;

    static SAVE_APPRAISAL_IMAGES = gql`
        mutation saveAppraisalImages(
            $appraisalListId: Int!,
            $images: [String]!
        ) {
            saveAppraisalImages(appraisalListId: $appraisalListId, images: $images)
        }
    `;

    static REMOVE_APPRAISAL_IMAGES = gql`
        mutation removeAppraisalImages(
            $ids: [String!]!
        ) {
            removeAppraisalImages(ids: $ids)
        }
    `;

    static CREATE_DOCUMENTS = gql`
        mutation createDocuments(
            $stockNumber: Int!, $input: [InventoryDocumentInput!]!
        ) {
            createDocuments(stockNumber: $stockNumber, input: $input)
        }
    `;

    static EDIT_DOCUMENT_NAME = gql`
        mutation editDocumentName(
            $documentId: Int!, $name: String!
        ) {
            editDocumentName(documentId: $documentId, name: $name)
        }
    `;

    static DELETE_DOCUMENT = gql`
        mutation deleteDocument(
            $documentId: Int!
        ) {
            deleteDocument(documentId: $documentId)
        }
    `;

    static EDIT_SENTENCE_FOR_DESCRIPTION = gql`
        mutation editSentenceForDescription(
            $id: String,
            $sentence: String!,
            $order: Int!,
            $isParagraph: Boolean!,
            $active: Boolean
        ) {
            editSentenceForDescription(
                id: $id,
                sentence: $sentence,
                order: $order,
                isParagraph: $isParagraph,
                active: $active,
            )
        }
    `;

    static GENERATE_VEHICLE_DESCRIPTION = gql`
        mutation generateVehicleDescription(
            $stockNumber: Int!,
            $useAI: Boolean!,
            $threadId: String
        ) {
            generateVehicleDescription(stockNumber: $stockNumber, useAI: $useAI, threadId: $threadId) {
                content
                threadId
            }
        }
    `;

    static UNBLOCK_VEHICLE_RECORDS = gql`
        mutation unblockAllVehicleRecords(
            $stockNumber: Int
        ) {
            unblockAllVehicleRecords(stockNumber: $stockNumber)
        }
    `;

    static CHANGE_BLOCKING_STATUS = gql`
        mutation changeVehicleRecordBlockingStatus(
            $stockNumber: Int!,
            $isBlocking: Boolean!
        ) {
            changeVehicleRecordBlockingStatus(stockNumber: $stockNumber, isBlocking: $isBlocking)
        }
    `;

    static UPDATE_VEHICLE_MAKE = gql`
        mutation updateVehicleMake(
            $id: String,
            $name: String!,
            $active: Boolean
        ) {
            updateVehicleMake(id: $id, name: $name, active: $active)
        }
    `;

    static CREATE_VEHICLE = gql`
        mutation createVehicle($input: InventoryInput!) {
            createVehicle(input: $input)
        }
    `;

    static SAVE_VEHICLE_STYLE = gql`
        mutation saveVehicleStyle($id: Int, $name: String!, $active: Boolean) {
            saveVehicleStyle(id: $id, name: $name, active: $active)
        }
    `;
}
