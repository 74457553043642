import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
    List, ListItem, ListItemIcon, ListItemText,
} from '@material-ui/core';
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import { modules } from 'utils/enum/modules';
import { makeStyles } from '@material-ui/core/styles';
import SettingsStyles from 'styles/modules/settings/SettingsStyles';
import Container from 'components/widgets/Container';
import PlaidMappings from 'components/modules/settings/plaid/PlaidMappings';
import GpsSettings from 'components/modules/settings/integrations/GpsSettings';
import Underwriting from 'components/modules/settings/underwriting/Underwriting';
import PlaidSetting from 'components/modules/settings/integrations/PlaidSetting';
import CarFaxSetting from 'components/modules/settings/integrations/CarFaxSetting';
import SecureCloseSetting from 'components/modules/settings/integrations/SecureCloseSetting';

// icons
import AppsIcon from '@material-ui/icons/Apps';
import GpsFixedOutlinedIcon from '@material-ui/icons/GpsFixedOutlined';

const useStyles = makeStyles((theme) => SettingsStyles.settingsPanelItem(theme));

const getSettings = () => {
    const keyStore = new KeyStore();
    const settings = [];

    const INTEGRATIONS_SETTINGS_READ = keyStore.hasPermission(Permission.INTEGRATIONS_SETTINGS_READ);
    const INTEGRATIONS_SETTINGS_WRITE = keyStore.hasPermission(Permission.INTEGRATIONS_SETTINGS_WRITE);

    if (INTEGRATIONS_SETTINGS_READ) {
        const settingsConfig = [
            {
                index: 0,
                text: 'GPS',
                url: 'gps',
                icon: <GpsFixedOutlinedIcon />,
                component: <GpsSettings canWrite={INTEGRATIONS_SETTINGS_WRITE} />,
            },
            {
                index: 1,
                text: 'Underwriting',
                url: 'underwriting',
                icon: <AppsIcon />,
                component: <Underwriting />,
            },
            {
                index: 2,
                text: 'Plaid Mappings',
                url: 'plaid-mappings',
                icon: <AppsIcon />,
                component: <PlaidMappings />,
            },
            {
                index: 3,
                text: 'Plaid',
                url: 'plaid',
                icon: <AppsIcon />,
                component: <PlaidSetting />,
            },
            {
                index: 4,
                text: 'Secure Close',
                url: 'secure-close',
                icon: <AppsIcon />,
                component: <SecureCloseSetting />,
            },
            {
                index: 5,
                text: 'CarFax',
                url: 'car-fax',
                icon: <AppsIcon />,
                component: <CarFaxSetting />,
            },
        ];

        settings.push(...settingsConfig);
    }

    return settings.filter((setting) => setting !== null);
};

const IntegrationsSettings = () => {
    const classes = useStyles();
    const history = useHistory();
    const { maintab, subtab } = useParams();
    const settingsItems = getSettings();

    const getSelectedTab = (url) => {
        const tabIndex = settingsItems.findIndex((item) => item.url === url);
        return tabIndex !== -1 ? tabIndex : 0;
    };

    const [activeIndex, setActiveIndex] = useState(getSelectedTab(subtab || 0));

    const handleChange = (newIndex) => {
        history.push(`/${modules.SETTINGS}/${maintab}/${settingsItems[newIndex].url}`);
        setActiveIndex(newIndex);
    };

    return (
        <Container className={classes.rootContainer}>
            <List component="nav" className={classes.sideBar}>
                {settingsItems.map((item) => (
                    <ListItem key={item.index} onClick={() => handleChange(item.index)} button selected={activeIndex === item.index}>
                        <ListItemIcon>
                            {item.icon}
                        </ListItemIcon>
                        <ListItemText primary={item.text} />
                    </ListItem>
                ))}
            </List>
            <div className={classes.content}>
                {settingsItems.map((item) => (
                    activeIndex === item.index && <div key={item.index}>{item.component}</div>
                ))}
            </div>
        </Container>
    );
};

export default IntegrationsSettings;
