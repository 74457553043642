import React, { useEffect, useState, useContext } from 'react';
import {
    makeStyles, Button, Grid, Divider, Switch,
} from '@material-ui/core';
import { Col, Form } from 'react-bootstrap';

import { useMutation, useLazyQuery } from '@apollo/client';
import ServiceSettingsQuery from 'services/graphQL/query/service/ServiceSettings';
import ServiceSettingsMutation from 'services/graphQL/mutate/service/ServiceSettingsMutation';
import UserContext from 'components/context/UserContext';
import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';

import ModalUtils from 'utils/ModalUtils';
import { isValidField, isValidSchema } from 'utils/schema/utils';
import ServiceSettingsMappingSchema from 'utils/schema/service/ServiceSettingsMappingSchema';
import { FetchPolicy } from 'utils/enum/Core';
import DropdownQuery from 'components/widgets/DropdownQuery';
import LotQuery from 'services/graphQL/query/LotQuery';
import InputNumber from 'components/widgets/InputNumber';

const useStyles = makeStyles((theme) => ({
    box: {
        marginTop: '10px',
        paddingTop: '10px',
        border: `solid 1px ${theme.palette.border.mercury}`,
    },
    labels: {
        fontSize: '0.875rem',
    },
    divider: {
        marginTop: 20,
    },
    wrapperDate: {
        display: 'flex',
        flex: 1,
    },
    mainContent: {
        height: 'calc(100vh - 320px)',
        overflowY: 'auto',
        [theme.breakpoints.down('sm')]: {
            height: 'calc(100vh + 250px)',
        },
        '& .form-group': {
            marginBottom: '5px',
        },
    },
    chargeSupplyWidth: {
        width: '100px',
    },
    labelWidth: {
        width: '120px',
    },
}));

const ServiceDefaultSettings = () => {
    const classes = useStyles();
    const { defaultLot } = useContext(UserContext);

    const [record, setRecord] = useState(
        {
            lotName: defaultLot || null,
            customerPayHourlyRate: 0,
            customerSubletPriceMarkUp: 0,
            hourlyLaborRate: 0,
            internalSubletPriceMarkUp: 0,
            shopSuppliesLimit: 0,
            shopSuppliesPercent: 0,
            consentFormWording: '',
            invoiceDisclaimer: '',
            defaultFactoryWarranty: false,
            hideApproveDeclineButtons: false,
            multipleOpCodes: false,
            storageFee: 0,
            warrantyPartMarkUp: 0,
            minimumGP: 0,
            customTaxRateEnabled: false,
            customTaxRateValue: 0,
            allowAddInventoryPartsFromProcess: false,
            chargeSalesTaxASR: false,
            chargeSalesTaxOnWarrantyDeductable: false,
            chargeSalesTaxOnWarrantyParts: false,
            shopSuppliesType: 'All',
        },
    );

    const [getSettings] = useLazyQuery(ServiceSettingsQuery.GET_SERVICE_DEFAULT_SETTINGS, {
        onCompleted: (data) => {
            const { getServiceDefaultSettings } = data;
            setRecord((prev) => ({
                ...prev,
                ...getServiceDefaultSettings,
            }));
        },
        onError: (error) => {
            ModalUtils.errorMessage([error]);
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (defaultLot && !record.lotName) {
            setRecord((prev) => ({
                ...prev,
                lotName: defaultLot,
            }));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultLot]);

    useEffect(() => {
        if (record.lotName) {
            getSettings({
                variables: {
                    lotName: record.lotName,
                },
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [record.lotName]);

    const [saveData, { loading: updating }] = useMutation(ServiceSettingsMutation.SAVE_DEFAULT_SERVICE_SETTINGS, {
        onCompleted: (mutationData) => {
            if (mutationData?.saveDefaultServiceSettings) {
                ModalUtils.successMessage(null, 'saved successfully');
            }
        },
        onError: (mutationError) => {
            ModalUtils.errorMessage(null, mutationError);
        },
    });

    const onSave = () => {
        const { lotName, ...recordToCreate } = record;
        saveData({ variables: { input: { ...recordToCreate }, lotName } });
    };

    const isValidData = isValidSchema(ServiceSettingsMappingSchema, record);
    const { isValid, errors } = isValidData;

    const handleChange = (columnId, newValue) => {
        setRecord((item) => ({
            ...item,
            [columnId]: newValue,
        }));
    };

    return (
        <Grid container>
            <Grid item sm={12} md={12} className={classes.box}>
                <Grid container>
                    <Grid item xs={12} md={12}>
                        <Form.Group as={Col}>
                            <Button
                                variant="outlined"
                                startIcon={<SaveOutlinedIcon />}
                                size="small"
                                disabled={updating || !isValid}
                                onClick={onSave}
                            >
                                {updating ? 'Saving...' : 'Save'}
                                {updating && <CircularProgress size={20} />}
                            </Button>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label className={classes.labels}>Default Settings by Lot</Form.Label>
                            <DropdownQuery
                                name="lotName"
                                value={record.lotName}
                                placeholder="select a Lot"
                                onChange={handleChange}
                                allowEmptyLine={false}
                                className={isValidField(errors, 'lotName') ? 'invalid-field' : ''}
                                dataSource={{
                                    query: LotQuery.GET_LOTS, rootData: 'lotList', idField: 'lotName', descriptionField: 'lotName',
                                }}
                            />
                            <Divider className={classes.divider} />
                        </Form.Group>
                    </Grid>
                    <Grid container className={classes.mainContent}>
                        <Grid item xs={12} md={6}>
                            <Form.Group as={Col}>
                                <Form.Label className={classes.labels}>Consent from user wording</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    size="sm"
                                    rows={6}
                                    name="consentFormWording"
                                    value={record.consentFormWording}
                                    onChange={(event) => handleChange(event.target.name, event.target.value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label className={classes.labels}>Invoice user disclaimer</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    size="sm"
                                    rows={6}
                                    name="invoiceDisclaimer"
                                    value={record.invoiceDisclaimer}
                                    onChange={(event) => handleChange(event.target.name, event.target.value)}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label className={classes.labels}>Allow add inventory parts from process</Form.Label>
                                <Switch
                                    name="allowAddInventoryPartsFromProcess"
                                    checked={record.allowAddInventoryPartsFromProcess || false}
                                    color={record.allowAddInventoryPartsFromProcess ? 'primary' : 'secondary'}
                                    onChange={(e) => handleChange('allowAddInventoryPartsFromProcess', e.target.checked)}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label className={classes.labels}>Set factory warranty as default</Form.Label>
                                <Switch
                                    name="defaultFactoryWarranty"
                                    checked={record.defaultFactoryWarranty || false}
                                    color={record.defaultFactoryWarranty ? 'primary' : 'secondary'}
                                    onChange={(e) => handleChange('defaultFactoryWarranty', e.target.checked)}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label className={classes.labels}>Allow to add multiple Op-Codes for jobs</Form.Label>
                                <Switch
                                    name="multipleOpCodes"
                                    checked={record.multipleOpCodes || false}
                                    color={record.multipleOpCodes ? 'primary' : 'secondary'}
                                    onChange={(e) => handleChange('multipleOpCodes', e.target.checked)}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label className={classes.labels}>Hide approve and decline buttons</Form.Label>
                                <Switch
                                    name="hideApproveDeclineButtons"
                                    checked={record.hideApproveDeclineButtons || false}
                                    color={record.hideApproveDeclineButtons ? 'primary' : 'secondary'}
                                    onChange={(e) => handleChange('hideApproveDeclineButtons', e.target.checked)}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label className={classes.labels}>Custom Service Sales Tax Rate</Form.Label>
                                        <Switch
                                            name="customTaxRateEnabled"
                                            checked={record.customTaxRateEnabled || false}
                                            color={record.customTaxRateEnabled ? 'primary' : 'secondary'}
                                            onChange={(e) => handleChange('customTaxRateEnabled', e.target.checked)}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label className={classes.labels}>Tax Rate </Form.Label>
                                        <InputNumber
                                            value={record.customTaxRateValue}
                                            name="customTaxRateValue"
                                            onChange={(val) => handleChange('customTaxRateValue', val)}
                                            allowNegative={false}
                                            max={100}
                                            size="sm"
                                            disabled={!record.customTaxRateEnabled}
                                            suffix="%"
                                        />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label className={classes.labels}>Charge Sales Tax on Warranty Deductible</Form.Label>
                                        <Switch
                                            name="chargeSalesTaxOnWarrantyDeductable"
                                            checked={record.chargeSalesTaxOnWarrantyDeductable || false}
                                            color={record.chargeSalesTaxOnWarrantyDeductable ? 'primary' : 'secondary'}
                                            onChange={(e) => handleChange('chargeSalesTaxOnWarrantyDeductable', e.target.checked)}
                                        />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label className={classes.labels}>Charge Sales Tax on Warranty Parts</Form.Label>
                                        <Switch
                                            name="chargeSalesTaxOnWarrantyParts"
                                            checked={record.chargeSalesTaxOnWarrantyParts || false}
                                            color={record.chargeSalesTaxOnWarrantyParts ? 'primary' : 'secondary'}
                                            onChange={(e) => handleChange('chargeSalesTaxOnWarrantyParts', e.target.checked)}
                                        />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label className={classes.labels}>Charge sales tax on part after sales repairs (ASR)</Form.Label>
                                        <Switch
                                            name="chargeSalesTaxASR"
                                            checked={record.chargeSalesTaxASR || false}
                                            color={record.chargeSalesTaxASR ? 'primary' : 'secondary'}
                                            onChange={(e) => handleChange('chargeSalesTaxASR', e.target.checked)}
                                        />
                                    </Form.Group>
                                </Form.Row>
                            </Form.Group>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Form.Group as={Col}>
                                <Form.Label className={classes.labels}>Internal hourly rate</Form.Label>
                                <InputNumber
                                    value={record.hourlyLaborRate}
                                    name="hourlyLaborRate"
                                    onChange={(val) => handleChange('hourlyLaborRate', val)}
                                    showCurrency
                                    thousandSeparator
                                    allowNegative={false}
                                    size="sm"
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label className={classes.labels}>Customer pay hourly rate</Form.Label>
                                <InputNumber
                                    value={record.customerPayHourlyRate}
                                    name="customerPayHourlyRate"
                                    onChange={(val) => handleChange('customerPayHourlyRate', val)}
                                    showCurrency
                                    thousandSeparator
                                    allowNegative={false}
                                    size="sm"
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label className={classes.labels}>Default storage fee</Form.Label>
                                <InputNumber
                                    value={record.storageFee}
                                    name="storageFee"
                                    onChange={(val) => handleChange('storageFee', val)}
                                    allowNegative={false}
                                    showCurrency
                                    thousandSeparator
                                    size="sm"
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label className={classes.labels}>Internal RO markup Sublet and Parts price % at</Form.Label>
                                <InputNumber
                                    value={record.internalSubletPriceMarkUp}
                                    name="internalSubletPriceMarkUp"
                                    onChange={(val) => handleChange('internalSubletPriceMarkUp', val)}
                                    allowNegative={false}
                                    max={100}
                                    suffix="%"
                                    size="sm"
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label className={classes.labels}>Customer RO Markup Sublet Price % at</Form.Label>
                                <InputNumber
                                    value={record.customerSubletPriceMarkUp}
                                    name="customerSubletPriceMarkUp"
                                    onChange={(val) => handleChange('customerSubletPriceMarkUp', val)}
                                    allowNegative={false}
                                    max={100}
                                    suffix="%"
                                    size="sm"
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label className={classes.labels}>Charge warranty parts at (% on cost)</Form.Label>
                                <InputNumber
                                    value={record.warrantyPartMarkUp}
                                    name="warrantyPartMarkUp"
                                    onChange={(val) => handleChange('warrantyPartMarkUp', val)}
                                    allowNegative={false}
                                    max={100}
                                    suffix="%"
                                    size="sm"
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label className={classes.labels}>Minimum GP</Form.Label>
                                <InputNumber
                                    value={record.minimumGP}
                                    name="minimumGP"
                                    onChange={(val) => handleChange('minimumGP', val)}
                                    allowNegative={false}
                                    max={100}
                                    suffix="%"
                                    size="sm"
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label className={classes.labels}>Charge shop supplies on:</Form.Label>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <InputNumber
                                            value={record.shopSuppliesPercent}
                                            name="shopSuppliesPercent"
                                            onChange={(val) => handleChange('shopSuppliesPercent', val)}
                                            allowNegative={false}
                                            max={100}
                                            suffix="%"
                                            alignRight
                                            size="sm"
                                        />
                                    </Form.Group>
                                    <Form.Group className="text-center" as={Col}>
                                        <Form.Label className={classes.labels}>of</Form.Label>
                                    </Form.Group>
                                    <Form.Group>
                                        <DropdownQuery
                                            name="shopSuppliesType"
                                            value={record.shopSuppliesType}
                                            placeholder="select a type"
                                            onChange={handleChange}
                                            allowEmptyLine={false}
                                            className={clsx(isValidField(errors, 'shopSuppliesType') ? 'invalid-field' : '', classes.chargeSupplyWidth)}
                                            dataSource={{
                                                rootData: '',
                                                idField: 'id',
                                                descriptionField: 'name',
                                                localData: [
                                                    {
                                                        id: 'All',
                                                        name: 'All',
                                                    },
                                                    {
                                                        id: 'Labor',
                                                        name: 'Labor',
                                                    },
                                                    {
                                                        id: 'Parts',
                                                        name: 'Parts',
                                                    },
                                                ],
                                            }}
                                        />
                                    </Form.Group>
                                    <Form.Group className="text-center" as={Col}>
                                        <Form.Label className={clsx(classes.labels, classes.labelWidth)}>to a maximum of</Form.Label>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <InputNumber
                                            value={record.shopSuppliesLimit}
                                            name="shopSuppliesLimit"
                                            onChange={(val) => handleChange('shopSuppliesLimit', val)}
                                            allowNegative={false}
                                            fixedDecimalScale
                                            showCurrency
                                            size="sm"
                                        />
                                    </Form.Group>
                                </Form.Row>
                            </Form.Group>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
export default ServiceDefaultSettings;
