import React, { useEffect, useState, useContext } from 'react';
import {
    makeStyles, Button, Grid, Divider, Switch,
} from '@material-ui/core';
import { Col, Form } from 'react-bootstrap';

import { useMutation, useLazyQuery } from '@apollo/client';
import UserContext from 'components/context/UserContext';
import CircularProgress from '@material-ui/core/CircularProgress';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';

import ModalUtils from 'utils/ModalUtils';
import { isValidField, isValidSchema } from 'utils/schema/utils';
import { FetchPolicy } from 'utils/enum/Core';
import DropdownQuery from 'components/widgets/DropdownQuery';
import LotQuery from 'services/graphQL/query/LotQuery';
import InputNumber from 'components/widgets/InputNumber';
import PartsQuery from 'services/graphQL/query/parts/PartsQuery';
import PartsSettingsMutation from 'services/graphQL/mutate/parts/PartsSettingsMutation';
import PartsSettingsMappingSchema from 'utils/schema/parts/PartsSettingsMappingSchema';

const useStyles = makeStyles((theme) => ({
    box: {
        marginTop: '10px',
        paddingTop: '10px',
        border: `solid 1px ${theme.palette.border.mercury}`,
    },
    labels: {
        fontSize: '0.875rem',
    },
    divider: {
        marginTop: 20,
    },
    wrapperDate: {
        display: 'flex',
        flex: 1,
    },
    mainContent: {
        height: 'calc(100vh - 320px)',
        overflowY: 'auto',
        [theme.breakpoints.down('sm')]: {
            height: 'calc(100vh + 250px)',
        },
        '& .form-group': {
            marginBottom: '5px',
        },
    },
    chargeSupplyWidth: {
        width: '100px',
    },
    labelWidth: {
        width: '120px',
    },
}));

const PartsDefaultSettings = () => {
    const classes = useStyles();
    const { defaultLot } = useContext(UserContext);

    const [record, setRecord] = useState(
        {
            lotName: defaultLot || null,
            surplusTax: 0,
            customPartTaxRateEnabled: false,
            customPartTaxRateValue: 0,
        },
    );

    const [getSettings] = useLazyQuery(PartsQuery.GET_PARTS_DEFAULT_SETTINGS, {
        onCompleted: (data) => {
            const { getPartsDefaultSettings } = data;
            setRecord((prev) => ({
                ...prev,
                ...getPartsDefaultSettings,
            }));
        },
        onError: (error) => {
            ModalUtils.errorMessage([error]);
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (defaultLot && !record.lotName) {
            setRecord((prev) => ({
                ...prev,
                lotName: defaultLot,
            }));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultLot]);

    useEffect(() => {
        if (record.lotName) {
            getSettings({
                variables: {
                    lotName: record.lotName,
                },
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [record.lotName]);

    const [saveData, { loading: updating }] = useMutation(PartsSettingsMutation.SAVE_DEFAULT_SERVICE_SETTINGS, {
        onCompleted: (mutationData) => {
            if (mutationData?.savePartsSettings) {
                ModalUtils.successMessage(null, 'saved successfully');
            }
        },
        onError: (mutationError) => {
            ModalUtils.errorMessage(null, mutationError);
        },
    });

    const onSave = () => {
        const { lotName, surplusTax, ...recordToCreate } = record;
        saveData({ variables: { input: { ...recordToCreate }, lotName } });
    };

    const isValidData = isValidSchema(PartsSettingsMappingSchema, record);
    const { isValid, errors } = isValidData;

    const handleChange = (columnId, newValue) => {
        setRecord((item) => ({
            ...item,
            [columnId]: newValue,
        }));
    };

    return (
        <Grid container>
            <Grid item sm={12} md={12} className={classes.box}>
                <Grid container>
                    <Grid item xs={12} md={3}>
                        <Form.Group as={Col}>
                            <Button
                                variant="outlined"
                                startIcon={<SaveOutlinedIcon />}
                                size="small"
                                disabled={updating || !isValid}
                                onClick={onSave}
                            >
                                {updating ? 'Saving...' : 'Save'}
                                {updating && <CircularProgress size={20} />}
                            </Button>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label className={classes.labels}>Default Settings by Lot</Form.Label>
                            <DropdownQuery
                                name="lotName"
                                value={record.lotName}
                                placeholder="select a Lot"
                                onChange={handleChange}
                                allowEmptyLine={false}
                                className={isValidField(errors, 'lotName') ? 'invalid-field' : ''}
                                dataSource={{
                                    query: LotQuery.GET_LOTS, rootData: 'lotList', idField: 'lotName', descriptionField: 'lotName',
                                }}
                            />
                            <Divider className={classes.divider} />
                        </Form.Group>
                    </Grid>
                    <Grid container className={classes.mainContent}>
                        <Grid item xs={12} md={6}>
                            <Form.Group as={Col}>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label className={classes.labels}>Custom Parts Sales Tax Rate</Form.Label>
                                        <Switch
                                            name="customPartTaxRateEnabled"
                                            checked={record.customPartTaxRateEnabled || false}
                                            color={record.customPartTaxRateEnabled ? 'primary' : 'secondary'}
                                            onChange={(e) => handleChange('customPartTaxRateEnabled', e.target.checked)}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label className={classes.labels}>Tax Rate </Form.Label>
                                        <InputNumber
                                            value={record.customPartTaxRateValue}
                                            name="customPartTaxRateValue"
                                            onChange={(val) => handleChange('customPartTaxRateValue', val)}
                                            allowNegative={false}
                                            max={100}
                                            size="sm"
                                            disabled={!record.customPartTaxRateEnabled}
                                            suffix="%"
                                        />
                                    </Form.Group>
                                </Form.Row>
                            </Form.Group>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
export default PartsDefaultSettings;
