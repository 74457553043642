import React from 'react';
import {
    makeStyles, Switch, Chip,
    Tooltip,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {
    Col, Form, Row,
} from 'react-bootstrap';
import InputNumber from 'components/widgets/InputNumber';
import DropdownQuery from 'components/widgets/DropdownQuery';
import clsx from 'clsx';
import BasicStyles from 'styles/modules/basic/basicStyles';
import JournalStyles from 'styles/modules/accounting/JournalStyles';
import FieldsetTitle from 'components/widgets/fieldsetTitle/fieldsetTitle';
import { PAY_TYPE, PayTypeData } from 'utils/enum/ServiceInvoiceEnum';
import { EmployeeType } from 'utils/enum/UserEnum';
import KeyStore from 'utils/KeyStore';
import Permission from 'utils/enum/Permissions';
import { isValidField, isValidSchema } from 'utils/schema/utils';
import { ServiceJobsItemSchema } from 'utils/schema/service/ServiceJobsSchema';
import ModalUtils from 'utils/ModalUtils';

const useStyles = makeStyles((theme) => JournalStyles.journalDistributionStyles(theme));
const basicFormStyle = makeStyles((theme) => BasicStyles.basicForm(theme));
const ownStyles = makeStyles((theme) => ({
    summaryText: {
        display: 'grid',
        width: 200,
        paddingLeft: 5,
        gridTemplateColumns: 'repeat(2, 1fr)',
        overflow: 'auto',
    },
    summaryTextInLine: {
        display: 'flex',
        overflow: 'auto',
        padding: 5,
    },
    contentSpace: {
        marginRight: theme.spacing(2),
        textAlign: 'right',
    },
    difference: {
        color: theme.palette.error.main,
    },
    badgeRed: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.text.white,
    },
    badgeGreen: {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.text.white,
    },
    priceSpot: {
        flexDirection: 'row',
        display: 'flex',
        flex: 'initial',
        '& > div:nth-child(1)': {
            marginRight: '10px',
        },
    },
    priceOverride: {
        paddingLeft: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& > input': {
            margin: 0,
            position: 'initial',
        },
    },
    fundedLabel: {
        color: theme.palette.border.darkGreen,
    },
}));

const keyStore = new KeyStore();

const ServiceJobsHeader = (props) => {
    const {
        jobItem, onChangeJobValue, vendorList,
        userList, editDetails, recordFields,
    } = props;

    const SERVICE_JOB_LABOR_HOURS = keyStore.hasPermission(Permission.SERVICE_JOB_LABOR_HOURS);
    const SERVICE_JOBS_OVERRIDE_LABOR_RATE = keyStore.hasPermission(Permission.SERVICE_JOBS_OVERRIDE_LABOR_RATE);
    const SERVICE_JOBS_OVERRIDE_SUBLET_PRICE = keyStore.hasPermission(Permission.SERVICE_JOBS_OVERRIDE_SUBLET_PRICE);

    const classes = {
        ...useStyles(),
        ...basicFormStyle(),
        ...ownStyles(),
    };

    const {
        compactForm, paddingSides15, paddingTop15,
        badgeGreen, badgeRed,
    } = classes;

    const {
        hours, laborTotal,
        subletCost, subletPrice, subletInvoice, discount,
        flaggedHours, subletVendorId,
        jobNumber, overrideSubletPrice, approved,
        isSublet, isHrsChecked, technicianId, payType,
        technician, flaggedHoursPaid,
        flaggedHoursApprovedOn,
        technicianFundedApprovedOn,
        technicianFunded, isFlatRate,
    } = jobItem;

    const {
        editApproved,
        editIsSublet,
        editJobDiscount,
        editTechniccian,
        editPayType,
        editCostByHours,
        editSoldHours,
        editLaborAmount,
        editSubletVendor,
        editSubletCost,
        editSubletPrice,
        editOverrideSubletPrice,
        editFlaggedHours,
    } = editDetails;

    const { laborDiscount, discountPercentage, inHouse } = recordFields;

    const { errors } = isValidSchema(ServiceJobsItemSchema, jobItem);

    const technicianList = userList?.filter((item) => item.employeeType === EmployeeType.TECHNICIAN);

    const onChangeValue = (field, value) => {
        if (field === 'flaggedHours' && Number(value) > Number(hours)) {
            ModalUtils.errorMessage(null, `Flagged Hours could not be greater than ${Number(hours)}`);
        }

        onChangeJobValue(field, value);
        if (field === 'technicianId') {
            const technicianName = technicianList.find((c) => c.userId === value);
            onChangeJobValue('technician', technicianName?.user ?? '');
            onChangeJobValue('technicianImage', technicianName?.userPicture ?? 'https://images.automatrix.com/1/HR/ProfilePicture/employeeDefault.jpg');
        }
    };

    let currentTechnicianId = technicianId;
    if (!technicianId && technician) {
        currentTechnicianId = technicianList?.find((c) => c.user.toLowerCase() === technician.toLowerCase())?.userId;
    }

    const payTypeDataFiltered = inHouse ? PayTypeData.filter((c) => c.withCustomerPay === false || c.withCustomerPay === null)
        : PayTypeData.filter((c) => c.withCustomerPay === true || c.withCustomerPay === null);

    const technicianFundedApproved = technicianFundedApprovedOn ?? false;
    const displayFunded = payType === PAY_TYPE.CUSTOMER_PAY && isFlatRate && (technicianFundedApproved || technicianFunded);

    return (
        <Form as={Row} className={clsx(compactForm, paddingSides15, paddingTop15)}>
            <Col lg={3}>
                <FieldsetTitle title="">
                    <Form as={Row}>
                        <Col xs={12} sm={6} lg={12}>
                            <Form.Group as={Row}>
                                <Form.Label column lg={5}>Job Discount</Form.Label>
                                <Col lg={7}>
                                    <InputNumber
                                        size="sm"
                                        showCurrency
                                        allowNegative={false}
                                        value={discount}
                                        thousandSeparator
                                        placeholder="0"
                                        onChange={(value) => onChangeValue('discount', value)}
                                        alignRight
                                        disabled={!editJobDiscount || (laborDiscount && discountPercentage > 0)}
                                    />
                                </Col>
                            </Form.Group>
                        </Col>
                        <Col xs={12} sm={3} lg={12}>
                            <Form.Group as={Row}>
                                <Form.Label column lg={5}>
                                    {approved ? <Chip size="small" label="Approved" className={badgeGreen} />
                                        : <Chip size="small" label="Declined" className={badgeRed} />}

                                </Form.Label>
                                <Col lg={7}>
                                    <Switch
                                        checked={approved}
                                        color={approved ? 'primary' : 'secondary'}
                                        onChange={(e) => onChangeValue('approved', e.target.checked)}
                                        disabled={!editApproved || displayFunded}
                                    />
                                </Col>
                            </Form.Group>
                        </Col>
                        <Col xs={12} sm={3} lg={12}>
                            <Form.Group as={Row}>
                                <Form.Label column lg={5}>Is Sublet </Form.Label>
                                <Col lg={7}>
                                    <Switch
                                        checked={isSublet}
                                        color={isSublet ? 'primary' : 'secondary'}
                                        onChange={(e) => onChangeValue('isSublet', e.target.checked)}
                                        disabled={!editIsSublet || displayFunded}
                                    />
                                </Col>
                            </Form.Group>
                        </Col>
                    </Form>
                </FieldsetTitle>
            </Col>
            <Col lg={3}>
                <FieldsetTitle title="Techinician Info">
                    <Form as={Row}>
                        <Col xs={12} sm={6} lg={12}>
                            <Form.Group as={Row}>
                                <Form.Label column lg={4}>Pay Type</Form.Label>
                                <Col lg={8}>
                                    <DropdownQuery
                                        name="payType"
                                        value={payType}
                                        placeHolder="select a type"
                                        onChange={(name, newValue) => onChangeValue(name, newValue)}
                                        className={isValidField(errors, 'payType') ? 'invalid-field' : ''}
                                        dataSource={{
                                            localData: payTypeDataFiltered,
                                            idField: 'value',
                                            descriptionField: 'label',
                                        }}
                                        allowEmptyLine={false}
                                        disabled={!editPayType}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Form.Label column lg={4}>Technician</Form.Label>
                                <Col lg={8}>
                                    <DropdownQuery
                                        name="technicianId"
                                        value={currentTechnicianId}
                                        placeHolder="select a technician"
                                        onChange={(name, newValue) => onChangeValue(name, newValue)}
                                        dataSource={{
                                            localData: technicianList,
                                            idField: 'userId',
                                            descriptionField: 'user',
                                        }}
                                        allowEmptyLine
                                        defaultEmptyLineText="None"
                                        disabled={!editTechniccian || isSublet || displayFunded}
                                        className={(isValidField(errors, 'technicianId') && !isSublet) ? 'invalid-field' : ''}
                                    />
                                </Col>
                            </Form.Group>
                        </Col>
                    </Form>
                </FieldsetTitle>
            </Col>
            <Col lg={3}>
                <FieldsetTitle title="Hours">
                    <Form as={Row}>
                        <Col xs={12} sm={6} lg={12}>
                            <Form.Group as={Row}>
                                <Form.Label column lg={6}>Sold Hours</Form.Label>
                                <Col lg={6}>
                                    <InputNumber
                                        size="sm"
                                        allowNegative={false}
                                        value={hours}
                                        placeholder="0"
                                        onChange={(value) => onChangeValue('hours', value)}
                                        alignRight
                                        disabled={!isHrsChecked || !editSoldHours || !SERVICE_JOB_LABOR_HOURS
                                            || isSublet || displayFunded}
                                        className={(isValidField(errors, 'hours') && !isSublet) ? 'invalid-field' : ''}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Form.Label column lg={6}>Flagged Hours</Form.Label>
                                <Col lg={6}>
                                    <InputNumber
                                        size="sm"
                                        allowNegative={false}
                                        value={flaggedHours}
                                        placeholder="0"
                                        onChange={(value) => onChangeValue('flaggedHours', value)}
                                        alignRight
                                        disabled={!editFlaggedHours || (flaggedHoursApprovedOn ?? false) || flaggedHoursPaid
                                            || displayFunded}
                                        className={isValidField(errors, 'flaggedHours') ? 'invalid-field' : ''}
                                    />
                                </Col>
                            </Form.Group>
                        </Col>
                        <Col xs={12} sm={6} lg={12}>
                            <Form.Group as={Row}>
                                <Form.Label column lg={6}>Labor Amount</Form.Label>
                                <Col lg={6}>
                                    <InputNumber
                                        size="sm"
                                        showCurrency
                                        allowNegative={false}
                                        value={laborTotal}
                                        thousandSeparator
                                        placeholder="0"
                                        onChange={(value) => onChangeValue('laborTotal', value)}
                                        alignRight
                                        disabled={isHrsChecked || !editLaborAmount || !SERVICE_JOBS_OVERRIDE_LABOR_RATE
                                            || isSublet || displayFunded}
                                        className={(isValidField(errors, 'laborTotal') && !isSublet) ? 'invalid-field' : ''}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Col lg={12}>
                                    <Form.Check
                                        id={`isHrsCheckedId${jobNumber}`}
                                        checked={!isHrsChecked}
                                        type="checkbox"
                                        label="Override Labor Amount"
                                        onChange={(e) => onChangeValue('isHrsChecked', !e.target.checked)}
                                        disabled={!editCostByHours || !SERVICE_JOBS_OVERRIDE_LABOR_RATE || isSublet
                                            || displayFunded}
                                    />
                                </Col>
                            </Form.Group>
                            {displayFunded && (
                                <Form.Group as={Row}>
                                    <Form.Label className={classes.fundedLabel} column lg={3}>*Funded</Form.Label>
                                </Form.Group>
                            )}
                        </Col>
                    </Form>
                </FieldsetTitle>
            </Col>
            <Col lg={3}>
                <FieldsetTitle title="Sublet Information">
                    <Form as={Row}>
                        <Col xs={12} sm={6} lg={12}>
                            <Form.Group as={Row}>
                                <Form.Label column lg={3}>Vendor</Form.Label>
                                <Col lg={9}>
                                    <DropdownQuery
                                        name="subletVendorId"
                                        value={subletVendorId}
                                        placeHolder="select a vendor"
                                        onChange={(name, newValue) => onChangeValue(name, newValue)}
                                        dataSource={{
                                            localData: vendorList ?? [],
                                            idField: 'vendorId',
                                            descriptionField: 'vendor',
                                        }}
                                        defaultEmptyLineText="Select"
                                        disabled={!editSubletVendor || !isSublet || displayFunded}
                                        className={(isValidField(errors, 'subletVendorId') && isSublet) ? 'invalid-field' : ''}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Form.Label column lg={3}>Invoice</Form.Label>
                                <Col lg={9}>
                                    <Form.Control
                                        type="text"
                                        size="sm"
                                        maxLength={15}
                                        value={subletInvoice ?? ''}
                                        name="subletInvoice"
                                        onChange={({ target }) => onChangeValue(target.name, target.value)}
                                        disabled={!editSubletCost || !isSublet || displayFunded}
                                        className={(isValidField(errors, 'subletInvoice') && isSublet) ? 'invalid-field' : ''}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Form.Label column lg={3}>Cost</Form.Label>
                                <Col lg={9}>
                                    <InputNumber
                                        size="sm"
                                        showCurrency
                                        allowNegative={false}
                                        value={subletCost}
                                        thousandSeparator
                                        placeholder="0"
                                        onChange={(value) => onChangeValue('subletCost', value)}
                                        alignRight
                                        disabled={!editSubletCost || !isSublet || displayFunded}
                                        className={(isValidField(errors, 'subletCost') && isSublet) ? 'invalid-field' : ''}
                                    />
                                </Col>
                            </Form.Group>
                        </Col>
                        <Col xs={12} sm={6} lg={12}>
                            <Form.Group as={Row}>
                                <Form.Label column lg={3} className={classes.priceSpot}>
                                    <div>Price</div>
                                    <Tooltip title="Override Price" placement="top">
                                        <Form.Check
                                            id={`overridePriceId${jobNumber}`}
                                            checked={overrideSubletPrice}
                                            type="checkbox"
                                            onChange={(e) => onChangeValue('overrideSubletPrice', e.target.checked)}
                                            disabled={!editOverrideSubletPrice || !SERVICE_JOBS_OVERRIDE_SUBLET_PRICE || !isSublet
                                                || displayFunded}
                                            className={classes.priceOverride}
                                        />
                                    </Tooltip>
                                </Form.Label>
                                <Col lg={9}>
                                    <InputNumber
                                        size="sm"
                                        showCurrency
                                        allowNegative={false}
                                        value={subletPrice}
                                        thousandSeparator
                                        placeholder="0"
                                        onChange={(value) => onChangeValue('subletPrice', value)}
                                        alignRight
                                        disabled={!overrideSubletPrice || !editSubletPrice || !SERVICE_JOBS_OVERRIDE_SUBLET_PRICE
                                            || !isSublet || displayFunded}
                                        className={(isValidField(errors, 'subletPrice') && isSublet) ? 'invalid-field' : ''}
                                    />
                                </Col>
                            </Form.Group>
                        </Col>
                    </Form>
                </FieldsetTitle>
            </Col>
        </Form>
    );
};

ServiceJobsHeader.propTypes = {
    jobItem: PropTypes.object,
    vendorList: PropTypes.arrayOf(PropTypes.object).isRequired,
    onChangeJobValue: PropTypes.func.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    identifierJobsHeader: PropTypes.string,
    userList: PropTypes.arrayOf(PropTypes.object),
    editDetails: PropTypes.object,
    recordFields: PropTypes.shape({
        laborDiscount: PropTypes.bool,
        discount: PropTypes.number,
        discountPercentage: PropTypes.number,
        lotName: PropTypes.string,
        inHouse: PropTypes.bool,
    }).isRequired,
};

ServiceJobsHeader.defaultProps = {
    jobItem: {},
    identifierJobsHeader: '',
    userList: [],
    editDetails: {},
};

const areEqual = (prevProps, nextProps) => prevProps.identifierJobsHeader === nextProps.identifierJobsHeader;

export default React.memo(ServiceJobsHeader, areEqual);
