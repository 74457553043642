import React, { Component } from 'react';

// Components and Ohters
import PropTypes from 'prop-types';
import { clone, isNull, map } from 'lodash';

// GraphQL
import GraphQLClient from 'services/apollo/GraphQLClient';
import DealMap from 'services/mapData/DealMap';
import ModalUtils from 'utils/ModalUtils';
import DealService from 'services/modules/DealService';

const WeOweContainer = (WrappedComponent) => class extends Component {
    static propTypes = {
        accountNumber: PropTypes.number.isRequired,
        toggleModal: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.graphqlClient = new GraphQLClient();
        this.dealService = new DealService();
        const defaultRow = this.getNewWeOweLine();

        this.state = {
            records: [defaultRow],
            weOweDeleted: [],
            allowSave: false,
            openConfirm: false,
            indexToDelete: null,
            isSaving: false,
        };

        this.initBind();
    }

    onSave() {
        const { state: { records, weOweDeleted, isSaving }, props: { toggleModal, accountNumber } } = this;
        if (isSaving) {
            return;
        }
        this.setState({
            isSaving: true,
        });

        const currentRecords = map(records, (item) => DealMap.mapSaveWeOwe(item));
        const input = {
            dealNumber: accountNumber,
            data: currentRecords,
            deleted: weOweDeleted,
        };

        this.dealService.saveWeOwe(input).then((response) => {
            const { data, graphQLErrors } = response;

            if (graphQLErrors) {
                ModalUtils.errorMessage(graphQLErrors);
            } else if (data && data.saveWeOwe) {
                toggleModal();
                return;
            }
            this.setState({
                isSaving: false,
            });
        });
    }

    onDelete() {
        let items = null;

        this.setState(({ records, weOweDeleted, indexToDelete }) => {
            items = [...records];
            const newWeOweDeleted = [...weOweDeleted];
            const idWeOweToDelete = items[indexToDelete].weOweId;

            items.splice(indexToDelete, 1);

            if (!isNull(idWeOweToDelete)) {
                newWeOweDeleted.push(idWeOweToDelete);
            }

            return { records: items, weOweDeleted: newWeOweDeleted };
        }, () => this.validateRequiredFields(items));

        this.cancelConfirm();
    }

    onChangeValue(field, value, index) {
        const { state: { records } } = this;
        const backUp = clone(records);
        backUp[index][field] = value;

        this.setState({
            records: backUp,
        });

        this.validateRequiredFields(backUp);
    }

    getServicesData() {
        const { props: { accountNumber } } = this;

        this.dealService.getWeOweList(accountNumber)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data && data.length) {
                    this.setState({
                        records: data,
                    });

                    this.validateRequiredFields(data);
                }
            });
    }

    getNewWeOweLine() {
        const { props: { accountNumber } } = this;

        return {
            dealNumber: accountNumber,
            weOweId: null,
            quantity: 1,
            nameOfItem: '',
            part: 0,
            labor: 0,
            imported: false,
        };
    }

    showConfirm(index) {
        this.setState({
            openConfirm: true,
            indexToDelete: index,
        });
    }

    cancelConfirm() {
        this.setState({
            openConfirm: false,
            indexToDelete: null,
        });
    }

    validateRequiredFields(records) {
        let allowSave = true;

        records.forEach((record) => {
            if (
                !record.nameOfItem
            ) {
                allowSave = false;
            }
        });

        this.setState({
            allowSave,
        });
    }

    addRow() {
        const { state: { records } } = this;
        const backUp = clone(records);
        backUp.push(this.getNewWeOweLine());

        this.setState({
            records: backUp,
        });

        this.validateRequiredFields(backUp);
    }

    initBind() {
        this.onSave = this.onSave.bind(this);
        this.addRow = this.addRow.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onChangeValue = this.onChangeValue.bind(this);
        this.getServicesData = this.getServicesData.bind(this);
        this.showConfirm = this.showConfirm.bind(this);
        this.cancelConfirm = this.cancelConfirm.bind(this);
    }

    render() {
        const { props, state } = this;

        return (
            <WrappedComponent
                {...props}
                {...state}
                addRow={this.addRow}
                onSave={this.onSave}
                onDelete={this.onDelete}
                showConfirm={this.showConfirm}
                cancelConfirm={this.cancelConfirm}
                onChangeValue={this.onChangeValue}
                getServicesData={this.getServicesData}
            />
        );
    }
};

export default WeOweContainer;
