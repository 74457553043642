import React, { Component } from 'react';

import AssistantPhotoIcon from '@material-ui/icons/AssistantPhoto';

// Components and Others
import PropTypes from 'prop-types';
import KeyStore from 'utils/KeyStore';
import ModalUtils from 'utils/ModalUtils';
import {
    filter, map, find, reject,
} from 'lodash';
import Permissions from 'utils/enum/Permissions';
import {
    DealActions, FlagDeal,
    Actions, FundingStatus,
    FundingStatusValues, DealStatus,
} from 'utils/enum/DealEnum';
import DateUtils from 'lib/DateUtils';

// GraphQL
import DealService from 'services/modules/DealService';
import GraphQLClient from 'services/apollo/GraphQLClient';
import DealsMutate from 'services/graphQL/mutate/DealsMutate';
import DealUtils from 'utils/DealUtils';
import ErrorMessages from 'utils/enum/ErrorMessages';

const successMessage = 'Deal updated successfully';

const flagOptions = [
    {
        value: Actions.UNFLAG_DEAL,
        label: Actions.UNFLAG_DEAL,
        flagValue: 0,
    },
    {
        value: Actions.FLAG_DEAL,
        component: <AssistantPhotoIcon style={{ color: 'green' }} />,
        flagValue: 1,
    },
    {
        value: Actions.FLAG_DEAL,
        component: <AssistantPhotoIcon style={{ color: 'yellow' }} />,
        flagValue: 2,
    },
    {
        value: Actions.FLAG_DEAL,
        component: <AssistantPhotoIcon style={{ color: 'red' }} />,
        flagValue: 3,
    },
];

const DealItemsContainer = (WrappedComponent) => class extends Component {
    static propTypes = {
        stages: PropTypes.array.isRequired,
        record: PropTypes.object.isRequired,
        toggleModal: PropTypes.func.isRequired,
        onOpenModal: PropTypes.func.isRequired,
        stagesTransitionList: PropTypes.array.isRequired,
        toggleTransferToRFCDialog: PropTypes.func.isRequired,
        toogleSecureCloseDialog: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.graphqlClient = new GraphQLClient();
        this.dealService = new DealService();

        this.state = {
            openDeleteConfirm: false,
            openDeleteAdminConfirm: false,
            adminDeleteMessage: null,
        };

        this.initBind();
    }

    handleClick(data = {}) {
        const {
            props: {
                record: { accountNumber }, record, toggleModal,
                onOpenModal, toggleTransferToRFCDialog, toogleSecureCloseDialog,
            },
        } = this;
        const currrentAction = data.value;

        switch (currrentAction) {
        case DealActions.MOVE_TO:
            if (data.label === DealStatus.DELETED) this.onOpenDeleteConfirm(data.id);
            else this.setDealStatus(data.id);
            break;
        case DealActions.POST_TO_ACCOUNTING:
            onOpenModal(record);
            break;
        case Actions.FLAG_DEAL:
            this.flagDeal(this.getInputFlag(accountNumber, `FLAG${data.flagValue}`));
            break;
        case Actions.UNFLAG_DEAL:
            this.flagDeal(this.getInputFlag(accountNumber, FlagDeal.UNFLAG));
            break;
        case Actions.DEACTIVATE_DEAL:
            this.onOpenDeleteConfirm();
            break;
        case Actions.REACTIVATE_DEAL:
            this.reactivateDeal();
            break;
        case Actions.TRANSFER_TO_RFC:
            toggleTransferToRFCDialog(record);
            break;
        case Actions.TRANSFER_TO_LOT:
            toggleModal(record);
            break;
        case Actions.SEND_TO_TAG_AGENCY:
            this.sendToTagAgency();
            break;
        case Actions.SEND_TO_SECURE_CLOSE:
            toogleSecureCloseDialog(record);
            break;
        case Actions.FUNDING_STATUS: {
            const input = this.getInputFunding(accountNumber, data.id);
            this.setFundingStatus(input);
            break;
        }
        default:
        }
    }

    onDeleteDeal(stageId, forceDelete = false) {
        if (stageId) this.setDealStatus(stageId, forceDelete);
        else this.deactivateDeal(forceDelete);
    }

    onCloseDeleteConfirm() {
        this.setState({
            openDeleteConfirm: false, openDeleteAdminConfirm: false, adminDeleteMessage: null, stageId: null,
        });
    }

    onOpenDeleteConfirm(stageId) {
        this.setState({ openDeleteConfirm: true, stageId });
    }

    getActions(secureCloseEnabled) {
        const { record } = this.props;
        const currentAction = [];
        const keyStore = new KeyStore();

        const optionDeactivateDeal = record.status?.toUpperCase() === DealStatus.DELETED.toUpperCase()
            ? Actions.REACTIVATE_DEAL : Actions.DEACTIVATE_DEAL;
        const isNotPosted = !DateUtils.isValid(record.postedDate);
        const clientIdIsWeb = DealUtils.clientIdIsWeb(record.clientId);

        if (clientIdIsWeb && keyStore.hasPermission(Permissions.SALES_DEAL_SET_STAGE)) {
            currentAction.push({
                label: 'Move deal to',
                items: this.getDealStatuses(isNotPosted),
            });
        }

        if (
            clientIdIsWeb
            && keyStore.hasPermission(Permissions.SALES_DEAL_POST_ACCOUNTING)
            && isNotPosted
            && record.status !== DealStatus.DELETED
            && record.status !== DealStatus.QUOTE
        ) {
            currentAction.push({
                label: 'Post to Accounting',
                value: DealActions.POST_TO_ACCOUNTING,
            });
        }

        if (keyStore.hasPermission(Permissions.SALES_DEAL_FLAG)) {
            currentAction.push({
                label: Actions.FLAG_DEAL,
                items: this.getFlagOptions(),
            });
        }

        if (isNotPosted && clientIdIsWeb && keyStore.hasPermission(Permissions.SALES_DEAL_DEACTIVATE)) {
            currentAction.push({
                label: optionDeactivateDeal,
                value: optionDeactivateDeal,
            });
        }

        if (keyStore.hasPermission(Permissions.SALES_DEAL_TRANSFER_TO_RFC)
        && record.status !== DealStatus.QUOTE
        && record.status !== DealStatus.DELETED) {
            currentAction.push({
                label: Actions.TRANSFER_TO_RFC,
                value: Actions.TRANSFER_TO_RFC,
            });
        }

        if (
            keyStore.hasPermission(Permissions.SALES_DEAL_TRANSFER_TO_LOT)
            && record.status !== DealStatus.ACCOUNTING
        ) {
            currentAction.push({
                label: Actions.TRANSFER_TO_LOT,
                value: Actions.TRANSFER_TO_LOT,
            });
        }

        if (keyStore.hasPermission(Permissions.SALES_DEAL_SEND_TO_TAG_AGENCY)
            && (record.status === DealStatus.FNI
            || record.status === DealStatus.ACCOUNTING)) {
            currentAction.push({
                label: Actions.SEND_TO_TAG_AGENCY,
                value: Actions.SEND_TO_TAG_AGENCY,
            });
        }

        if (keyStore.hasPermission(Permissions.SALES_DEAL_FORMS_SEND_TO_SECURE_CLOSE)
            && secureCloseEnabled) {
            currentAction.push({
                label: Actions.SEND_TO_SECURE_CLOSE,
                value: Actions.SEND_TO_SECURE_CLOSE,
            });
        }

        if (keyStore.hasPermission(Permissions.SALES_DEAL_FUNDING)) {
            currentAction.push({
                label: Actions.FUNDING_STATUS,
                items: this.getFundingStatus(),
            });
        }

        return currentAction;
    }

    getDealStatuses(isNotPosted) {
        const { props: { record: { status }, stagesTransitionList, stages } } = this;
        const currentStageFrom = filter(stagesTransitionList, { stageFrom: status });
        const availableStages = isNotPosted ? reject(stages, { stageFrom: DealStatus.ARCHIVED }) : reject(stages, { stageFrom: DealStatus.DELETED });

        return map(availableStages, (item) => {
            const includeStageFrom = find(currentStageFrom, { stageTo: item.stageFrom });
            const currentId = includeStageFrom ? includeStageFrom.stageToId : item.stageToId;

            return ({
                label: item.stageFrom, value: DealActions.MOVE_TO, id: currentId, disabled: !includeStageFrom,
            });
        });
    }

    getFlagOptions() {
        const { props: { record: { flag } } } = this;
        // TODO: Filter to remove the current flag option

        return flagOptions.filter((i) => i.flagValue !== flag);
    }

    getFundingStatus() {
        const { props: { record } } = this;
        return [
            {
                label: FundingStatus.NOT_FUNDED,
                value: Actions.FUNDING_STATUS,
                id: FundingStatusValues.NOT_FUNDED,
                disabled: record.dealFunded === FundingStatus.NOT_FUNDED,
            },
            {
                label: FundingStatus.SENT_OUT,
                value: Actions.FUNDING_STATUS,
                id: FundingStatusValues.SENT_OUT,
                disabled: record.dealFunded === FundingStatus.SENT_OUT,
            },
            {
                label: FundingStatus.RETURNED,
                value: Actions.FUNDING_STATUS,
                id: FundingStatusValues.RETURNED,
                disabled: record.dealFunded === FundingStatus.RETURNED,
            },
            {
                label: FundingStatus.BOOKED,
                value: Actions.FUNDING_STATUS,
                id: FundingStatusValues.BOOKED,
                disabled: record.dealFunded === FundingStatus.BOOKED,
            },
            {
                label: FundingStatus.FUNDED,
                value: Actions.FUNDING_STATUS,
                id: FundingStatusValues.FUNDED,
                disabled: record.dealFunded === FundingStatus.FUNDED,
            },
        ];
    }

    getInputFlag(dealId, flag) {
        return { dealId, flag };
    }

    setFundingStatus(input) {
        this.graphqlClient
            .mutate(DealsMutate.SET_FUNDING_STATUS, input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data && data.setFundingStatus) {
                    ModalUtils.successMessage([{ message: 'Deal updated successfully' }]);
                }
            });
    }

    setDealStatus(stageId, forceDelete = false) {
        const { props: { record: { accountNumber } } } = this;
        const input = {
            stageId,
            dealId: accountNumber,
            forceDelete,
        };

        this.dealService.setDealStatus(input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    if (graphQLErrors?.[0]?.message?.toLowerCase().includes(ErrorMessages.DEAL_DELETE_SOLD_TRADE.toLowerCase())) {
                        this.setState({ openDeleteAdminConfirm: true, adminDeleteMessage: graphQLErrors?.[0]?.message });
                        return;
                    }
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data && data.setDealStatus) {
                    ModalUtils.successMessage([{ message: successMessage }]);
                }
            });
    }

    getInputFunding(dealId, fundingStatus) {
        return { dealId, fundingStatus };
    }

    deactivateDeal(forceDelete = false) {
        const { props: { record: { accountNumber } } } = this;
        const input = {
            dealId: accountNumber,
            forceDelete,
        };

        this.dealService.deactivateDeal(input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    if (graphQLErrors?.[0]?.message?.toLowerCase().includes(ErrorMessages.DEAL_DELETE_SOLD_TRADE.toLowerCase())) {
                        this.setState({ openDeleteAdminConfirm: true, adminDeleteMessage: graphQLErrors?.[0]?.message });
                        return;
                    }
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data && data.deactivateDeal) {
                    ModalUtils.successMessage([{ message: successMessage }]);
                }
            });
    }

    reactivateDeal() {
        const { props: { record: { accountNumber } } } = this;
        const input = {
            dealId: accountNumber,
        };

        this.graphqlClient
            .mutate(DealsMutate.REACTIVATE_DEAL, input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data && data.reactivateDeal) {
                    ModalUtils.successMessage([{ message: 'Deal reactivated successfully' }]);
                }
            });
    }

    sendToTagAgency() {
        const { props: { record: { accountNumber } } } = this;
        const input = {
            accountNumber,
        };

        this.graphqlClient
            .mutate(DealsMutate.SEND_TO_TAG_AGENCY, input)
            .then((response) => {
                const { data, graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                    return;
                }

                if (data && data.sendDealInfoToTagAgency) {
                    ModalUtils.successMessage([{ message: 'Deal sent to tag agency successfully' }]);
                }
            });
    }

    flagDeal(input) {
        this.dealService
            .setDealFlag(input)
            .then((response) => {
                const { graphQLErrors } = response;

                if (graphQLErrors) {
                    ModalUtils.errorMessage(graphQLErrors);
                }
            });
    }

    initBind() {
        this.flagDeal = this.flagDeal.bind(this);
        this.getActions = this.getActions.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.setDealStatus = this.setDealStatus.bind(this);
        this.setFundingStatus = this.setFundingStatus.bind(this);
        this.getDealStatuses = this.getDealStatuses.bind(this);
        this.onDeleteDeal = this.onDeleteDeal.bind(this);
        this.onOpenDeleteConfirm = this.onOpenDeleteConfirm.bind(this);
        this.onCloseDeleteConfirm = this.onCloseDeleteConfirm.bind(this);
    }

    render() {
        const { props, state } = this;

        return (
            <WrappedComponent
                {...props}
                {...state}
                flagDeal={this.flagDeal}
                getActions={this.getActions}
                handleClick={this.handleClick}
                onDeleteDeal={this.onDeleteDeal}
                onCloseDeleteConfirm={this.onCloseDeleteConfirm}
            />
        );
    }
};

export default DealItemsContainer;
